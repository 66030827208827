import { Union, Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { tuple_type, lambda_type, unit_type, option_type, int32_type, bool_type, union_type, record_type, class_type, list_type, string_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { empty } from "../fable_modules/fable-library.4.1.4/Map.js";
import { comparePrimitives } from "../fable_modules/fable-library.4.1.4/Util.js";
import { ReadyCheckId_$reflection, ServerResponse_$reflection, Leaderboard_$reflection, PlayerStatus_$reflection, FriendshipId_$reflection, FriendshipWithDisplayName_$reflection, ScoreWithUsernames_$reflection, Move_$reflection, Player_$reflection, RoomId_$reflection, ChallengeId_$reflection, Plane_$reflection } from "../Shared/Types.js";

export class ValidationError extends Record {
    constructor(Errors) {
        super();
        this.Errors = Errors;
    }
}

export function ValidationError_$reflection() {
    return record_type("Types.ValidationError", [], ValidationError, () => [["Errors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])]]);
}

export function ValidationError_get_empty() {
    return new ValidationError(empty({
        Compare: comparePrimitives,
    }));
}

export class UiState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Idle", "DrawForMatchmaking", "DrawForChallenge", "DrawForRematch", "InRoom"];
    }
}

export function UiState_$reflection() {
    return union_type("Types.UiState", [], UiState, () => [[], [["Item", list_type(Plane_$reflection())]], [["Item1", ChallengeId_$reflection()], ["Item2", list_type(Plane_$reflection())]], [["Item1", ChallengeId_$reflection()], ["Item2", list_type(Plane_$reflection())]], [["Item", RoomId_$reflection()]]]);
}

export class State extends Record {
    constructor(ValidationErrors, Loaded, PlayerStatusLoaded, LatestPingPong, ApiKey, Player, Messages, HiddenRooms, QueuedMoves, Scores, Friends, OnlineCount, OnlineFriends, ActiveWaitingRoomCount, Requests, MatchmakingStatus, UiState, PlayerStatus, VibrationOn, SoundOn, Leaderboard) {
        super();
        this.ValidationErrors = ValidationErrors;
        this.Loaded = Loaded;
        this.PlayerStatusLoaded = PlayerStatusLoaded;
        this.LatestPingPong = (LatestPingPong | 0);
        this.ApiKey = ApiKey;
        this.Player = Player;
        this.Messages = Messages;
        this.HiddenRooms = HiddenRooms;
        this.QueuedMoves = QueuedMoves;
        this.Scores = Scores;
        this.Friends = Friends;
        this.OnlineCount = (OnlineCount | 0);
        this.OnlineFriends = OnlineFriends;
        this.ActiveWaitingRoomCount = (ActiveWaitingRoomCount | 0);
        this.Requests = Requests;
        this.MatchmakingStatus = MatchmakingStatus;
        this.UiState = UiState;
        this.PlayerStatus = PlayerStatus;
        this.VibrationOn = VibrationOn;
        this.SoundOn = SoundOn;
        this.Leaderboard = Leaderboard;
    }
}

export function State_$reflection() {
    return record_type("Types.State", [], State, () => [["ValidationErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, ValidationError_$reflection()])], ["Loaded", bool_type], ["PlayerStatusLoaded", bool_type], ["LatestPingPong", int32_type], ["ApiKey", option_type(string_type)], ["Player", option_type(Player_$reflection())], ["Messages", list_type(string_type)], ["HiddenRooms", list_type(RoomId_$reflection())], ["QueuedMoves", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [RoomId_$reflection(), list_type(Move_$reflection())])], ["Scores", list_type(ScoreWithUsernames_$reflection())], ["Friends", list_type(FriendshipWithDisplayName_$reflection())], ["OnlineCount", int32_type], ["OnlineFriends", list_type(FriendshipId_$reflection())], ["ActiveWaitingRoomCount", int32_type], ["Requests", list_type(FriendshipWithDisplayName_$reflection())], ["MatchmakingStatus", bool_type], ["UiState", UiState_$reflection()], ["PlayerStatus", PlayerStatus_$reflection()], ["VibrationOn", bool_type], ["SoundOn", bool_type], ["Leaderboard", option_type(Leaderboard_$reflection())]]);
}

export function $007CIdleState$007C_$007C(state) {
    if (state.UiState.tag === 0) {
        return state;
    }
    else {
        return void 0;
    }
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GotServerResponse", "GotServerError", "MessageBatch", "SideEffect", "PartialStateUpdate", "PartialStateUpdateAnd", "RecheckAuthenticatedInfo", "FetchLeaderboard", "SendPing", "Login", "Register", "RegisterAnon", "Logout", "SelectIcon", "RejoinActiveRoom", "AddPlane", "RemovePlane", "PlayMatchmaking", "PlayDaily", "ChallengeWithLink", "ReadyCheckConfirm", "ReadyCheckDecline", "AcceptMatch", "AcceptLinkChallenge", "StartMatchmakingOrChallengeGame", "Rematch", "ForceLogin", "Redirect", "AbandonGame", "AbandonMatchmaking", "MakeMove", "QueueMove", "RequestFriendship", "AcceptRequest", "DeclineRequest", "ChallengeFriend", "AcceptChallenge", "DeclineChallenge", "CancelChallenge", "RejoinChallenge", "ToggleSound", "ToggleVibration", "HideRoom"];
    }
}

export function Msg_$reflection() {
    return union_type("Types.Msg", [], Msg, () => [[["Item", ServerResponse_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", list_type(Msg_$reflection())]], [["Item", lambda_type(bool_type, unit_type)]], [["Item", lambda_type(State_$reflection(), State_$reflection())]], [["Item1", lambda_type(State_$reflection(), State_$reflection())], ["Item2", Msg_$reflection()]], [], [], [], [["Item", tuple_type(string_type, string_type)]], [["Item", tuple_type(string_type, string_type)]], [], [], [["Item", string_type]], [["Item", RoomId_$reflection()]], [["Item", Plane_$reflection()]], [["Item", Plane_$reflection()]], [], [], [], [["Item", ReadyCheckId_$reflection()]], [["Item", ReadyCheckId_$reflection()]], [["Item", ReadyCheckId_$reflection()]], [["Item", ChallengeId_$reflection()]], [], [["Item", RoomId_$reflection()]], [], [["Item", string_type]], [["Item", RoomId_$reflection()]], [], [["Item1", RoomId_$reflection()], ["Item2", Move_$reflection()]], [["Item1", RoomId_$reflection()], ["Item2", Move_$reflection()]], [["Item", string_type]], [["Item", FriendshipId_$reflection()]], [["Item", FriendshipId_$reflection()]], [["Item", FriendshipId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [], [], [["Item", RoomId_$reflection()]]]);
}

