import { Record, Union } from "../fable_modules/fable-library.4.1.4/Types.js";
import { lambda_type, unit_type, option_type, tuple_type, bool_type, list_type, class_type, record_type, int32_type, union_type, string_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { empty as empty_1, ofList } from "../fable_modules/fable-library.4.1.4/Set.js";
import { empty as empty_2, map } from "../fable_modules/fable-library.4.1.4/List.js";
import { compare } from "../fable_modules/fable-library.4.1.4/Util.js";
import { empty } from "../fable_modules/fable-library.4.1.4/Map.js";

export class GroupId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["GroupId"];
    }
}

export function GroupId_$reflection() {
    return union_type("Shared.Types.GroupId", [], GroupId, () => [[["Item", string_type]]]);
}

export class RoomId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["RoomId"];
    }
}

export function RoomId_$reflection() {
    return union_type("Shared.Types.RoomId", [], RoomId, () => [[["Item", string_type]]]);
}

export function RoomId__ToGroupId(self) {
    const s = self.fields[0];
    return new GroupId(toText(printf("%s"))(s));
}

export class ReadyCheckId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ReadyCheckId"];
    }
}

export function ReadyCheckId_$reflection() {
    return union_type("Shared.Types.ReadyCheckId", [], ReadyCheckId, () => [[["Item", string_type]]]);
}

export function ReadyCheckId__ToRoomId(self) {
    const s = self.fields[0];
    return new RoomId(toText(printf("%s"))(s));
}

export class ChallengeId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ChallengeId"];
    }
}

export function ChallengeId_$reflection() {
    return union_type("Shared.Types.ChallengeId", [], ChallengeId, () => [[["Item", int32_type]]]);
}

export function ChallengeId__ToGroupId(self) {
    const i = self.fields[0] | 0;
    return new GroupId(toText(printf("%i"))(i));
}

export function ChallengeId__ToRoomId(self) {
    const i = self.fields[0] | 0;
    return new RoomId(toText(printf("challenge-room-%i"))(i));
}

export class DailyPlayId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DailyPlayId"];
    }
}

export function DailyPlayId_$reflection() {
    return union_type("Shared.Types.DailyPlayId", [], DailyPlayId, () => [[["Item", int32_type]]]);
}

export class RoomType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Matchmaking", "Challenge", "ChallengeWithLink", "Rematch", "Daily"];
    }
}

export function RoomType_$reflection() {
    return union_type("Shared.Types.RoomType", [], RoomType, () => [[], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item1", ChallengeId_$reflection()], ["Item2", RoomId_$reflection()]], [["Item", DailyPlayId_$reflection()]]]);
}

export class PlayerId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PlayerId"];
    }
}

export function PlayerId_$reflection() {
    return union_type("Shared.Types.PlayerId", [], PlayerId, () => [[["Item", int32_type]]]);
}

export class PlayerType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Normal", "Anon"];
    }
}

export function PlayerType_$reflection() {
    return union_type("Shared.Types.PlayerType", [], PlayerType, () => [[], []]);
}

export class Player extends Record {
    constructor(Id, Username, DisplayName, Icon, Type) {
        super();
        this.Id = Id;
        this.Username = Username;
        this.DisplayName = DisplayName;
        this.Icon = Icon;
        this.Type = Type;
    }
}

export function Player_$reflection() {
    return record_type("Shared.Types.Player", [], Player, () => [["Id", PlayerId_$reflection()], ["Username", string_type], ["DisplayName", string_type], ["Icon", string_type], ["Type", PlayerType_$reflection()]]);
}

export class Move extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Move", "Abandon", "Timeout"];
    }
}

export function Move_$reflection() {
    return union_type("Shared.Types.Move", [], Move, () => [[["Item1", int32_type], ["Item2", int32_type]], [], []]);
}

export class PlaneDirection extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["North", "South", "West", "East"];
    }
}

export function PlaneDirection_$reflection() {
    return union_type("Shared.Types.PlaneDirection", [], PlaneDirection, () => [[], [], [], []]);
}

export class Plane extends Record {
    constructor(X, Y, Direction) {
        super();
        this.X = (X | 0);
        this.Y = (Y | 0);
        this.Direction = Direction;
    }
}

export function Plane_$reflection() {
    return record_type("Shared.Types.Plane", [], Plane, () => [["X", int32_type], ["Y", int32_type], ["Direction", PlaneDirection_$reflection()]]);
}

export class ChallengeStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["New", "GameOver", "Started", "Accepted", "Declined", "Cancelled"];
    }
}

export function ChallengeStatus_$reflection() {
    return union_type("Shared.Types.ChallengeStatus", [], ChallengeStatus, () => [[], [], [], [], [], []]);
}

export function ChallengeStatus_FromString_Z721C83C5(str) {
    switch (str) {
        case "New":
            return new ChallengeStatus(0, []);
        case "GameOver":
            return new ChallengeStatus(1, []);
        case "Started":
            return new ChallengeStatus(2, []);
        case "Accepted":
            return new ChallengeStatus(3, []);
        case "Declined":
            return new ChallengeStatus(4, []);
        case "Cancelled":
            return new ChallengeStatus(5, []);
        default:
            throw new Error(toText(printf("Cant determine status from str %A"))(str));
    }
}

export class ChallengeType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Rematch", "Challenge", "ChallengeWithLink"];
    }
}

export function ChallengeType_$reflection() {
    return union_type("Shared.Types.ChallengeType", [], ChallengeType, () => [[["Item", RoomId_$reflection()]], [], []]);
}

export class Challenge extends Record {
    constructor(Id, ChallengerId, ChallengedIds, PlayerPlanesMap, Status, Type, Date$) {
        super();
        this.Id = Id;
        this.ChallengerId = ChallengerId;
        this.ChallengedIds = ChallengedIds;
        this.PlayerPlanesMap = PlayerPlanesMap;
        this.Status = Status;
        this.Type = Type;
        this.Date = Date$;
    }
}

export function Challenge_$reflection() {
    return record_type("Shared.Types.Challenge", [], Challenge, () => [["Id", ChallengeId_$reflection()], ["ChallengerId", PlayerId_$reflection()], ["ChallengedIds", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [PlayerId_$reflection()])], ["PlayerPlanesMap", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [PlayerId_$reflection(), list_type(Plane_$reflection())])], ["Status", ChallengeStatus_$reflection()], ["Type", ChallengeType_$reflection()], ["Date", class_type("System.DateTime")]]);
}

export class MatchmakingId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["MatchmakingId"];
    }
}

export function MatchmakingId_$reflection() {
    return union_type("Shared.Types.MatchmakingId", [], MatchmakingId, () => [[["Item", int32_type]]]);
}

export class Matchmaking extends Record {
    constructor(Id, PlayerId, Planes, CreatedAt) {
        super();
        this.Id = Id;
        this.PlayerId = PlayerId;
        this.Planes = Planes;
        this.CreatedAt = CreatedAt;
    }
}

export function Matchmaking_$reflection() {
    return record_type("Shared.Types.Matchmaking", [], Matchmaking, () => [["Id", MatchmakingId_$reflection()], ["PlayerId", PlayerId_$reflection()], ["Planes", list_type(Plane_$reflection())], ["CreatedAt", class_type("System.DateTime")]]);
}

export class ChallengePlayer extends Record {
    constructor(Id, DisplayName, Icon) {
        super();
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Icon = Icon;
    }
}

export function ChallengePlayer_$reflection() {
    return record_type("Shared.Types.ChallengePlayer", [], ChallengePlayer, () => [["Id", PlayerId_$reflection()], ["DisplayName", string_type], ["Icon", string_type]]);
}

export class ChallengeRoom extends Record {
    constructor(Id, ChallengedPlayers, ChallengerPlayer, Type, Status, Date$) {
        super();
        this.Id = Id;
        this.ChallengedPlayers = ChallengedPlayers;
        this.ChallengerPlayer = ChallengerPlayer;
        this.Type = Type;
        this.Status = Status;
        this.Date = Date$;
    }
}

export function ChallengeRoom_$reflection() {
    return record_type("Shared.Types.ChallengeRoom", [], ChallengeRoom, () => [["Id", ChallengeId_$reflection()], ["ChallengedPlayers", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [ChallengePlayer_$reflection()])], ["ChallengerPlayer", ChallengePlayer_$reflection()], ["Type", ChallengeType_$reflection()], ["Status", ChallengeStatus_$reflection()], ["Date", class_type("System.DateTime")]]);
}

export function ChallengeRoom_FromChallenge(c, challenger, challenged) {
    const mapPlayerToChallengerPlayer = (player) => (new ChallengePlayer(player.Id, player.DisplayName, player.Icon));
    return new ChallengeRoom(c.Id, ofList(map(mapPlayerToChallengerPlayer, challenged), {
        Compare: compare,
    }), mapPlayerToChallengerPlayer(challenger), c.Type, c.Status, c.Date);
}

export class DailyStatus extends Record {
    constructor(CompletedLayouts, CurrentScore, HasStartedLayouts) {
        super();
        this.CompletedLayouts = CompletedLayouts;
        this.CurrentScore = (CurrentScore | 0);
        this.HasStartedLayouts = HasStartedLayouts;
    }
}

export function DailyStatus_$reflection() {
    return record_type("Shared.Types.DailyStatus", [], DailyStatus, () => [["CompletedLayouts", list_type(int32_type)], ["CurrentScore", int32_type], ["HasStartedLayouts", bool_type]]);
}

export function DailyStatus_get_MaxCompletedLayouts() {
    return 5;
}

export class WaitingForPlayersGameState extends Record {
    constructor(PlayerA, PlayerB) {
        super();
        this.PlayerA = PlayerA;
        this.PlayerB = PlayerB;
    }
}

export function WaitingForPlayersGameState_$reflection() {
    return record_type("Shared.Types.WaitingForPlayersGameState", [], WaitingForPlayersGameState, () => [["PlayerA", option_type(tuple_type(PlayerId_$reflection(), list_type(Plane_$reflection())))], ["PlayerB", option_type(tuple_type(PlayerId_$reflection(), list_type(Plane_$reflection())))]]);
}

export class PlayingGameState extends Record {
    constructor(PlayerA, PlayerB, Moves, FirstPlayer) {
        super();
        this.PlayerA = PlayerA;
        this.PlayerB = PlayerB;
        this.Moves = Moves;
        this.FirstPlayer = FirstPlayer;
    }
}

export function PlayingGameState_$reflection() {
    return record_type("Shared.Types.PlayingGameState", [], PlayingGameState, () => [["PlayerA", tuple_type(PlayerId_$reflection(), list_type(Plane_$reflection()))], ["PlayerB", tuple_type(PlayerId_$reflection(), list_type(Plane_$reflection()))], ["Moves", list_type(tuple_type(PlayerId_$reflection(), Move_$reflection()))], ["FirstPlayer", PlayerId_$reflection()]]);
}

export class GameState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PlayingGameState", "GameOverState"];
    }
}

export function GameState_$reflection() {
    return union_type("Shared.Types.GameState", [], GameState, () => [[["Item", PlayingGameState_$reflection()]], [["Item", PlayingGameState_$reflection()]]]);
}

export class MoveResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Air", "Hit", "Dead", "Abandon", "Timeout"];
    }
}

export function MoveResult_$reflection() {
    return union_type("Shared.Types.MoveResult", [], MoveResult, () => [[["Item", tuple_type(int32_type, int32_type)]], [["Item", tuple_type(int32_type, int32_type)]], [["Item", tuple_type(int32_type, int32_type)]], [], []]);
}

export class PublicGameState extends Record {
    constructor(MoveResults, NextPlayerId, Winner, MoveTimeout) {
        super();
        this.MoveResults = MoveResults;
        this.NextPlayerId = NextPlayerId;
        this.Winner = Winner;
        this.MoveTimeout = MoveTimeout;
    }
}

export function PublicGameState_$reflection() {
    return record_type("Shared.Types.PublicGameState", [], PublicGameState, () => [["MoveResults", list_type(tuple_type(PlayerId_$reflection(), MoveResult_$reflection()))], ["NextPlayerId", PlayerId_$reflection()], ["Winner", option_type(PlayerId_$reflection())], ["MoveTimeout", class_type("System.DateTime")]]);
}

export class PublicPlayer extends Record {
    constructor(Name, Icon) {
        super();
        this.Name = Name;
        this.Icon = Icon;
    }
}

export function PublicPlayer_$reflection() {
    return record_type("Shared.Types.PublicPlayer", [], PublicPlayer, () => [["Name", string_type], ["Icon", string_type]]);
}

export class RoomItem extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MatchmakingRoom", "ChallengeRoom", "DailyRoom"];
    }
}

export function RoomItem_$reflection() {
    return union_type("Shared.Types.RoomItem", [], RoomItem, () => [[["Item1", PublicGameState_$reflection()], ["Item2", list_type(Plane_$reflection())], ["Item3", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [PlayerId_$reflection(), PublicPlayer_$reflection()])], ["Item4", class_type("System.DateTime")]], [["Item1", PublicGameState_$reflection()], ["Item2", list_type(Plane_$reflection())], ["Item3", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [PlayerId_$reflection(), PublicPlayer_$reflection()])], ["Item4", class_type("System.DateTime")]], [["Item", PublicGameState_$reflection()]]]);
}

export class ScoreId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ScoreId"];
    }
}

export function ScoreId_$reflection() {
    return union_type("Shared.Types.ScoreId", [], ScoreId, () => [[["Item", int32_type]]]);
}

export class Score extends Record {
    constructor(Id, RoomId, FirstPlayerId, Winner, Loser, LoserScore, Date$) {
        super();
        this.Id = Id;
        this.RoomId = RoomId;
        this.FirstPlayerId = FirstPlayerId;
        this.Winner = Winner;
        this.Loser = Loser;
        this.LoserScore = (LoserScore | 0);
        this.Date = Date$;
    }
}

export function Score_$reflection() {
    return record_type("Shared.Types.Score", [], Score, () => [["Id", ScoreId_$reflection()], ["RoomId", RoomId_$reflection()], ["FirstPlayerId", PlayerId_$reflection()], ["Winner", PlayerId_$reflection()], ["Loser", PlayerId_$reflection()], ["LoserScore", int32_type], ["Date", class_type("System.DateTime")]]);
}

export class ScoreWithUsernames extends Record {
    constructor(WinnerDisplayName, LoserDisplayName, LoserScore, Date$) {
        super();
        this.WinnerDisplayName = WinnerDisplayName;
        this.LoserDisplayName = LoserDisplayName;
        this.LoserScore = (LoserScore | 0);
        this.Date = Date$;
    }
}

export function ScoreWithUsernames_$reflection() {
    return record_type("Shared.Types.ScoreWithUsernames", [], ScoreWithUsernames, () => [["WinnerDisplayName", string_type], ["LoserDisplayName", string_type], ["LoserScore", int32_type], ["Date", class_type("System.DateTime")]]);
}

export class RankedLeaderboardItem extends Record {
    constructor(PlayerId, DisplayName, Score, Rank) {
        super();
        this.PlayerId = PlayerId;
        this.DisplayName = DisplayName;
        this.Score = (Score | 0);
        this.Rank = (Rank | 0);
    }
}

export function RankedLeaderboardItem_$reflection() {
    return record_type("Shared.Types.RankedLeaderboardItem", [], RankedLeaderboardItem, () => [["PlayerId", PlayerId_$reflection()], ["DisplayName", string_type], ["Score", int32_type], ["Rank", int32_type]]);
}

export class UnrankedLeaderboardItem extends Record {
    constructor(PlayerId, DisplayName) {
        super();
        this.PlayerId = PlayerId;
        this.DisplayName = DisplayName;
    }
}

export function UnrankedLeaderboardItem_$reflection() {
    return record_type("Shared.Types.UnrankedLeaderboardItem", [], UnrankedLeaderboardItem, () => [["PlayerId", PlayerId_$reflection()], ["DisplayName", string_type]]);
}

export class LeaderboardItem extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RankedLeaderboardItem", "UnrankedLeaderboardItem"];
    }
}

export function LeaderboardItem_$reflection() {
    return union_type("Shared.Types.LeaderboardItem", [], LeaderboardItem, () => [[["Item", RankedLeaderboardItem_$reflection()]], [["Item", UnrankedLeaderboardItem_$reflection()]]]);
}

export class Leaderboard extends Record {
    constructor(Top100, TopPlayerPosition, Friends) {
        super();
        this.Top100 = Top100;
        this.TopPlayerPosition = TopPlayerPosition;
        this.Friends = Friends;
    }
}

export function Leaderboard_$reflection() {
    return record_type("Shared.Types.Leaderboard", [], Leaderboard, () => [["Top100", list_type(LeaderboardItem_$reflection())], ["TopPlayerPosition", LeaderboardItem_$reflection()], ["Friends", list_type(LeaderboardItem_$reflection())]]);
}

export class TournamentId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["TournamentId"];
    }
}

export function TournamentId_$reflection() {
    return union_type("Shared.Types.TournamentId", [], TournamentId, () => [[["Item", int32_type]]]);
}

export class Tournament extends Record {
    constructor(Id, Start, RegistrationLimit) {
        super();
        this.Id = Id;
        this.Start = Start;
        this.RegistrationLimit = RegistrationLimit;
    }
}

export function Tournament_$reflection() {
    return record_type("Shared.Types.Tournament", [], Tournament, () => [["Id", TournamentId_$reflection()], ["Start", class_type("System.DateTime")], ["RegistrationLimit", class_type("System.DateTime")]]);
}

export class TournamentEntryId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["TournamentEntryId"];
    }
}

export function TournamentEntryId_$reflection() {
    return union_type("Shared.Types.TournamentEntryId", [], TournamentEntryId, () => [[["Item", int32_type]]]);
}

export class TournamentEntry extends Record {
    constructor(Id, PlayerId, TournamentId) {
        super();
        this.Id = Id;
        this.PlayerId = PlayerId;
        this.TournamentId = TournamentId;
    }
}

export function TournamentEntry_$reflection() {
    return record_type("Shared.Types.TournamentEntry", [], TournamentEntry, () => [["Id", TournamentEntryId_$reflection()], ["PlayerId", PlayerId_$reflection()], ["TournamentId", TournamentId_$reflection()]]);
}

export class TournamentGameId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["TournamentGameId"];
    }
}

export function TournamentGameId_$reflection() {
    return union_type("Shared.Types.TournamentGameId", [], TournamentGameId, () => [[["Item", int32_type]]]);
}

export class TournamentGame extends Record {
    constructor(Id, ScoreId, PlayerIdB, TournamentId) {
        super();
        this.Id = Id;
        this.ScoreId = ScoreId;
        this.PlayerIdB = PlayerIdB;
        this.TournamentId = TournamentId;
    }
}

export function TournamentGame_$reflection() {
    return record_type("Shared.Types.TournamentGame", [], TournamentGame, () => [["Id", TournamentGameId_$reflection()], ["ScoreId", ScoreId_$reflection()], ["PlayerIdB", PlayerId_$reflection()], ["TournamentId", TournamentId_$reflection()]]);
}

export class FriendshipStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["New", "Active", "Inactive"];
    }
}

export function FriendshipStatus_$reflection() {
    return union_type("Shared.Types.FriendshipStatus", [], FriendshipStatus, () => [[], [], []]);
}

export function FriendshipStatus_FromString_Z721C83C5(str) {
    switch (str) {
        case "New":
            return new FriendshipStatus(0, []);
        case "Active":
            return new FriendshipStatus(1, []);
        case "Inactive":
            return new FriendshipStatus(2, []);
        default:
            throw new Error(toText(printf("Cant determine status from str %A"))(str));
    }
}

export class FriendshipId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["FriendshipId"];
    }
}

export function FriendshipId_$reflection() {
    return union_type("Shared.Types.FriendshipId", [], FriendshipId, () => [[["Item", int32_type]]]);
}

export class Friendship extends Record {
    constructor(Id, RequesterId, RequestedId, Status) {
        super();
        this.Id = Id;
        this.RequesterId = RequesterId;
        this.RequestedId = RequestedId;
        this.Status = Status;
    }
}

export function Friendship_$reflection() {
    return record_type("Shared.Types.Friendship", [], Friendship, () => [["Id", FriendshipId_$reflection()], ["RequesterId", PlayerId_$reflection()], ["RequestedId", PlayerId_$reflection()], ["Status", FriendshipStatus_$reflection()]]);
}

export class FriendshipWithDisplayName extends Record {
    constructor(Id, RequesterId, FriendDisplayName, Status) {
        super();
        this.Id = Id;
        this.RequesterId = RequesterId;
        this.FriendDisplayName = FriendDisplayName;
        this.Status = Status;
    }
}

export function FriendshipWithDisplayName_$reflection() {
    return record_type("Shared.Types.FriendshipWithDisplayName", [], FriendshipWithDisplayName, () => [["Id", FriendshipId_$reflection()], ["RequesterId", PlayerId_$reflection()], ["FriendDisplayName", string_type], ["Status", FriendshipStatus_$reflection()]]);
}

export class FriendshipRequestId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["FriendshipRequestId"];
    }
}

export function FriendshipRequestId_$reflection() {
    return union_type("Shared.Types.FriendshipRequestId", [], FriendshipRequestId, () => [[["Item", int32_type]]]);
}

export class FriendshipRequest extends Record {
    constructor(Id, RequesterId, RequestedId, Status) {
        super();
        this.Id = Id;
        this.RequesterId = RequesterId;
        this.RequestedId = RequestedId;
        this.Status = Status;
    }
}

export function FriendshipRequest_$reflection() {
    return record_type("Shared.Types.FriendshipRequest", [], FriendshipRequest, () => [["Id", FriendshipRequestId_$reflection()], ["RequesterId", PlayerId_$reflection()], ["RequestedId", PlayerId_$reflection()], ["Status", string_type]]);
}

export class DailyId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DailyId"];
    }
}

export function DailyId_$reflection() {
    return union_type("Shared.Types.DailyId", [], DailyId, () => [[["Item", int32_type]]]);
}

export class Daily extends Record {
    constructor(Id, Date$, Game1, Game2, Game3, Game4, Game5, GameState) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.Game1 = Game1;
        this.Game2 = Game2;
        this.Game3 = Game3;
        this.Game4 = Game4;
        this.Game5 = Game5;
        this.GameState = GameState;
    }
}

export function Daily_$reflection() {
    return record_type("Shared.Types.Daily", [], Daily, () => [["Id", DailyId_$reflection()], ["Date", class_type("System.DateOnly")], ["Game1", option_type(int32_type)], ["Game2", option_type(int32_type)], ["Game3", option_type(int32_type)], ["Game4", option_type(int32_type)], ["Game5", option_type(int32_type)], ["GameState", PlayingGameState_$reflection()]]);
}

export class PlayerStatus extends Record {
    constructor(InMatchmaking, ReadyChecks, Challenges, Rooms, DailyStatus) {
        super();
        this.InMatchmaking = InMatchmaking;
        this.ReadyChecks = ReadyChecks;
        this.Challenges = Challenges;
        this.Rooms = Rooms;
        this.DailyStatus = DailyStatus;
    }
}

export function PlayerStatus_$reflection() {
    return record_type("Shared.Types.PlayerStatus", [], PlayerStatus, () => [["InMatchmaking", option_type(class_type("System.DateTime"))], ["ReadyChecks", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [ReadyCheckId_$reflection(), tuple_type(bool_type, class_type("System.DateTime"))])], ["Challenges", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [ChallengeRoom_$reflection()])], ["Rooms", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [RoomId_$reflection(), RoomItem_$reflection()])], ["DailyStatus", DailyStatus_$reflection()]]);
}

export function PlayerStatus_get_empty() {
    return new PlayerStatus(void 0, empty({
        Compare: compare,
    }), empty_1({
        Compare: compare,
    }), empty({
        Compare: compare,
    }), new DailyStatus(empty_2(), 0, false));
}

export class ServerResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Pong", "ServerErrorResponse", "LoggedIn", "LoggedInForLinkChallenge", "LoginFailed", "RegisterFailed", "GotLeaderboard", "GotScoresForLoggedInPlayer", "GotPlayerStatus", "GotPlayer", "GotOnlinePeople", "QueuedForMatchmaking", "WaitingForAnotherPlayer", "ReadyCheck", "ReadyCheckConfirmed", "ReadyCheckDeclined", "ReadyCheckExpired", "MatchStarted", "MatchUpdated", "MatchEnded", "MatchEndedTimedOut", "MatchCancelled", "MatchmakingError", "FriendshipRequested", "FriendshipAccepted", "FriendshipDeclined", "GotFriendsForLoggedInPlayer", "ChallengeSent", "ChallengeReceived", "ChallengeAccepted", "ChallengeRejoined", "ChallengeDeclined", "ChallengeCancelled"];
    }
}

export function ServerResponse_$reflection() {
    return union_type("Shared.Types.ServerResponse", [], ServerResponse, () => [[], [["Item", string_type]], [["Item1", Player_$reflection()], ["Item2", string_type]], [["Item1", Player_$reflection()], ["Item2", string_type], ["Item3", ChallengeId_$reflection()]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])]], [["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])]], [["Item", Leaderboard_$reflection()]], [["Item", list_type(ScoreWithUsernames_$reflection())]], [["Item", PlayerStatus_$reflection()]], [["Item", Player_$reflection()]], [["onlinePlayerCount", int32_type], ["onlineFriends", list_type(FriendshipId_$reflection())]], [], [], [["Item1", ReadyCheckId_$reflection()], ["Item2", list_type(Plane_$reflection())], ["Item3", class_type("System.DateTime")]], [["Item", ReadyCheckId_$reflection()]], [["Item1", ReadyCheckId_$reflection()], ["Item2", bool_type]], [["Item", ReadyCheckId_$reflection()]], [["Item1", RoomId_$reflection()], ["Item2", RoomItem_$reflection()]], [["Item1", RoomId_$reflection()], ["Item2", PublicGameState_$reflection()]], [["Item1", RoomId_$reflection()], ["Item2", PublicGameState_$reflection()]], [["Item1", RoomId_$reflection()], ["Item2", PublicGameState_$reflection()]], [["Item", RoomId_$reflection()]], [["Item", list_type(string_type)]], [], [], [], [["acceptedFriendships", list_type(FriendshipWithDisplayName_$reflection())], ["newFriendships", list_type(FriendshipWithDisplayName_$reflection())]], [["Item", ChallengeRoom_$reflection()]], [["Item", ChallengeRoom_$reflection()]], [["Item", ChallengeRoom_$reflection()]], [["Item1", ChallengeRoom_$reflection()], ["Item2", list_type(Plane_$reflection())]], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]]]);
}

export class GuestServerMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ping", "Login", "Register", "RegisterAnon", "AcceptLinkChallengeAnon"];
    }
}

export function GuestServerMsg_$reflection() {
    return union_type("Shared.Types.GuestServerMsg", [], GuestServerMsg, () => [[], [["Item1", string_type], ["Item2", string_type]], [["Item1", string_type], ["Item2", string_type]], [], [["Item", ChallengeId_$reflection()]]]);
}

export class AuthenticatedServerMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ping", "GetScores", "GetPlayerStatus", "GetLeaderboard", "RegisterWithAnonUser", "SelectIcon", "RequestFriendship", "GetFriends", "AcceptRequest", "DeclineRequest", "GetOnlinePeople"];
    }
}

export function AuthenticatedServerMsg_$reflection() {
    return union_type("Shared.Types.AuthenticatedServerMsg", [], AuthenticatedServerMsg, () => [[], [], [], [], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", FriendshipId_$reflection()]], [["Item", FriendshipId_$reflection()]], []]);
}

export class SocketMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StartListening", "SocketPing", "ChallengeFriend", "ChallengeWithLink", "AcceptChallenge", "DeclineChallenge", "CancelChallenge", "RejoinChallenge", "ReadyCheckConfirm", "ReadyCheckDecline", "QueueForMatchmaking", "AbandonMatchmaking", "Rematch", "StartChallengeMatch", "StartDaily", "RejoinRoom", "MakeMove", "AbandonGame"];
    }
}

export function SocketMsg_$reflection() {
    return union_type("Shared.Types.SocketMsg", [], SocketMsg, () => [[], [], [["Item", FriendshipId_$reflection()]], [], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item", ChallengeId_$reflection()]], [["Item", ReadyCheckId_$reflection()]], [["Item", ReadyCheckId_$reflection()]], [["Item", list_type(Plane_$reflection())]], [], [["Item", RoomId_$reflection()]], [["Item1", ChallengeId_$reflection()], ["Item2", list_type(Plane_$reflection())]], [], [["Item", RoomId_$reflection()]], [["Item1", RoomId_$reflection()], ["Item2", Move_$reflection()]], [["Item", RoomId_$reflection()]]]);
}

export class IAuthenticatedApi extends Record {
    constructor(ping, message) {
        super();
        this.ping = ping;
        this.message = message;
    }
}

export function IAuthenticatedApi_$reflection() {
    return record_type("Shared.Types.IAuthenticatedApi", [], IAuthenticatedApi, () => [["ping", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["message", lambda_type(AuthenticatedServerMsg_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [ServerResponse_$reflection()]))]]);
}

export class IGuestApi extends Record {
    constructor(ping, message, dumpRoom1238a0sd9108a0978s0d9870192830as, seedDailies1238a0sd9108a0978s0d9870192830as) {
        super();
        this.ping = ping;
        this.message = message;
        this.dumpRoom1238a0sd9108a0978s0d9870192830as = dumpRoom1238a0sd9108a0978s0d9870192830as;
        this.seedDailies1238a0sd9108a0978s0d9870192830as = seedDailies1238a0sd9108a0978s0d9870192830as;
    }
}

export function IGuestApi_$reflection() {
    return record_type("Shared.Types.IGuestApi", [], IGuestApi, () => [["ping", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["message", lambda_type(GuestServerMsg_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [ServerResponse_$reflection()]))], ["dumpRoom1238a0sd9108a0978s0d9870192830as", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["seedDailies1238a0sd9108a0978s0d9870192830as", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))]]);
}

