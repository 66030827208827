import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRouterLink,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {useState} from "react";
import * as Ui from '../fjs/fs/Ui';
import {useRedirectOnCondition} from "../hooks/useRedirectOnCondition";
import './Login.scss';


const T = ({children}) => {
    return children;
}

function t(content) {
    return content;
}


function Inner({registerManager}) {
    const [username, setUsername] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [showPassword, setShowPassword] = useState<any>(false);


    return <IonPage>
        <IonContent>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Register</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonList>
                <form onSubmit={(e: any) => {
                    e.preventDefault();

                    let {username, password} = Object.fromEntries((new FormData(e.target)).entries());

                    registerManager.tryRegister(username, password)
                }}>
                    <IonItem lines='full'>
                        <IonLabel position='stacked'>
                            {/*<h1><T>Username</T></h1>*/}
                            {registerManager.getValidationErrorForField("Username").map((err) => {
                                return <IonText color='danger' key={err}>{err}</IonText>
                            })}
                        </IonLabel>
                        <IonInput placeholder={t('Your username')} clearInput
                                  name="username"
                                  label={t("Username")}
                                  labelPlacement="fixed"
                                  type='text'></IonInput>
                        <IonLabel position='stacked'>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines='full'>
                        <IonLabel position='stacked'>
                            {/*<h1><T>Password</T></h1>*/}
                            {registerManager.getValidationErrorForField("Password").map((err) => {
                                return <IonText color='danger' key={err}>{err}</IonText>
                            })}
                        </IonLabel>
                        <IonInput placeholder={t('Your password')} clearInput
                                  name="password"
                                  label={t("Password")}
                                  labelPlacement="fixed"
                                  type={showPassword ? 'text' : 'password'}></IonInput>
                    </IonItem>

                    <IonItem lines='full'>
                        <IonCheckbox checked={showPassword}
                                     aria-label={t("Show password")}
                                     onIonChange={(e) => setShowPassword(e.detail.checked)}>
                            <T>Show password</T>
                        </IonCheckbox>
                    </IonItem>
                    <IonItem lines='none'>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <IonButton color='primary' size='default' type="submit"
                            ><T>Register</T></IonButton>

                            <IonRouterLink routerLink="/login" style={{"marginLeft": "10px"}}>Back to login</IonRouterLink>
                        </div>

                    </IonItem>
                </form>
            </IonList>

            <IonGrid>
                <IonRow>
                    <IonCol style={{"textAlign": "center"}}>
                        <span>[release_date_here]</span>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>;
}

function Register({state, dispatch}) {

    const redirected = useRedirectOnCondition([
        [(() => state.Player && !Ui.isAnonPlayer(state)), "/"],
    ]);

    if (redirected) {
        return <div>redirecting</div>;
    }

    let registerManager = Ui.getRegisterManager(state, dispatch);


    return <Inner registerManager={registerManager}/>
}

export default Register