import { useEffect } from 'react';
import { useHistory } from 'react-router';
export const useRedirectOnCondition = (conditionRedirectPairs: [Function,string][]) => {  

    const history = useHistory();

    let redirectPath = (() => {
        for (let index = 0; index < conditionRedirectPairs.length; index++) {
            const [condition, redirectPath] = conditionRedirectPairs[index];
            
            if (condition()) {
                return redirectPath
            }
            
        }
    })();

    useEffect(() => {
        if (redirectPath) {
            console.log("router redirect to", redirectPath)
            history.push(redirectPath)
        }
    }, [redirectPath])

    let redirected = !! redirectPath;

    return redirected;
}