import { createAtom } from "../fable_modules/fable-library.4.1.4/Util.js";
import { op_Subtraction, now } from "../fable_modules/fable-library.4.1.4/Date.js";
import { totalSeconds } from "../fable_modules/fable-library.4.1.4/TimeSpan.js";
import { debug, log, getEnv } from "./Utils.js";
import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_withCustomHeader, RemotingModule_createApi, RemotingModule_withBaseUrl } from "../fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { defaultArg } from "../fable_modules/fable-library.4.1.4/Option.js";
import { singleton } from "../fable_modules/fable-library.4.1.4/List.js";
import { printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { SocketMsg_$reflection, ServerResponse_$reflection, IGuestApi_$reflection, IAuthenticatedApi_$reflection } from "../Shared/Types.js";
import { Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { record_type, class_type, lambda_type, unit_type, string_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import * as signalr from "../../js/signalr.ts";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.1.4/Choice.js";
import { SimpleJson_tryParse } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { Convert_serialize, Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { Msg } from "./Types.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.4.1.4/AsyncBuilder.js";
import { awaitPromise } from "../fable_modules/fable-library.4.1.4/Async.js";

export let lastPing = createAtom(now());

export let lastPong = createAtom(now());

export function isConnectionBad() {
    const timespan = op_Subtraction(lastPing(), lastPong());
    const r = totalSeconds(timespan) > 6;
    return r;
}

export const endpoint = (() => {
    const matchValue = getEnv();
    return (matchValue.tag === 1) ? "https://avioane-api.addalgo.com" : "https://localhost:5001";
})();

export const apiEndpoint = endpoint + "/api";

export function authenticatedApi(apiKeyOption) {
    let options_1, options, arg;
    return Remoting_buildProxy_64DC51C(RemotingModule_withBaseUrl(apiEndpoint, (options_1 = ((options = RemotingModule_createApi(), RemotingModule_withCustomHeader(singleton(["access-token", defaultArg(apiKeyOption, "")]), options))), RemotingModule_withAuthorizationHeader((arg = defaultArg(apiKeyOption, ""), toText(printf("Bearer %s"))(arg)), options_1))), IAuthenticatedApi_$reflection());
}

export const guestApi = Remoting_buildProxy_64DC51C(RemotingModule_withBaseUrl(apiEndpoint, RemotingModule_createApi()), IGuestApi_$reflection());

export class ISignalR extends Record {
    constructor(init, SocketMsg) {
        super();
        this.init = init;
        this.SocketMsg = SocketMsg;
    }
}

export function ISignalR_$reflection() {
    return record_type("Remote.ISignalR", [], ISignalR, () => [["init", lambda_type(string_type, lambda_type(string_type, lambda_type(lambda_type(string_type, unit_type), unit_type)))], ["SocketMsg", lambda_type(string_type, class_type("Fable.Core.JS.Promise`1", [unit_type]))]]);
}

export let SignalR_inited = createAtom(false);

export let SignalR_apiKey = createAtom(void 0);

export let SignalR_dispatch = createAtom(void 0);

export function SignalR_setApiKey(apiKey$0027) {
    SignalR_apiKey(apiKey$0027);
}

export function SignalR_setDispatch(dispatch$0027) {
    SignalR_dispatch(dispatch$0027);
}

export function SignalR_init(dispatch, apiKey) {
    if (!SignalR_inited()) {
        SignalR_inited(true);
        signalr.init(endpoint, apiKey, (json) => {
            let matchValue, inputJson, typeInfo;
            let matchValue_1;
            try {
                matchValue_1 = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(json), (matchValue != null) ? ((inputJson = matchValue, (typeInfo = createTypeInfo(ServerResponse_$reflection()), Convert_fromJson(inputJson, typeInfo)))) : (() => {
                    throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                })())]));
            }
            catch (ex) {
                matchValue_1 = (new FSharpResult$2(1, [ex.message]));
            }
            if (matchValue_1.tag === 1) {
                const m = matchValue_1.fields[0];
                log(["SignalR server response error", m, json]);
            }
            else {
                const serverResponse = matchValue_1.fields[0];
                dispatch(new Msg(0, [serverResponse]));
            }
        });
    }
}

export function SignalR_SendMessage(msg) {
    let arg;
    log((arg = SignalR_apiKey(), toText(printf("Send socket message for: %A %A"))(arg)(msg)));
    const matchValue = SignalR_dispatch();
    const matchValue_1 = SignalR_apiKey();
    let matchResult, apiKey, dispatch;
    if (matchValue != null) {
        if (matchValue_1 != null) {
            matchResult = 0;
            apiKey = matchValue_1;
            dispatch = matchValue;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            SignalR_init(dispatch, apiKey);
            let json;
            const typeInfo = createTypeInfo(SocketMsg_$reflection());
            json = Convert_serialize(msg, typeInfo);
            return singleton_1.Delay(() => singleton_1.Bind(awaitPromise(signalr.SocketMsg(json)), () => singleton_1.Return(void 0)));
        }
        default: {
            log("Missing dispatch or apiKey");
            throw new Error("Missing dispatch or apiKey");
        }
    }
}

export let lastReconnectAttempt = createAtom(now());

export function tryReconnect(dispatch) {
    const shouldRetry = () => {
        const timespan = op_Subtraction(now(), lastReconnectAttempt());
        return totalSeconds(timespan) > 5;
    };
    const matchValue = isConnectionBad();
    const matchValue_1 = shouldRetry();
    if (matchValue) {
        if (matchValue_1) {
            debug(">> attempting reconnect");
            lastReconnectAttempt(now());
            SignalR_inited(false);
            dispatch(new Msg(6, []));
        }
        else {
            debug(">> connection bad, skipping retry because it is too soon");
        }
    }
    else if (matchValue_1) {
        debug(">> should retry but connection is ok");
    }
    else {
        debug(">> connection is ok, should not retry");
    }
}

