import GameQueueSwitchItem from "./GameQueueSwitchItem";
import GameSwitchItem from "./GameSwitchItem";
import * as Ui from "./fjs/fs/Ui";
import './SwitchBar.scss';
import GameHomeSwitchItem from "./GameHomeSwitchItem";

const SwitchBar = (
    {
        state,
        dispatch,
        currentRoomId
    }) =>  {

    let switchItems = Ui.mapRoomsToGameSwitchItems(state, dispatch, currentRoomId);
    let queueState = Ui.getQueueState(state, dispatch);
    let homeState = Ui.getHomeState(state, dispatch);

    return <div className="switch-container">
        <GameHomeSwitchItem {...homeState}/> 
        {/*<GameQueueSwitchItem {...queueState}/>*/} 
        {switchItems.map((si) => <GameSwitchItem key={si.roomId} {...si} />)}
    </div>
}

export default SwitchBar;

