import { contains, length, head, tail, isEmpty, cons, map as map_1, empty as empty_1, ofArray, filter, singleton, append } from "../fable_modules/fable-library.4.1.4/List.js";
import { ValidationError, State, Msg, UiState as UiState_9 } from "./Types.js";
import { safeHash, comparePrimitives, compare, equals } from "../fable_modules/fable-library.4.1.4/Util.js";
import { Cmd_OfAsyncWith_perform, Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.0.0/cmd.fs.js";
import { tryReconnect, SignalR_setDispatch, lastPong, lastPing, SignalR_setApiKey, SignalR_SendMessage, guestApi, authenticatedApi } from "./Remote.js";
import { presentErrorToast, presentInfoToast, pushRouter, log } from "./Utils.js";
import { SocketMsg, GuestServerMsg, AuthenticatedServerMsg, ServerResponse, PlayerStatus as PlayerStatus_1, RoomItem, PublicPlayer, RoomId, ChallengeStatus, ChallengeType, ReadyCheckId, PlayerStatus_get_empty, FriendshipWithDisplayName, FriendshipStatus, FriendshipId, ScoreWithUsernames, Player as Player_4, PlayerType, ChallengeRoom, ChallengeId, ChallengePlayer, PublicGameState, MoveResult, PlayerId } from "../Shared/Types.js";
import { now, addHours, addDays, utcNow, addMinutes } from "../fable_modules/fable-library.4.1.4/Date.js";
import { filter as filter_1, add as add_1, ofList } from "../fable_modules/fable-library.4.1.4/Set.js";
import { tryFind, remove, map as map_2, add, ofList as ofList_1, empty } from "../fable_modules/fable-library.4.1.4/Map.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.0/cmd.fs.js";
import { storeAllInStorage, tryGetAllFromStorageAsync } from "./Local.js";
import { bind, map, defaultArg } from "../fable_modules/fable-library.4.1.4/Option.js";
import { toFail, printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.4.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.0.0/program.fs.js";
import { ping, recheckAuth } from "./Ui.js";
import { useReact_useEffect_7331F961 } from "../fable_modules/Feliz.2.6.0/React.fs.js";
import App_1 from "../../App.tsx";

export function UiState_tryGetActiveRoomId(uiState) {
    switch (uiState.tag) {
        case 2:
        case 1:
        case 3:
        case 0:
            return void 0;
        default: {
            const roomId = uiState.fields[0];
            return roomId;
        }
    }
}

export function UiState_tryAddPlane(uiState, plane) {
    switch (uiState.tag) {
        case 1: {
            const planes_1 = uiState.fields[0];
            return new UiState_9(1, [append(singleton(plane), planes_1)]);
        }
        case 3: {
            const planes_2 = uiState.fields[1];
            const id_1 = uiState.fields[0];
            return new UiState_9(3, [id_1, append(singleton(plane), planes_2)]);
        }
        case 0:
            return uiState;
        case 4: {
            const roomId = uiState.fields[0];
            return uiState;
        }
        default: {
            const planes = uiState.fields[1];
            const id = uiState.fields[0];
            return new UiState_9(2, [id, append(singleton(plane), planes)]);
        }
    }
}

export function UiState_tryRemovePlane(uiState, plane) {
    switch (uiState.tag) {
        case 1: {
            const planes_1 = uiState.fields[0];
            const newPlanes = filter((p_1) => !equals(p_1, plane), planes_1);
            return new UiState_9(1, [newPlanes]);
        }
        case 3: {
            const planes_2 = uiState.fields[1];
            const id_1 = uiState.fields[0];
            return new UiState_9(3, [id_1, filter((p_2) => !equals(p_2, plane), planes_2)]);
        }
        case 0:
            return uiState;
        case 4: {
            const roomId = uiState.fields[0];
            return uiState;
        }
        default: {
            const planes = uiState.fields[1];
            const id = uiState.fields[0];
            return new UiState_9(2, [id, filter((p) => !equals(p, plane), planes)]);
        }
    }
}

/**
 * Sends a Server Message to the server and emits a GotServerResponse of ServerReponse
 */
export function Cmd_ofAsyncAuthenticatedServerMessage(apiKeyOption, msg) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, authenticatedApi(apiKeyOption).message, msg, (r) => (new Msg(0, [r])), (exn) => (new Msg(1, [exn])));
}

export function Cmd_ofAsyncGuestServerMessage(msg) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, guestApi.message, msg, (r) => (new Msg(0, [r])), (exn) => (new Msg(1, [exn])));
}

export function Cmd_ofSocketMessage(msg) {
    return Cmd_OfAsyncWith_attempt((x) => {
        Cmd_OfAsync_start(x);
    }, SignalR_SendMessage, msg, (exn) => (new Msg(1, [exn])));
}

export function Cmd_ofNavigateAnd(path, fn) {
    log(["Msg navigate to", path]);
    pushRouter(path);
    return singleton((dispatch) => {
        dispatch(new Msg(4, [fn]));
    });
}

export function Cmd_ofNavigate(path) {
    return Cmd_ofNavigateAnd(path, (x) => x);
}

export const gs = new PublicGameState(ofArray([[new PlayerId(1), new MoveResult(1, [[0, 0]])], [new PlayerId(1), new MoveResult(0, [[2, 0]])], [new PlayerId(2), new MoveResult(0, [[2, 0]])], [new PlayerId(1), new MoveResult(2, [[1, 0]])], [new PlayerId(1), new MoveResult(3, [])]]), new PlayerId(1), new PlayerId(1), addMinutes(utcNow(), 1));

export function emptyChallengeRoom(id, typ, status) {
    const ChallengerPlayer = new ChallengePlayer(new PlayerId(1), "Player1", "plane-y");
    return new ChallengeRoom(new ChallengeId(id), ofList(ofArray([new ChallengePlayer(new PlayerId(2), "Player2", "plane-y"), new ChallengePlayer(new PlayerId(3), "Player3", "plane-y")]), {
        Compare: compare,
    }), ChallengerPlayer, typ, status, utcNow());
}

export const testState = (() => {
    const ValidationErrors = empty({
        Compare: comparePrimitives,
    });
    const Player = new Player_4(new PlayerId(1), "Cosmin", "Cosmin", "plane-y", new PlayerType(0, []));
    const Scores = ofArray([new ScoreWithUsernames("Cosmin", "Lavinia", 2, utcNow()), new ScoreWithUsernames("Test", "Test2", 2, addDays(utcNow(), -1)), new ScoreWithUsernames("Lavinia", "Cosmin", 2, addHours(utcNow(), -1)), new ScoreWithUsernames("Lavinia", "Cosmin", 2, addHours(addDays(utcNow(), -1), -2))]);
    const Friends = singleton(new FriendshipWithDisplayName(new FriendshipId(1), new PlayerId(1), "Test Friend", new FriendshipStatus(1, [])));
    const Requests = singleton(new FriendshipWithDisplayName(new FriendshipId(2), new PlayerId(2), "Test Friend Request", new FriendshipStatus(0, [])));
    let PlayerStatus;
    const inputRecord = PlayerStatus_get_empty();
    PlayerStatus = (new PlayerStatus_1(utcNow(), ofList_1(ofArray([[new ReadyCheckId("zzzap"), [false, utcNow()]], [new ReadyCheckId("mmm"), [true, utcNow()]]]), {
        Compare: compare,
    }), ofList(ofArray([emptyChallengeRoom(1, new ChallengeType(1, []), new ChallengeStatus(3, [])), emptyChallengeRoom(2, new ChallengeType(1, []), new ChallengeStatus(0, [])), emptyChallengeRoom(3, new ChallengeType(1, []), new ChallengeStatus(2, [])), emptyChallengeRoom(3, new ChallengeType(1, []), new ChallengeStatus(5, [])), emptyChallengeRoom(3, new ChallengeType(1, []), new ChallengeStatus(4, [])), emptyChallengeRoom(3, new ChallengeType(1, []), new ChallengeStatus(1, [])), emptyChallengeRoom(4, new ChallengeType(0, [new RoomId("welp")]), new ChallengeStatus(3, [])), emptyChallengeRoom(5, new ChallengeType(0, [new RoomId("welp1")]), new ChallengeStatus(0, [])), emptyChallengeRoom(6, new ChallengeType(0, [new RoomId("welp2")]), new ChallengeStatus(2, [])), emptyChallengeRoom(6, new ChallengeType(0, [new RoomId("welp3")]), new ChallengeStatus(5, [])), emptyChallengeRoom(6, new ChallengeType(0, [new RoomId("welp4")]), new ChallengeStatus(4, [])), emptyChallengeRoom(6, new ChallengeType(0, [new RoomId("welp5")]), new ChallengeStatus(1, [])), emptyChallengeRoom(7, new ChallengeType(2, []), new ChallengeStatus(3, [])), emptyChallengeRoom(8, new ChallengeType(2, []), new ChallengeStatus(0, [])), emptyChallengeRoom(9, new ChallengeType(2, []), new ChallengeStatus(2, [])), emptyChallengeRoom(9, new ChallengeType(2, []), new ChallengeStatus(5, [])), emptyChallengeRoom(9, new ChallengeType(2, []), new ChallengeStatus(4, [])), emptyChallengeRoom(9, new ChallengeType(2, []), new ChallengeStatus(1, []))]), {
        Compare: compare,
    }), ofList_1(ofArray([[new RoomId("welp1"), new RoomItem(0, [new PublicGameState(empty_1(), new PlayerId(1), void 0, addMinutes(utcNow(), 1)), empty_1(), ofList_1(singleton([new PlayerId(1234), new PublicPlayer("Test player", "plane-b")]), {
        Compare: compare,
    }), utcNow()])], [new RoomId("welp3"), new RoomItem(1, [new PublicGameState(empty_1(), new PlayerId(1), void 0, addMinutes(utcNow(), 2)), empty_1(), ofList_1(singleton([new PlayerId(1234), new PublicPlayer("Test player", "plane-b")]), {
        Compare: compare,
    }), utcNow()])], [new RoomId("welp5"), new RoomItem(2, [new PublicGameState(empty_1(), new PlayerId(1), void 0, addMinutes(utcNow(), 1))])]]), {
        Compare: compare,
    }), inputRecord.DailyStatus));
    return new State(ValidationErrors, true, true, 0, void 0, Player, singleton("Hello World"), empty_1(), empty({
        Compare: compare,
    }), Scores, Friends, 100, singleton(new FriendshipId(1)), 3, Requests, true, new UiState_9(0, []), PlayerStatus, true, true, void 0);
})();

export const emptyState = (() => {
    const ValidationErrors = empty({
        Compare: comparePrimitives,
    });
    const PlayerStatus = PlayerStatus_get_empty();
    return new State(ValidationErrors, false, false, 0, void 0, void 0, singleton("Hello World"), empty_1(), empty({
        Compare: compare,
    }), empty_1(), empty_1(), 0, empty_1(), 0, empty_1(), false, new UiState_9(0, []), PlayerStatus, true, true, void 0);
})();

export function init() {
    return [emptyState, Cmd_batch(singleton(Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, tryGetAllFromStorageAsync, void 0, (all) => {
        let message;
        if (all != null) {
            const all_1 = all;
            const matchValue = all_1.ApiKey;
            const matchValue_1 = all_1.Player;
            let matchResult, apiKey, player;
            if (matchValue != null) {
                if (matchValue_1 != null) {
                    matchResult = 0;
                    apiKey = matchValue;
                    player = matchValue_1;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    message = (new Msg(0, [new ServerResponse(2, [player, apiKey])]));
                    break;
                }
                default:
                    message = (new Msg(2, [singleton(new Msg(2, [empty_1()]))]));
            }
        }
        else {
            message = (new Msg(2, [empty_1()]));
        }
        return new Msg(5, [(state) => {
            const SoundOn = defaultArg(map((all_2) => all_2.SoundOn, all), true);
            return new State(state.ValidationErrors, true, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, defaultArg(map((all_3) => all_3.VibrationOn, all), true), SoundOn, state.Leaderboard);
        }, message]);
    })))];
}

export function update(msg, state) {
    let matchResult, fn, messages, fn_1, msg_3, fn_2, roomId, password, username, password_1, username_1, icon, pathname, player, apiKey, player_1, apiKey_1, challengeId, player_2, errors, errors_1, playerStatus, roomId_1, roomItem, date, planes, roomId_2, roomId_3, readyCheckId, selfDeclined, readyCheckId_1, publicGameState, roomId_5, publicGameState_1, roomId_6, roomId_7, scores, leaderboard, friends, requests, onlineFriends, onlinePlayerCount, challengeRoom, roomId_8, challenge, challenge_1, planes_5, challengeId_1, challengeId_2, m, e, roomId_9, roomId_10, roomId_11, roomId_12, roomId_13, challengeId_5, friendshipId, challengeId_6, challengeId_7, challengeId_8, challengeId_9, move_2, roomId_14, move_3, roomId_15, roomId_16, plane, plane_1, username_2, friendshipId_1, friendshipId_2;
    switch (msg.tag) {
        case 2: {
            matchResult = 1;
            messages = msg.fields[0];
            break;
        }
        case 5: {
            matchResult = 2;
            fn_1 = msg.fields[0];
            msg_3 = msg.fields[1];
            break;
        }
        case 4: {
            matchResult = 3;
            fn_2 = msg.fields[0];
            break;
        }
        case 40: {
            matchResult = 4;
            break;
        }
        case 41: {
            matchResult = 5;
            break;
        }
        case 42: {
            matchResult = 6;
            roomId = msg.fields[0];
            break;
        }
        case 10: {
            matchResult = 7;
            password = msg.fields[0][1];
            username = msg.fields[0][0];
            break;
        }
        case 11: {
            matchResult = 8;
            break;
        }
        case 9: {
            matchResult = 9;
            password_1 = msg.fields[0][1];
            username_1 = msg.fields[0][0];
            break;
        }
        case 12: {
            matchResult = 10;
            break;
        }
        case 13: {
            matchResult = 11;
            icon = msg.fields[0];
            break;
        }
        case 8: {
            matchResult = 12;
            break;
        }
        case 6: {
            matchResult = 13;
            break;
        }
        case 7: {
            matchResult = 14;
            break;
        }
        case 26: {
            matchResult = 15;
            break;
        }
        case 27: {
            matchResult = 16;
            pathname = msg.fields[0];
            break;
        }
        case 0: {
            switch (msg.fields[0].tag) {
                case 0: {
                    matchResult = 17;
                    break;
                }
                case 9: {
                    matchResult = 18;
                    player = msg.fields[0].fields[0];
                    break;
                }
                case 2: {
                    matchResult = 19;
                    apiKey = msg.fields[0].fields[1];
                    player_1 = msg.fields[0].fields[0];
                    break;
                }
                case 3: {
                    matchResult = 20;
                    apiKey_1 = msg.fields[0].fields[1];
                    challengeId = msg.fields[0].fields[2];
                    player_2 = msg.fields[0].fields[0];
                    break;
                }
                case 4: {
                    matchResult = 21;
                    errors = msg.fields[0].fields[0];
                    break;
                }
                case 5: {
                    matchResult = 22;
                    errors_1 = msg.fields[0].fields[0];
                    break;
                }
                case 12: {
                    matchResult = 23;
                    break;
                }
                case 8: {
                    matchResult = 24;
                    playerStatus = msg.fields[0].fields[0];
                    break;
                }
                case 11: {
                    matchResult = 25;
                    break;
                }
                case 17: {
                    matchResult = 26;
                    roomId_1 = msg.fields[0].fields[0];
                    roomItem = msg.fields[0].fields[1];
                    break;
                }
                case 13: {
                    matchResult = 27;
                    date = msg.fields[0].fields[2];
                    planes = msg.fields[0].fields[1];
                    roomId_2 = msg.fields[0].fields[0];
                    break;
                }
                case 14: {
                    matchResult = 28;
                    roomId_3 = msg.fields[0].fields[0];
                    break;
                }
                case 15: {
                    matchResult = 29;
                    readyCheckId = msg.fields[0].fields[0];
                    selfDeclined = msg.fields[0].fields[1];
                    break;
                }
                case 16: {
                    matchResult = 30;
                    readyCheckId_1 = msg.fields[0].fields[0];
                    break;
                }
                case 18: {
                    matchResult = 31;
                    publicGameState = msg.fields[0].fields[1];
                    roomId_5 = msg.fields[0].fields[0];
                    break;
                }
                case 19: {
                    matchResult = 32;
                    publicGameState_1 = msg.fields[0].fields[1];
                    roomId_6 = msg.fields[0].fields[0];
                    break;
                }
                case 20: {
                    matchResult = 32;
                    publicGameState_1 = msg.fields[0].fields[1];
                    roomId_6 = msg.fields[0].fields[0];
                    break;
                }
                case 21: {
                    matchResult = 33;
                    roomId_7 = msg.fields[0].fields[0];
                    break;
                }
                case 7: {
                    matchResult = 34;
                    scores = msg.fields[0].fields[0];
                    break;
                }
                case 6: {
                    matchResult = 35;
                    leaderboard = msg.fields[0].fields[0];
                    break;
                }
                case 26: {
                    matchResult = 36;
                    friends = msg.fields[0].fields[0];
                    requests = msg.fields[0].fields[1];
                    break;
                }
                case 10: {
                    matchResult = 37;
                    onlineFriends = msg.fields[0].fields[1];
                    onlinePlayerCount = msg.fields[0].fields[0];
                    break;
                }
                case 23: {
                    matchResult = 38;
                    break;
                }
                case 24: {
                    matchResult = 39;
                    break;
                }
                case 25: {
                    matchResult = 40;
                    break;
                }
                case 27: {
                    matchResult = 41;
                    challengeRoom = msg.fields[0].fields[0];
                    break;
                }
                case 28: {
                    matchResult = 42;
                    roomId_8 = msg.fields[0].fields[0];
                    break;
                }
                case 29: {
                    matchResult = 43;
                    challenge = msg.fields[0].fields[0];
                    break;
                }
                case 30: {
                    matchResult = 44;
                    challenge_1 = msg.fields[0].fields[0];
                    planes_5 = msg.fields[0].fields[1];
                    break;
                }
                case 31: {
                    matchResult = 45;
                    challengeId_1 = msg.fields[0].fields[0];
                    break;
                }
                case 32: {
                    matchResult = 46;
                    challengeId_2 = msg.fields[0].fields[0];
                    break;
                }
                case 1: {
                    matchResult = 47;
                    m = msg.fields[0].fields[0];
                    break;
                }
                default:
                    matchResult = 48;
            }
            break;
        }
        case 1: {
            matchResult = 49;
            e = msg.fields[0];
            break;
        }
        case 17: {
            matchResult = 50;
            break;
        }
        case 18: {
            matchResult = 51;
            break;
        }
        case 14: {
            matchResult = 52;
            roomId_9 = msg.fields[0];
            break;
        }
        case 25: {
            matchResult = 53;
            roomId_10 = msg.fields[0];
            break;
        }
        case 24: {
            matchResult = 54;
            break;
        }
        case 22: {
            matchResult = 55;
            roomId_11 = msg.fields[0];
            break;
        }
        case 20: {
            matchResult = 56;
            roomId_12 = msg.fields[0];
            break;
        }
        case 21: {
            matchResult = 57;
            roomId_13 = msg.fields[0];
            break;
        }
        case 19: {
            matchResult = 58;
            break;
        }
        case 23: {
            matchResult = 59;
            challengeId_5 = msg.fields[0];
            break;
        }
        case 35: {
            matchResult = 60;
            friendshipId = msg.fields[0];
            break;
        }
        case 36: {
            matchResult = 61;
            challengeId_6 = msg.fields[0];
            break;
        }
        case 37: {
            matchResult = 62;
            challengeId_7 = msg.fields[0];
            break;
        }
        case 38: {
            matchResult = 63;
            challengeId_8 = msg.fields[0];
            break;
        }
        case 39: {
            matchResult = 64;
            challengeId_9 = msg.fields[0];
            break;
        }
        case 31: {
            matchResult = 65;
            move_2 = msg.fields[1];
            roomId_14 = msg.fields[0];
            break;
        }
        case 30: {
            matchResult = 66;
            move_3 = msg.fields[1];
            roomId_15 = msg.fields[0];
            break;
        }
        case 28: {
            matchResult = 67;
            roomId_16 = msg.fields[0];
            break;
        }
        case 29: {
            matchResult = 68;
            break;
        }
        case 15: {
            matchResult = 69;
            plane = msg.fields[0];
            break;
        }
        case 16: {
            matchResult = 70;
            plane_1 = msg.fields[0];
            break;
        }
        case 32: {
            matchResult = 71;
            username_2 = msg.fields[0];
            break;
        }
        case 33: {
            matchResult = 72;
            friendshipId_1 = msg.fields[0];
            break;
        }
        case 34: {
            matchResult = 73;
            friendshipId_2 = msg.fields[0];
            break;
        }
        default: {
            matchResult = 0;
            fn = msg.fields[0];
        }
    }
    switch (matchResult) {
        case 0: {
            fn(state.Loaded);
            return [state, Cmd_none()];
        }
        case 1:
            return [state, Cmd_batch(map_1((msg_1) => singleton((dispatch) => {
                dispatch(msg_1);
            }), messages))];
        case 2: {
            const newState = fn_1(state);
            storeAllInStorage(newState);
            return [newState, Cmd_batch(singleton(singleton((dispatch_1) => {
                dispatch_1(msg_3);
            })))];
        }
        case 3:
            return [state, singleton((dispatch_2) => {
                dispatch_2(new Msg(5, [fn_2, new Msg(2, [empty_1()])]));
            })];
        case 4:
            return [state, singleton((dispatch_3) => {
                dispatch_3(new Msg(4, [(state_1) => {
                    let arg;
                    presentInfoToast((arg = (state_1.SoundOn ? "OFF" : "ON"), toText(printf("Sounds are now %s"))(arg)));
                    return new State(state_1.ValidationErrors, state_1.Loaded, state_1.PlayerStatusLoaded, state_1.LatestPingPong, state_1.ApiKey, state_1.Player, state_1.Messages, state_1.HiddenRooms, state_1.QueuedMoves, state_1.Scores, state_1.Friends, state_1.OnlineCount, state_1.OnlineFriends, state_1.ActiveWaitingRoomCount, state_1.Requests, state_1.MatchmakingStatus, state_1.UiState, state_1.PlayerStatus, state_1.VibrationOn, !state_1.SoundOn, state_1.Leaderboard);
                }]));
            })];
        case 5:
            return [state, singleton((dispatch_4) => {
                dispatch_4(new Msg(4, [(state_2) => {
                    let arg_1;
                    presentInfoToast((arg_1 = (state_2.VibrationOn ? "OFF" : "ON"), toText(printf("Vibrations are now %s"))(arg_1)));
                    return new State(state_2.ValidationErrors, state_2.Loaded, state_2.PlayerStatusLoaded, state_2.LatestPingPong, state_2.ApiKey, state_2.Player, state_2.Messages, state_2.HiddenRooms, state_2.QueuedMoves, state_2.Scores, state_2.Friends, state_2.OnlineCount, state_2.OnlineFriends, state_2.ActiveWaitingRoomCount, state_2.Requests, state_2.MatchmakingStatus, state_2.UiState, state_2.PlayerStatus, !state_2.VibrationOn, state_2.SoundOn, state_2.Leaderboard);
                }]));
            })];
        case 6:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, cons(roomId, state.HiddenRooms), state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), singleton((dispatch_5) => {
                dispatch_5(new Msg(27, ["/dashboard"]));
            })];
        case 7: {
            const matchValue = state.Player;
            let matchResult_1;
            if (matchValue != null) {
                if (matchValue.Type.tag === 1) {
                    matchResult_1 = 0;
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(4, [username, password]))];
                default:
                    return [state, Cmd_ofAsyncGuestServerMessage(new GuestServerMsg(2, [username, password]))];
            }
        }
        case 8:
            return [state, Cmd_ofAsyncGuestServerMessage(new GuestServerMsg(3, []))];
        case 9:
            return [state, Cmd_ofAsyncGuestServerMessage(new GuestServerMsg(1, [username_1, password_1]))];
        case 10:
            return [state, Cmd_ofNavigateAnd("/", (state_3) => {
                const state_4 = new State(emptyState.ValidationErrors, true, emptyState.PlayerStatusLoaded, emptyState.LatestPingPong, emptyState.ApiKey, emptyState.Player, emptyState.Messages, emptyState.HiddenRooms, emptyState.QueuedMoves, emptyState.Scores, emptyState.Friends, emptyState.OnlineCount, emptyState.OnlineFriends, emptyState.ActiveWaitingRoomCount, emptyState.Requests, emptyState.MatchmakingStatus, emptyState.UiState, emptyState.PlayerStatus, emptyState.VibrationOn, emptyState.SoundOn, emptyState.Leaderboard);
                storeAllInStorage(state_4);
                SignalR_setApiKey("");
                return state_4;
            })];
        case 11:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(5, [icon]))];
        case 12:
            if (state.ApiKey != null) {
                lastPing(now());
                log("ping");
                return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong + 1, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_batch(singleton(Cmd_ofSocketMessage(new SocketMsg(1, []))))];
            }
            else {
                return [state, Cmd_none()];
            }
        case 13:
            if (state.ApiKey != null) {
                return [state, Cmd_batch(ofArray([Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(1, [])), Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(7, [])), Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(10, [])), Cmd_ofSocketMessage(new SocketMsg(0, []))]))];
            }
            else {
                return [state, Cmd_none()];
            }
        case 14:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(3, []))];
        case 15:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, void 0, void 0, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_ofNavigate("/")];
        case 16:
            return [state, Cmd_ofNavigate(pathname)];
        case 17: {
            lastPong(now());
            log("pong");
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong + 1, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 18: {
            const state_5 = new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard);
            storeAllInStorage(state_5);
            return [state_5, Cmd_batch(singleton(singleton((dispatch_6) => {
                dispatch_6(new Msg(6, []));
            })))];
        }
        case 19: {
            SignalR_setApiKey(apiKey);
            const state_6 = new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, apiKey, player_1, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard);
            storeAllInStorage(state_6);
            return [state_6, Cmd_batch(singleton(singleton((dispatch_7) => {
                dispatch_7(new Msg(6, []));
            })))];
        }
        case 20: {
            SignalR_setApiKey(apiKey_1);
            const state_7 = new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, apiKey_1, player_2, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard);
            storeAllInStorage(state_7);
            return [state_7, Cmd_batch(ofArray([singleton((dispatch_8) => {
                dispatch_8(new Msg(6, []));
            }), singleton((dispatch_9) => {
                dispatch_9(new Msg(5, [(state_8) => state_8, new Msg(36, [challengeId])]));
            })]))];
        }
        case 21: {
            const state_9 = new State(ofList_1(singleton(["login", new ValidationError(errors)]), {
                Compare: comparePrimitives,
            }), state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard);
            return [state_9, Cmd_none()];
        }
        case 22: {
            const state_10 = new State(ofList_1(singleton(["register", new ValidationError(errors_1)]), {
                Compare: comparePrimitives,
            }), state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard);
            return [state_10, Cmd_none()];
        }
        case 23: {
            log("Server says: Waiting for another player");
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(0, []), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_ofNavigate("/dashboard")];
        }
        case 24: {
            log("Server says: Got player status");
            return [new State(state.ValidationErrors, state.Loaded, true, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 25: {
            log("Server says: Queued for matchmaking");
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(0, []), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_ofNavigate("/dashboard")];
        }
        case 26: {
            log("Server says: Match started");
            const roomIdString = roomId_1.fields[0];
            let playerStatus_1;
            const inputRecord = state.PlayerStatus;
            playerStatus_1 = (new PlayerStatus_1(inputRecord.InMatchmaking, inputRecord.ReadyChecks, inputRecord.Challenges, add(roomId_1, roomItem, state.PlayerStatus.Rooms), inputRecord.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(4, [roomId_1]), playerStatus_1, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_ofNavigate(toText(printf("/play/%s"))(roomIdString))];
        }
        case 27: {
            log("Server says: Ready check");
            let playerStatus_2;
            const inputRecord_1 = state.PlayerStatus;
            playerStatus_2 = (new PlayerStatus_1(inputRecord_1.InMatchmaking, add(roomId_2, [false, date], state.PlayerStatus.ReadyChecks), inputRecord_1.Challenges, inputRecord_1.Rooms, inputRecord_1.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_2, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_ofNavigate("/dashboard")];
        }
        case 28: {
            log("Server says: Ready check confirmed");
            let playerStatus_3;
            const inputRecord_2 = state.PlayerStatus;
            playerStatus_3 = (new PlayerStatus_1(inputRecord_2.InMatchmaking, map_2((roomId_4, tupledArg) => {
                const date_1 = tupledArg[1];
                return [true, date_1];
            }, state.PlayerStatus.ReadyChecks), inputRecord_2.Challenges, inputRecord_2.Rooms, inputRecord_2.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_3, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 29: {
            log("Server says: Ready check declined");
            let playerStatus_4;
            const inputRecord_3 = state.PlayerStatus;
            playerStatus_4 = (new PlayerStatus_1(inputRecord_3.InMatchmaking, remove(readyCheckId, state.PlayerStatus.ReadyChecks), inputRecord_3.Challenges, inputRecord_3.Rooms, inputRecord_3.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_4, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 30: {
            log("Server says: Ready check expired");
            let playerStatus_5;
            const inputRecord_4 = state.PlayerStatus;
            playerStatus_5 = (new PlayerStatus_1(inputRecord_4.InMatchmaking, remove(readyCheckId_1, state.PlayerStatus.ReadyChecks), inputRecord_4.Challenges, inputRecord_4.Rooms, inputRecord_4.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_5, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 31: {
            log(["Server says: Match updated. Next player:", publicGameState.NextPlayerId]);
            let room_1;
            const matchValue_1 = tryFind(roomId_5, state.PlayerStatus.Rooms);
            if (matchValue_1 == null) {
                room_1 = toFail(printf("Unknown roomId"));
            }
            else {
                const room = matchValue_1;
                switch (room.tag) {
                    case 1: {
                        const playersMap_1 = room.fields[2];
                        const planes_2 = room.fields[1];
                        const pgs_1 = room.fields[0];
                        const date_3 = room.fields[3];
                        room_1 = (new RoomItem(1, [publicGameState, planes_2, playersMap_1, date_3]));
                        break;
                    }
                    case 2: {
                        const pgs_2 = room.fields[0];
                        room_1 = (new RoomItem(2, [publicGameState]));
                        break;
                    }
                    default: {
                        const playersMap = room.fields[2];
                        const planes_1 = room.fields[1];
                        const pgs = room.fields[0];
                        const date_2 = room.fields[3];
                        room_1 = (new RoomItem(0, [publicGameState, planes_1, playersMap, date_2]));
                    }
                }
            }
            let playerStatus_6;
            const inputRecord_5 = state.PlayerStatus;
            playerStatus_6 = (new PlayerStatus_1(inputRecord_5.InMatchmaking, inputRecord_5.ReadyChecks, inputRecord_5.Challenges, add(roomId_5, room_1, state.PlayerStatus.Rooms), inputRecord_5.DailyStatus));
            const state_11 = new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_6, state.VibrationOn, state.SoundOn, state.Leaderboard);
            const queuedMoveResult = bind((player_3) => {
                const playerId = player_3.Id;
                if (!equals(playerId, publicGameState.NextPlayerId)) {
                    return void 0;
                }
                else {
                    const queuedMovesForRoom = defaultArg(tryFind(roomId_5, state_11.QueuedMoves), empty_1());
                    if (isEmpty(queuedMovesForRoom)) {
                        return void 0;
                    }
                    else {
                        const rest = tail(queuedMovesForRoom);
                        const move = head(queuedMovesForRoom);
                        return [move, rest];
                    }
                }
            }, state_11.Player);
            if (queuedMoveResult == null) {
                return [state_11, Cmd_none()];
            }
            else {
                const rest_1 = queuedMoveResult[1];
                const move_1 = queuedMoveResult[0];
                return [new State(state_11.ValidationErrors, state_11.Loaded, state_11.PlayerStatusLoaded, state_11.LatestPingPong, state_11.ApiKey, state_11.Player, state_11.Messages, state_11.HiddenRooms, add(roomId_5, rest_1, state_11.QueuedMoves), state_11.Scores, state_11.Friends, state_11.OnlineCount, state_11.OnlineFriends, state_11.ActiveWaitingRoomCount, state_11.Requests, state_11.MatchmakingStatus, state_11.UiState, state_11.PlayerStatus, state_11.VibrationOn, state_11.SoundOn, state_11.Leaderboard), singleton((dispatch_10) => {
                    dispatch_10(new Msg(30, [roomId_5, move_1]));
                })];
            }
        }
        case 32: {
            log(["Server says: Match ended. Winner", publicGameState_1.Winner]);
            let room_3;
            const matchValue_2 = tryFind(roomId_6, state.PlayerStatus.Rooms);
            if (matchValue_2 == null) {
                room_3 = toFail(printf("Unknown roomId"));
            }
            else {
                const room_2 = matchValue_2;
                switch (room_2.tag) {
                    case 1: {
                        const playersMap_3 = room_2.fields[2];
                        const planes_4 = room_2.fields[1];
                        const pgs_4 = room_2.fields[0];
                        const date_5 = room_2.fields[3];
                        room_3 = (new RoomItem(1, [publicGameState_1, planes_4, playersMap_3, date_5]));
                        break;
                    }
                    case 2: {
                        const pgs_5 = room_2.fields[0];
                        room_3 = (new RoomItem(2, [publicGameState_1]));
                        break;
                    }
                    default: {
                        const playersMap_2 = room_2.fields[2];
                        const planes_3 = room_2.fields[1];
                        const pgs_3 = room_2.fields[0];
                        const date_4 = room_2.fields[3];
                        room_3 = (new RoomItem(0, [publicGameState_1, planes_3, playersMap_2, date_4]));
                    }
                }
            }
            let playerStatus_7;
            const inputRecord_6 = state.PlayerStatus;
            playerStatus_7 = (new PlayerStatus_1(inputRecord_6.InMatchmaking, inputRecord_6.ReadyChecks, inputRecord_6.Challenges, add(roomId_6, room_3, state.PlayerStatus.Rooms), inputRecord_6.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_7, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_batch(singleton(singleton((dispatch_11) => {
                dispatch_11(new Msg(6, []));
            })))];
        }
        case 33: {
            log("Server says: Match cancelled");
            const uiState = equals(state.UiState, new UiState_9(4, [roomId_7])) ? (new UiState_9(0, [])) : state.UiState;
            let playerStatus_8;
            const inputRecord_7 = state.PlayerStatus;
            playerStatus_8 = (new PlayerStatus_1(inputRecord_7.InMatchmaking, inputRecord_7.ReadyChecks, inputRecord_7.Challenges, remove(roomId_7, state.PlayerStatus.Rooms), inputRecord_7.DailyStatus));
            const state_12 = new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, uiState, playerStatus_8, state.VibrationOn, state.SoundOn, state.Leaderboard);
            return [state_12, Cmd_batch(singleton(singleton((dispatch_12) => {
                dispatch_12(new Msg(6, []));
            })))];
        }
        case 34:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        case 35:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, leaderboard), Cmd_none()];
        case 36:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        case 37:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, onlinePlayerCount, onlineFriends, 0, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        case 38:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(7, []))];
        case 39:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(7, []))];
        case 40:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(7, []))];
        case 41: {
            let playerStatus_9;
            const inputRecord_8 = state.PlayerStatus;
            playerStatus_9 = (new PlayerStatus_1(inputRecord_8.InMatchmaking, inputRecord_8.ReadyChecks, add_1(challengeRoom, state.PlayerStatus.Challenges), inputRecord_8.Rooms, inputRecord_8.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_9, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_batch(singleton(Cmd_ofNavigate("/")))];
        }
        case 42:
            return [state, Cmd_batch(empty_1())];
        case 43:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(2, [challenge.Id, empty_1()]), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_batch(singleton(Cmd_ofNavigate("/draw")))];
        case 44:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(2, [challenge_1.Id, planes_5]), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_batch(singleton(Cmd_ofNavigate("/draw")))];
        case 45: {
            let playerStatus_10;
            const inputRecord_9 = state.PlayerStatus;
            playerStatus_10 = (new PlayerStatus_1(inputRecord_9.InMatchmaking, inputRecord_9.ReadyChecks, filter_1((c) => !equals(c.Id, challengeId_1), state.PlayerStatus.Challenges), inputRecord_9.Rooms, inputRecord_9.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_10, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 46: {
            let playerStatus_11;
            const inputRecord_10 = state.PlayerStatus;
            playerStatus_11 = (new PlayerStatus_1(inputRecord_10.InMatchmaking, inputRecord_10.ReadyChecks, filter_1((c_1) => !equals(c_1.Id, challengeId_2), state.PlayerStatus.Challenges), inputRecord_10.Rooms, inputRecord_10.DailyStatus));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, playerStatus_11, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 47: {
            log(["ServerErrorResponse", m]);
            presentErrorToast(m);
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, append(state.Messages, singleton(m)), state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 48: {
            log(["Server response not handled", msg]);
            return [state, Cmd_none()];
        }
        case 49: {
            log(["GotServerError", e.message]);
            return [state, Cmd_none()];
        }
        case 50:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(1, [empty_1()]), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_ofNavigate("/draw")];
        case 51:
            return [state, Cmd_batch(singleton(Cmd_ofSocketMessage(new SocketMsg(14, []))))];
        case 52:
            return [state, Cmd_ofSocketMessage(new SocketMsg(15, [roomId_9]))];
        case 53:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, new UiState_9(0, []), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_batch(ofArray([Cmd_ofNavigate("/"), Cmd_ofSocketMessage(new SocketMsg(12, [roomId_10]))]))];
        case 54: {
            let socketMessage;
            const matchValue_3 = state.UiState;
            switch (matchValue_3.tag) {
                case 3: {
                    const planes_7 = matchValue_3.fields[1];
                    const challengeId_4 = matchValue_3.fields[0];
                    socketMessage = Cmd_ofSocketMessage(new SocketMsg(13, [challengeId_4, planes_7]));
                    break;
                }
                case 1: {
                    const planes_8 = matchValue_3.fields[0];
                    socketMessage = Cmd_ofSocketMessage(new SocketMsg(10, [planes_8]));
                    break;
                }
                case 0:
                case 4: {
                    socketMessage = toFail(printf("Can\'t start game because of invalid ui state"));
                    break;
                }
                default: {
                    const planes_6 = matchValue_3.fields[1];
                    const challengeId_3 = matchValue_3.fields[0];
                    socketMessage = Cmd_ofSocketMessage(new SocketMsg(13, [challengeId_3, planes_6]));
                }
            }
            return [state, Cmd_batch(ofArray([socketMessage, Cmd_ofNavigate("/dashboard")]))];
        }
        case 55:
            return [state, Cmd_ofSocketMessage(new SocketMsg(8, [roomId_11]))];
        case 56:
            return [state, Cmd_ofSocketMessage(new SocketMsg(8, [roomId_12]))];
        case 57:
            return [state, Cmd_ofSocketMessage(new SocketMsg(9, [roomId_13]))];
        case 58:
            return [state, Cmd_ofSocketMessage(new SocketMsg(3, []))];
        case 59:
            if (state.ApiKey == null) {
                return [state, Cmd_ofAsyncGuestServerMessage(new GuestServerMsg(4, [challengeId_5]))];
            }
            else {
                return [state, Cmd_ofSocketMessage(new SocketMsg(4, [challengeId_5]))];
            }
        case 60:
            return [state, Cmd_ofSocketMessage(new SocketMsg(2, [friendshipId]))];
        case 61:
            return [state, Cmd_ofSocketMessage(new SocketMsg(4, [challengeId_6]))];
        case 62:
            return [state, Cmd_ofSocketMessage(new SocketMsg(5, [challengeId_7]))];
        case 63:
            return [state, Cmd_ofSocketMessage(new SocketMsg(6, [challengeId_8]))];
        case 64:
            return [state, Cmd_ofSocketMessage(new SocketMsg(7, [challengeId_9]))];
        case 65: {
            const moves = defaultArg(tryFind(roomId_14, state.QueuedMoves), empty_1());
            const moves_1 = (length(moves) === 10) ? moves : (contains(move_2, moves, {
                Equals: equals,
                GetHashCode: safeHash,
            }) ? filter((m_1) => !equals(m_1, move_2), moves) : append(moves, singleton(move_2)));
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, add(roomId_14, moves_1, state.QueuedMoves), state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, state.UiState, state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        }
        case 66:
            return [state, Cmd_ofSocketMessage(new SocketMsg(16, [roomId_15, move_3]))];
        case 67:
            return [state, Cmd_batch(singleton(Cmd_ofSocketMessage(new SocketMsg(17, [roomId_16]))))];
        case 68:
            return [state, Cmd_batch(singleton(Cmd_ofSocketMessage(new SocketMsg(11, []))))];
        case 69:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, UiState_tryAddPlane(state.UiState, plane), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        case 70:
            return [new State(state.ValidationErrors, state.Loaded, state.PlayerStatusLoaded, state.LatestPingPong, state.ApiKey, state.Player, state.Messages, state.HiddenRooms, state.QueuedMoves, state.Scores, state.Friends, state.OnlineCount, state.OnlineFriends, state.ActiveWaitingRoomCount, state.Requests, state.MatchmakingStatus, UiState_tryRemovePlane(state.UiState, plane_1), state.PlayerStatus, state.VibrationOn, state.SoundOn, state.Leaderboard), Cmd_none()];
        case 71:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(6, [username_2]))];
        case 72:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(8, [friendshipId_1]))];
        default:
            return [state, Cmd_ofAsyncAuthenticatedServerMessage(state.ApiKey, new AuthenticatedServerMsg(9, [friendshipId_2]))];
    }
}

export function ElmishApp() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), void 0, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    SignalR_setDispatch(dispatch);
    const subscribeToTimer = () => {
        const subscriptionId = window.setInterval((_arg_2) => {
            recheckAuth(dispatch);
            ping(dispatch);
            tryReconnect(dispatch);
        }, 1000, [dispatch]);
        return {
            Dispose() {
                window.clearTimeout(subscriptionId);
            },
        };
    };
    useReact_useEffect_7331F961(subscribeToTimer, [dispatch]);
    return createElement(App_1, {
        state: state_1,
        dispatch: dispatch,
    });
}

