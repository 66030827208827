import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from "@ionic/react";
import { Link } from "react-router-dom";
import { useRedirectOnCondition } from "../hooks/useRedirectOnCondition";
import './Login.scss';
import * as Ui from '../fjs/fs/Ui';



function Inner({ registerManager }) {

    return <IonPage>
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonButton size="large" expand="full" onClick={() => {
                            registerManager.registerAnon();

                        }}>
                            Quick play
                        </IonButton>
                        <div style={{"textAlign": "center"}}>
                            Use this to play without an account
                        </div>

                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <Link to="/login">
                            <IonButton size="large" expand="full">Login / Register</IonButton>
                        </Link>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>;
}

function Landing({ state, dispatch }) {
    let registerManager = Ui.getRegisterManager(state, dispatch)

    const redirected = useRedirectOnCondition([
        [(() => state.Player), "/dashboard"],
    ]);
    if (redirected) {
        return <div>redirecting</div>;
    }

    let waitingProps = {
        registerManager
    }
    return <Inner {...waitingProps} />
}


export default Landing