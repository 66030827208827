import { ProgramModule_map, ProgramModule_withSetState, ProgramModule_runWith, ProgramModule_init } from "../Fable.Elmish.4.0.0/program.fs.js";
import { clear, getEnumerator, equals, uncurry2, uncurry3, disposeSafe, isDisposable } from "../fable-library.4.1.4/Util.js";
import { Cmd_none } from "../Fable.Elmish.4.0.0/cmd.fs.js";
import { class_type } from "../fable-library.4.1.4/Reflection.js";
import { useEffect, useState } from "react";
import { useSyncExternalStore } from "use-sync-external-store/shim";

class Util_ElmishState$3 {
    constructor(program, arg, dependencies) {
        this.arg = arg;
        this.dependencies = dependencies;
        const program_1 = program();
        const queuedMessages = [];
        let patternInput_1;
        const patternInput = ProgramModule_init(program_1)(this.arg);
        let model = patternInput[0];
        let cmd = patternInput[1];
        const initialDispatch = (msg) => {
            void (queuedMessages.push(msg));
        };
        const subscribed = false;
        patternInput_1 = [[model, initialDispatch, subscribed, queuedMessages], cmd];
        this.state = patternInput_1[0];
        this.cmd = patternInput_1[1];
        this.subscribe = ((callback) => {
            let dispose = false;
            let needsDispose;
            const model_1 = this.state[0];
            needsDispose = (isDisposable(model_1));
            const mapInit = (_init, _arg) => {
                const cmd$0027 = this.cmd;
                this.cmd = Cmd_none();
                const model_2 = this.state[0];
                return [model_2, cmd$0027];
            };
            const mapTermination = (tupledArg) => {
                const predicate = tupledArg[0];
                const terminate = tupledArg[1];
                return [(msg_1) => (predicate(msg_1) ? true : (needsDispose && dispose)), (model_3) => {
                    const matchValue_1 = model_3;
                    if (isDisposable(matchValue_1)) {
                        const disp = matchValue_1;
                        disposeSafe(disp);
                    }
                    else {
                        terminate(model_3);
                    }
                }];
            };
            const mapUpdate = (update, msg_2, model_4) => {
                const latestModel = this.state[0];
                return update(msg_2)(latestModel);
            };
            ProgramModule_runWith(this.arg, ProgramModule_withSetState((model_5, dispatch) => {
                const oldModel = this.state[0];
                const subscribed_1 = true;
                this.state = [model_5, dispatch, subscribed_1, queuedMessages];
                if (!(model_5 === oldModel)) {
                    callback();
                }
            }, ProgramModule_map(mapInit, mapUpdate, uncurry3((x) => x), uncurry3((x_1) => x_1), uncurry2((x_2) => x_2), mapTermination, program_1)));
            return () => {
                dispose = true;
            };
        });
    }
}

function Util_ElmishState$3_$reflection(gen0, gen1, gen2) {
    return class_type("Feliz.UseElmish.Util.ElmishState`3", [gen0, gen1, gen2], Util_ElmishState$3);
}

function Util_ElmishState$3_$ctor_Z29FF63CF(program, arg, dependencies) {
    return new Util_ElmishState$3(program, arg, dependencies);
}

function Util_ElmishState$3__get_State(_) {
    return _.state;
}

function Util_ElmishState$3__get_Subscribe(_) {
    return _.subscribe;
}

function Util_ElmishState$3__IsOutdated_1954DBC6(_, arg$0027, dependencies$0027) {
    if (!equals(_.arg, arg$0027)) {
        return true;
    }
    else {
        return !equals(_.dependencies, dependencies$0027);
    }
}

export function React_useElmish_Z6C327F2E(program, arg, dependencies) {
    const patternInput = useState(() => Util_ElmishState$3_$ctor_Z29FF63CF(program, arg, dependencies));
    const state = patternInput[0];
    const setState = patternInput[1];
    if (Util_ElmishState$3__IsOutdated_1954DBC6(state, arg, dependencies)) {
        setState(Util_ElmishState$3_$ctor_Z29FF63CF(program, arg, dependencies));
    }
    const patternInput_1 = useSyncExternalStore(Util_ElmishState$3__get_Subscribe(state), () => Util_ElmishState$3__get_State(state));
    const subscribed = patternInput_1[2];
    const queuedMessages = patternInput_1[3];
    const finalState = patternInput_1[0];
    const dispatch = patternInput_1[1];
    useEffect(() => {
        if (subscribed && (queuedMessages.length > 0)) {
            let enumerator = getEnumerator(queuedMessages);
            try {
                while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                    const msg = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                    setTimeout(() => {
                        dispatch(msg);
                    });
                }
            }
            finally {
                disposeSafe(enumerator);
            }
            clear(queuedMessages);
        }
    }, [subscribed, queuedMessages]);
    return [finalState, dispatch];
}

