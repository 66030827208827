import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonPage } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { useEffect, useState } from "react";
import * as Ui from '../fjs/fs/Ui';
import './Login.scss';
import { useParams } from "react-router";
import { Link } from "react-router-dom";


function Inner({
    leaderboardType,
    top100Leaderboard,
    topPlayerPosition,
    friendsLeaderboard,
    friendsPosition,
}) {
    if (leaderboardType === 'global') {
        return <IonPage>
            <IonContent>
                <IonList>
                    <IonListHeader mode="ios">
                        Global top 100
                    </IonListHeader>
                    <IonItem lines="full">
                        <IonLabel>
                            Your place: {topPlayerPosition.RankLabel}
                        </IonLabel>
                    </IonItem>
                    <ol>
                        {top100Leaderboard.map((li) => <li key={li.DisplayName}>
                            {li.DisplayName} (score: {li.Score})
                        </li>)}
                    </ol>
                </IonList>
            </IonContent>
        </IonPage>;
    }

    if (leaderboardType === 'friends') {
        return <IonPage>
            <IonContent>
                <IonList>
                    <IonListHeader mode="ios">
                        Friends
                    </IonListHeader>
                    <IonItem lines="full">
                        <IonLabel>
                            Your place: {friendsPosition.RankLabel}
                        </IonLabel>

                    </IonItem>
                    <ol>
                        {friendsLeaderboard.map((li) => <li key={li.DisplayName}>
                            {li.DisplayName} (score: {li.Score})
                        </li>)}
                    </ol>
                </IonList>
            </IonContent>
        </IonPage>;
    }

    return <IonPage>
        <IonContent>
            <IonList>
                <IonListHeader mode="ios">
                    Global
                </IonListHeader>
                <Link to="/leaderboard/global">
                    <IonItem detail button lines="full">
                        <IonLabel>
                            Your place: {topPlayerPosition.RankLabel}
                        </IonLabel>
                    </IonItem>
                </Link>
            </IonList>
            <IonList>
                <IonListHeader mode="ios">
                    Friends
                </IonListHeader>
                <Link to="/leaderboard/friends">
                    <IonItem detail button lines="full">
                        <IonLabel>
                            Your place: {friendsPosition.RankLabel}
                        </IonLabel>
                    </IonItem>
                </Link>
            </IonList>
        </IonContent>
    </IonPage>;

}

function Leaderboard({ state, dispatch }) {
    const params: any = useParams();

    useEffect(() => {
        if (!state.Leaderboard) {
            Ui.fetchLeaderboard(dispatch);
        }
    }, [state.Leaderboard]);

    if (!state.Leaderboard) {
        return <div>loading...</div>
    }

    let props = {
        leaderboardType: params.leaderboardType,
        top100Leaderboard: Ui.getTop100Leaderboard(state),
        friendsLeaderboard: Ui.getFriendsLeaderboard(state),
        topPlayerPosition: Ui.getTopPlayersPosition(state),
        friendsPosition: Ui.getFriendsPosition(state),
    }

    return <Inner {...props} />
}

export default Leaderboard