import { IonApp, IonBackdrop, setupIonicReact } from '@ionic/react';

import '@ionic/react/css/core.css';

import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import './theme/variables.css';
import './App.scss'

import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Route, Switch, useHistory
} from "react-router-dom";
import * as Ui from './fjs/fs/Ui';
import * as Remote from './fjs/fs/Remote';

import ChallengeCountdown from './ChallengeCountdown';
import Challenge from './pages/Challenge';
import Dashboard from './pages/Dashboard';
import Draw from './pages/Draw';
import Friends from './pages/Friends';
import Login from './pages/Login';
import Play from './pages/Play';
import Register from './pages/Register';
import Waiting from './pages/Waiting';
import Landing from './pages/Landing';
import LinkChallenge from './pages/LinkChallenge';
import Leaderboard from './pages/Leaderboard';
import Icon from './pages/Icon';
import History from './pages/History';


setupIonicReact();


function ModalController({ components, onDismiss }) {
  if (!components.length) {
    return <></>
  }
  return <>
    <IonBackdrop tappable={true} style={{ "opacity": 0.5 }} onIonBackdropTap={() => {
      if (onDismiss) {
        onDismiss()
      }
    }} />
    <div style={{ "zIndex": 2, "position": "fixed", "bottom": 0, "width": "100%" }}>
      {components}
    </div>
  </>
}

function OutletWrapper({ children, state }) {
  let history = useHistory();

  Ui.setRouterPushFn((path) => { 
    if (history.location.pathname == path) {
      //do nothing to prevent duplicate history entries
      return
    }

    history.push(path) 
  })



  return children

}

function App({ state, dispatch }) {

  const [isOffline, setIsOffline] = useState<any>(false);
  
  useEffect(() => {
    let newIsOffline = Remote.isConnectionBad();

    if (isOffline != newIsOffline) {
      setIsOffline(newIsOffline);
    }
  }, [state.LatestPingPong]);

  if (!state.Loaded) {
    return <span>loading</span>
  }

  let newChallenges = []
  
  return <IonApp className={isOffline ? "offline" : ""}>
    <Router>
      <OutletWrapper state={state}>
        <Switch>
          <Route exact path="/login">
            <Login state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/register">
            <Register state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/friends">
            <Friends state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/leaderboard/:leaderboardType?">
            <Leaderboard state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/challenge/:friendshipId">
            <Challenge state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/waiting">
            <Waiting state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/play/:roomId">
            <Play state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/draw">
            <Draw state={state} dispatch={dispatch} />
          </Route> 
          <Route exact path="/icon">
            <Icon state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/history">
            <History state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard state={state} dispatch={dispatch} />
          </Route> 
          <Route exact path="/link/:linkChallengeId">
            <LinkChallenge state={state} dispatch={dispatch} />
          </Route>
          <Route exact path="/">
            <Landing state={state} dispatch={dispatch} />
          </Route>
        </Switch>
      </OutletWrapper>
    </Router>
    {state.Player && <ModalController
      onDismiss={() => {
      }}
      components={newChallenges} />}
    <div className="offline-indicator">
      no connection to server
    </div>
  </IonApp >
};

export default App;
