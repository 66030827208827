import { IonText } from "@ionic/react"
import './PlayerRow.scss'
function PlayerRow({ player }) {
    return <div className='player-row'>
        <div className='player-item'>
            <div className={"player " + player.Icon}>

            </div>
            <div className='details'>
                <div className="opponent">
                    <IonText className='player-name'>{player.Name}</IonText>
                </div>
                <div className="awards">
                    {/* <div className="award-1"></div>
                    <div className="award-1"></div>
                    <div className="award-1"></div>
                    <div className="award-1"></div> */}
                </div>
            </div>
        </div>
        {/* <div className='player-history'>
            <div className="opponent">💀✈️✈️ + ⚪</div>
            <div className="you">⚪ + ✈️💀💀 </div>
        </div> */}
    </div>
}

export default PlayerRow