import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonPage } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { useState } from "react";
import * as Ui from '../fjs/fs/Ui';
import './Icon.scss';


function Inner({ 
    icons, 
    selectIcon,
    selectedIcon,
}) {
    return <IonPage>
        <IonContent>
            <IonList>
                <IonListHeader mode="ios">
                    Icons
                </IonListHeader>
                {icons.map((icon) => <div key={icon}  className={ "icon-item " + (selectedIcon === icon ? "selected" : "")} 
                    onClick={() => selectIcon(icon)}
                >
                    <div className={"player " + icon}>

                    </div>
                    <h4>{icon}</h4>
                </div>)}

            </IonList>
        </IonContent>
    </IonPage>;
}

function Icon({ state, dispatch}) {

    let friendsProps = {
        icons: Ui.getAvailableIcons(state),
        selectedIcon: Ui.getSelectedIcon(state),
        selectIcon: (icon) => Ui.selectIcon(dispatch, icon),
    }

    return <Inner {...friendsProps} />
}

export default Icon