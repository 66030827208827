import { tryFind, tryLast, item, length, isEmpty, singleton, contains as contains_1, append, filter, head, map, tail, collect, ofArray } from "../fable_modules/fable-library.4.1.4/List.js";
import { PublicGameState, MoveResult, PlayingGameState, Move, PlayerId_$reflection, Plane, PlaneDirection } from "./Types.js";
import { List_countBy } from "../fable_modules/fable-library.4.1.4/Seq2.js";
import { safeHash, compare, equals, arrayHash, equalArrays } from "../fable_modules/fable-library.4.1.4/Util.js";
import { empty, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.1.4/Choice.js";
import { nonSeeded } from "../fable_modules/fable-library.4.1.4/Random.js";
import { Union } from "../fable_modules/fable-library.4.1.4/Types.js";
import { union_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { FSharpMap__get_Item, empty as empty_1, add } from "../fable_modules/fable-library.4.1.4/Map.js";
import { map as map_1 } from "../fable_modules/fable-library.4.1.4/Option.js";
import { maxValue } from "../fable_modules/fable-library.4.1.4/Date.js";

export function north(x, y) {
    return ofArray([[x, y], [x - 2, y + 1], [x - 1, y + 1], [x, y + 1], [x + 2, y + 1], [x + 1, y + 1], [x, y + 2], [x - 1, y + 3], [x, y + 3], [x + 1, y + 3]]);
}

export function south(x, y) {
    return ofArray([[x, y], [x - 2, y - 1], [x - 1, y - 1], [x, y - 1], [x + 2, y - 1], [x + 1, y - 1], [x, y - 2], [x - 1, y - 3], [x, y - 3], [x + 1, y - 3]]);
}

export function east(x, y) {
    return ofArray([[x, y], [x - 1, y - 2], [x - 1, y - 1], [x - 1, y], [x - 1, y + 2], [x - 1, y + 1], [x - 2, y], [x - 3, y - 1], [x - 3, y], [x - 3, y + 1]]);
}

export function west(x, y) {
    return ofArray([[x, y], [x + 1, y - 2], [x + 1, y - 1], [x + 1, y], [x + 1, y + 2], [x + 1, y + 1], [x + 2, y], [x + 3, y - 1], [x + 3, y], [x + 3, y + 1]]);
}

export function rotateCoordinate(x, y) {
    return [(10 - y) - 1, x];
}

export function mirrorCoordinateOnX(x, y) {
    return [(10 - x) - 1, y];
}

export function mirrorCoordinateOnY(x, y) {
    return [x, (10 - y) - 1];
}

export function rotateDirection(dir) {
    switch (dir.tag) {
        case 3:
            return new PlaneDirection(1, []);
        case 1:
            return new PlaneDirection(2, []);
        case 2:
            return new PlaneDirection(0, []);
        default:
            return new PlaneDirection(3, []);
    }
}

export function mirrorDirectionOnX(dir) {
    switch (dir.tag) {
        case 3:
            return new PlaneDirection(2, []);
        case 1:
            return new PlaneDirection(1, []);
        case 2:
            return new PlaneDirection(3, []);
        default:
            return new PlaneDirection(0, []);
    }
}

export function mirrorDirectionOnY(dir) {
    switch (dir.tag) {
        case 3:
            return new PlaneDirection(3, []);
        case 1:
            return new PlaneDirection(0, []);
        case 2:
            return new PlaneDirection(2, []);
        default:
            return new PlaneDirection(1, []);
    }
}

export function rotatePlane(plane) {
    const c = rotateCoordinate(plane.X, plane.Y);
    const dir = rotateDirection(plane.Direction);
    return new Plane(c[0], c[1], dir);
}

export function mirrorPlaneOnX(plane) {
    const c = mirrorCoordinateOnX(plane.X, plane.Y);
    const dir = mirrorDirectionOnX(plane.Direction);
    return new Plane(c[0], c[1], dir);
}

export function mirrorPlaneOnY(plane) {
    const c = mirrorCoordinateOnY(plane.X, plane.Y);
    const dir = mirrorDirectionOnY(plane.Direction);
    return new Plane(c[0], c[1], dir);
}

export function getPartsForPlane(plane) {
    const y = plane.Y | 0;
    const x = plane.X | 0;
    const matchValue = plane.Direction;
    switch (matchValue.tag) {
        case 1:
            return south(x, y);
        case 2:
            return west(x, y);
        case 3:
            return east(x, y);
        default:
            return north(x, y);
    }
}

export function getPlaneParts(planes) {
    return collect((plane) => tail(getPartsForPlane(plane)), planes);
}

export function getPlaneHeads(planes) {
    return map((plane) => head(getPartsForPlane(plane)), planes);
}

export function getOverlaps(planeParts, planeHeads) {
    return map((tuple) => tuple[0], filter((tupledArg) => {
        const count = tupledArg[1] | 0;
        return count > 1;
    }, List_countBy((x) => x, append(planeParts, planeHeads), {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    })));
}

export function getOutside(planeParts, planeHeads) {
    return filter((tupledArg) => {
        const x = tupledArg[0] | 0;
        const y = tupledArg[1] | 0;
        if (((x < 0) ? true : (x > 9)) ? true : (y < 0)) {
            return true;
        }
        else {
            return y > 9;
        }
    }, append(planeParts, planeHeads));
}

export function makePlane(x, y, direction) {
    return new Plane(x, y, direction);
}

export function drawPlane(x, y, direction) {
    const plane = makePlane(x, y, direction);
    return getPartsForPlane(plane);
}

export function contains(x, y, points) {
    return contains_1([x, y], points, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    });
}

export function getPlaneAt(planes, x, y) {
    return head(filter((plane) => {
        const parts = getPlaneParts(singleton(plane));
        const heads = getPlaneHeads(singleton(plane));
        return contains_1([x, y], append(parts, heads), {
            Equals: equalArrays,
            GetHashCode: arrayHash,
        });
    }, planes));
}

export function validatePlanePlacement(planes) {
    const parts = getPlaneParts(planes);
    const heads = getPlaneHeads(planes);
    const overlaps = getOverlaps(parts, heads);
    const outside = getOutside(parts, heads);
    const issues = toList(delay(() => append_1(!isEmpty(overlaps) ? singleton_1("Planes are overlapping") : empty(), delay(() => (!isEmpty(outside) ? singleton_1("Planes are outside play area") : empty())))));
    if (isEmpty(issues)) {
        return new FSharpResult$2(0, [planes]);
    }
    else {
        return new FSharpResult$2(1, [issues]);
    }
}

export function validatePlaneCount(planes) {
    const issues = toList(delay(() => ((length(planes) !== 3) ? singleton_1("3 planes are required to play the game") : empty())));
    if (isEmpty(issues)) {
        return new FSharpResult$2(0, [planes]);
    }
    else {
        return new FSharpResult$2(1, [issues]);
    }
}

export function getRandomInList(lst) {
    const rnd = nonSeeded();
    return item(rnd.Next1(length(lst)), lst);
}

export class FirstPlayerPickingStrategy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Random", "Exact"];
    }
}

export function FirstPlayerPickingStrategy_$reflection() {
    return union_type("Shared.Domain.FirstPlayerPickingStrategy", [], FirstPlayerPickingStrategy, () => [[], [["Item", PlayerId_$reflection()]]]);
}

export function canPlayerAddMove(gameState, playerId) {
    const matchValue = tryLast(gameState.Moves);
    if (matchValue == null) {
        return equals(playerId, gameState.FirstPlayer);
    }
    else {
        const pId = matchValue[0];
        return !equals(pId, playerId);
    }
}

export function makeVersusMove(gameState, playerId, move) {
    const isDuplicateMove = contains_1([playerId, move], gameState.Moves, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    });
    if ((canPlayerAddMove(gameState, playerId) ? true : equals(move, new Move(1, []))) ? true : equals(move, new Move(2, []))) {
        if (!isDuplicateMove) {
            return new FSharpResult$2(0, [new PlayingGameState(gameState.PlayerA, gameState.PlayerB, append(gameState.Moves, singleton([playerId, move])), gameState.FirstPlayer)]);
        }
        else {
            return new FSharpResult$2(1, ["Can\'t make same move twice"]);
        }
    }
    else {
        return new FSharpResult$2(1, ["Player can\'t add move because it\'s out of turn"]);
    }
}

export function makeSinglePlayerMove(gameState, playerId, move) {
    const isDuplicateMove = contains_1([playerId, move], gameState.Moves, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    });
    if (!isDuplicateMove) {
        return new FSharpResult$2(0, [new PlayingGameState(gameState.PlayerA, gameState.PlayerB, append(gameState.Moves, singleton([playerId, move])), gameState.FirstPlayer)]);
    }
    else {
        return new FSharpResult$2(1, ["Can\'t make same move twice"]);
    }
}

export function abandonGame(gameState, playerId) {
    return new PlayingGameState(gameState.PlayerA, gameState.PlayerB, append(gameState.Moves, singleton([playerId, new Move(1, [])])), gameState.FirstPlayer);
}

export function isValidMove(gameState, playerId, move) {
    return !contains_1([playerId, move], gameState.Moves, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    });
}

export function processGameState(gameState) {
    let playerId_3, playerId_4, playerId_5, playerId_6, playerId_7, playerId_8;
    const patternInput = gameState.PlayerA;
    const playerAPlanes = patternInput[1];
    const playerAId = patternInput[0];
    const patternInput_1 = gameState.PlayerB;
    const playerBPlanes = patternInput_1[1];
    const playerBId = patternInput_1[0];
    const playerAPlaneParts = getPlaneParts(playerAPlanes);
    const playerAPlaneHeads = getPlaneHeads(playerAPlanes);
    const playerBPlaneParts = getPlaneParts(playerBPlanes);
    const playerBPlaneHeads = getPlaneHeads(playerBPlanes);
    const targetMap = add(playerBId, [playerAPlaneParts, playerAPlaneHeads], add(playerAId, [playerBPlaneParts, playerBPlaneHeads], empty_1({
        Compare: compare,
    })));
    const moveResults = map((tupledArg) => {
        const playerId = tupledArg[0];
        const move = tupledArg[1];
        switch (move.tag) {
            case 1:
                return [playerId, new MoveResult(3, [])];
            case 2:
                return [playerId, new MoveResult(4, [])];
            default: {
                const y_1 = move.fields[1] | 0;
                const x_1 = move.fields[0] | 0;
                const patternInput_2 = FSharpMap__get_Item(targetMap, playerId);
                const partsTargets = patternInput_2[0];
                const headsTargets = patternInput_2[1];
                if (contains_1([x_1, y_1], partsTargets, {
                    Equals: equalArrays,
                    GetHashCode: arrayHash,
                })) {
                    return [playerId, new MoveResult(1, [[x_1, y_1]])];
                }
                else if (contains_1([x_1, y_1], headsTargets, {
                    Equals: equalArrays,
                    GetHashCode: arrayHash,
                })) {
                    return [playerId, new MoveResult(2, [[x_1, y_1]])];
                }
                else {
                    return [playerId, new MoveResult(0, [[x_1, y_1]])];
                }
            }
        }
    }, gameState.Moves);
    const nextTurnPlayerId = canPlayerAddMove(gameState, playerAId) ? playerAId : playerBId;
    const kills = List_countBy((tupledArg_2) => {
        const playerId_1 = tupledArg_2[0];
        return playerId_1;
    }, filter((tupledArg_1) => {
        const r = tupledArg_1[1];
        if (r.tag === 2) {
            return true;
        }
        else {
            return false;
        }
    }, moveResults), {
        Equals: equals,
        GetHashCode: safeHash,
    });
    const winner = map_1((tuple) => tuple[0], tryFind((tupledArg_3) => {
        const playerId_2 = tupledArg_3[0];
        const kills_1 = tupledArg_3[1] | 0;
        return kills_1 === 3;
    }, kills));
    let winner_1;
    if (winner == null) {
        const abandon = map_1((tuple_1) => tuple_1[0], tryFind((tupledArg_4) => {
            const r_1 = tupledArg_4[1];
            if (r_1.tag === 3) {
                return true;
            }
            else {
                return false;
            }
        }, moveResults));
        winner_1 = ((abandon == null) ? void 0 : (((playerId_3 = abandon, equals(playerId_3, playerAId))) ? ((playerId_4 = abandon, playerBId)) : ((playerId_5 = abandon, playerAId))));
    }
    else {
        winner_1 = winner;
    }
    let winner_2;
    if (winner_1 == null) {
        const timeout = map_1((tuple_2) => tuple_2[0], tryFind((tupledArg_5) => {
            const r_2 = tupledArg_5[1];
            if (r_2.tag === 4) {
                return true;
            }
            else {
                return false;
            }
        }, moveResults));
        winner_2 = ((timeout == null) ? void 0 : (((playerId_6 = timeout, equals(playerId_6, playerAId))) ? ((playerId_7 = timeout, playerBId)) : ((playerId_8 = timeout, playerAId))));
    }
    else {
        winner_2 = winner_1;
    }
    return new PublicGameState(moveResults, nextTurnPlayerId, winner_2, maxValue());
}

export function getWinner(gameState) {
    const publicGameState = processGameState(gameState);
    return publicGameState.Winner;
}

export function getLoserScore(gameState) {
    const publicGameState = processGameState(gameState);
    return length(filter((tupledArg) => {
        const p = tupledArg[0];
        const res = tupledArg[1];
        if (!equals(p, publicGameState.Winner)) {
            if (res.tag === 2) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }, publicGameState.MoveResults)) | 0;
}

