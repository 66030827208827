import React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ElmishApp } from './fjs/fs/App';
import { createRoot } from 'react-dom/client';

import {NativeAudio} from '@capacitor-community/native-audio'

Promise.all([
    NativeAudio.preload({
        assetId: "dead",
        assetPath: "dead.wav",
        audioChannelNum: 1,
        isUrl: false,
        volume: 0.3,
    }),
    NativeAudio.preload({
        assetId: "air",
        assetPath: "air.wav",
        audioChannelNum: 1,
        isUrl: false,
        volume: 0.8,
    }),
    NativeAudio.preload({
        assetId: "hit",
        assetPath: "hit.wav",
        audioChannelNum: 1,
        isUrl: false,
        volume: 0.8,
    }),
    NativeAudio.preload({
        assetId: "won",
        assetPath: "won.wav",
        audioChannelNum: 1,
        isUrl: false,
        volume: 0.1,
    }),
    NativeAudio.preload({
        assetId: "lost",
        assetPath: "lost.wav",
        audioChannelNum: 1,
        isUrl: false,
        volume: 0.1,
    }),

]).then(r => console.log("sounds loaded"));

const container = document.getElementById('root');
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ElmishApp />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
