import {Haptics, ImpactStyle, NotificationType} from '@capacitor/haptics';
import {IonButton, IonContent, IonIcon, IonPage, IonText, useIonAlert, useIonToast} from "@ionic/react";
import {useEffect, useState} from "react";
import * as Ui from '../fjs/fs/Ui';
import {useRedirectOnCondition} from '../hooks/useRedirectOnCondition';
import './Play.scss';
import PlayerRow from './PlayerRow';
import {Link, useParams} from 'react-router-dom';
import {RoomId} from "../fjs/Shared/Types";
import {NativeAudio} from '@capacitor-community/native-audio'
import {barcode, musicalNotes} from 'ionicons/icons';
import SwitchBar from "../SwitchBar";

async function playSound(assetId) {
    let isPlaying = await NativeAudio.isPlaying({assetId});

    if (isPlaying) {
        console.log("assetId", assetId, "is playing. Stopping.")
        await NativeAudio.stop({assetId});
    }
    console.log("start playing", assetId)
    try {
        await NativeAudio.play({assetId});
        
    } catch (e) {
        console.error(e)
    }
}

let drawn: { [k: string]: string } = {};
let drawFn = (id) => {
    console.log("drawFn not set")
}

let clearAllColors = () => {
    document.querySelectorAll(".item").forEach(el => el.classList.remove("red", "green", "orange"))
}
let redrawColors = () => {
    clearAllColors()
    Object.keys(drawn).forEach(key => {
        if (drawn[key]) {
            document.querySelector(`[data-id="${key}"]`)?.classList.add(drawn[key])
        }
    })
}

type Move = {
    myMove: any;
    type: string | number[];
    x: string | number;
    y: string | number;
} | undefined
const FeedbackFactory = (showMessageFn, soundOn, vibrationOn) => {
    if (!showMessageFn) {
        showMessageFn = () => {
            console.log('No showMessageFn provided')
        }
    }

    const xMap = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    const yMap = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

    let noop = async () => {
        return;
    }

    return {
        makeMove: (move: Move) => {
            console.log('makeMove');

            (async () => {
                if (vibrationOn) {
                    await Haptics.impact({style: ImpactStyle.Light});
                }
            })();
        },
        air: (move: Move) => {
            console.log('air');

            showMessageFn(`${move?.myMove ? 'You' : 'Opponent'} missed at ${xMap[move!.x]}${yMap[move!.y]}`);

            (async () => {
                await Promise.all([
                    soundOn ? playSound("air") : noop(),
                    vibrationOn ? Haptics.impact({style: ImpactStyle.Light}) : noop(),
                ]);
            })();
        },
        hit: (move: Move) => {
            console.log('hit');

            showMessageFn(`${move?.myMove ? 'You' : 'Opponent'} hit at ${xMap[move!.x]}${yMap[move!.y]}`);

            (async () => {
                await Promise.all([
                    soundOn ? playSound("hit") : noop(),
                    vibrationOn ? Haptics.notification({type: NotificationType.Success}) : noop(),
                ]);
            })();
        },
        dead: (move: Move) => {
            console.log('dead');

            showMessageFn(`${move?.myMove ? 'You' : 'Opponent'} killed airplane at ${xMap[move!.x]}${yMap[move!.y]}`);

            (async () => {
                await Promise.all([
                    soundOn ? playSound("dead") : noop(),
                    vibrationOn ? Haptics.notification({type: NotificationType.Warning}) : noop(),
                ]);
            })();
        },
        gameStart: () => {
            console.log('gameStart');

            showMessageFn(`Game started!`);

            (async () => {
                if (vibrationOn) {
                    await Haptics.impact({style: ImpactStyle.Heavy});
                    setTimeout(async () => {
                        await Haptics.impact({style: ImpactStyle.Heavy});
                    }, 300)
                }
            })();
        },
        gameEnd: (move: Move, playerHasWon: boolean) => {
            console.log('gameEnd');

            // showMessageFn(`${move?.myMove ? 'You' : 'Opponent'} won the game!`);

            (async () => {
                let promises: any[] = [];

                if (playerHasWon) {
                    promises.push(soundOn ? playSound("won") : noop())
                } else {
                    promises.push(soundOn ? playSound("lost") : noop());
                }
                promises.push(vibrationOn ? Haptics.impact({style: ImpactStyle.Heavy}) : noop());

                await Promise.all(promises);

                setTimeout(async () => {
                    await (vibrationOn ? Haptics.impact({style: ImpactStyle.Heavy}) : noop());
                }, 300)
            })();
        },
    }
};

function Inner(
    {
        makeMoveFn,
        playAgainFn,
        rematchFn,
        roomId,
        publicGameState,
        player,
        planes,
        currentScore,
        completedScore,
        playNextDaily,
        isDailyGame,
        opponentPlayer,
        toggleSound,
        toggleVibration,
        soundOn,
        vibrationOn,
        switchBar,
        queuedMoves,
    }) {

    let myPlaneParts = Ui.getPlaneParts(planes);
    let myPlaneHeads = Ui.getPlaneHeads(planes);
    let myPlaneDirections = Ui.getPlaneDirections(planes);
    let myMoves = Ui.getMovesForMyself(player, publicGameState);
    let myStatus = Ui.getMyStatus(player, publicGameState, "💀", "✈️");
    let otherMoves = Ui.getMovesForOther(player, publicGameState);
    let otherStatus = Ui.getOtherStatus(player, publicGameState, "💀", "✈️");
    let myTurn = Ui.isItMyTurn(player, publicGameState);
    let gameOver = Ui.isThereAWinner(publicGameState);
    let iWin = Ui.amITheWinner(player, publicGameState);
    let lastMoveType = Ui.getLastMoveType(publicGameState);
    let lastMove = Ui.getLastMoveForUi(player, publicGameState);
    let movesCount = Ui.getMovesCount(publicGameState);


    return <PlayReady
        opponentPlayer={opponentPlayer}
        makeMoveFn={makeMoveFn}
        playAgainFn={playAgainFn}
        rematchFn={rematchFn}
        roomId={roomId}
        publicGameState={publicGameState}
        myPlaneParts={myPlaneParts}
        myPlaneHeads={myPlaneHeads}
        myPlaneDirections={myPlaneDirections}
        myMoves={myMoves}
        myStatus={myStatus}
        otherMoves={otherMoves}
        otherStatus={otherStatus}
        myTurn={myTurn}
        gameOver={gameOver}
        iWin={iWin}
        lastMoveType={lastMoveType}
        lastMove={lastMove}
        movesCount={movesCount}
        currentScore={currentScore}
        completedScore={completedScore}
        playNextDaily={playNextDaily}
        isDailyGame={isDailyGame}
        toggleSound={toggleSound}
        toggleVibration={toggleVibration}
        vibrationOn={vibrationOn}
        soundOn={soundOn}
        switchBar={switchBar}
        queuedMoves={queuedMoves}
    />
}

function Play({state, dispatch}) {
    const params: any = useParams();
    const roomId = new RoomId(params.roomId)

    useEffect(() => {
    }, []);
    const redirected = useRedirectOnCondition([
        [(() => !Ui.canBeOnPlayPage(state, roomId)), "/"],
    ]);

    if (redirected) {
        return <div>redirecting</div>;
    }

    let publicGameState = Ui.getPublicGameStateForPlayerInRoom(state, roomId);

    if (!publicGameState) {
        return <div>loading game</div>
    }
    
    let switchBar = <SwitchBar state={state} dispatch={dispatch} currentRoomId={roomId} />
    
    let playProps = {
        makeMoveFn: ((x, y) => {
            if (Ui.isItMyTurn(state.Player, publicGameState))
            {
                Ui.makeMove(dispatch, roomId, x, y);
            } else {
                Ui.queueMove(dispatch, roomId, x, y); 
            }
        }),
        playAgainFn: (() => Ui.playAgain(dispatch)),
        rematchFn: (roomId => Ui.rematch(dispatch, roomId)),
        roomId: roomId,
        publicGameState: publicGameState,
        player: state.Player,
        planes: Ui.getPlanesForPlayerInRoom(state, roomId),
        currentScore: Ui.getDailyCurrentScore(state, roomId),
        completedScore: Ui.getDailyCompletedScore(state),
        playNextDaily: Ui.getDailyState(state) !== "finished" ? () => {
            Ui.playDaily(dispatch)
        } : null,
        isDailyGame: Ui.isDailyGameRoom(state, roomId),
        soundOn: state.SoundOn,
        vibrationOn: state.VibrationOn,
        opponentPlayer: Ui.tryGetOpponentPlayerForRoom(state, roomId),
        toggleVibration: () => {
            Ui.toggleVibration(dispatch);
        },
        toggleSound: () => {
            Ui.toggleSound(dispatch);
        },
        switchBar: switchBar,
        queuedMoves: Ui.getQueuedMoves(state, roomId)
    }
    return <Inner {...playProps} />
}

function VersusScore({myStatus, otherStatus, gameOver, playAgainFn, rematchFn, iWin}) {
    if (!gameOver) {
        return <></>
    }
    return <h4 style={{textAlign: "center"}}>
        {(iWin ? "You win!" : "Opponent wins!")} <br/>

        <IonButton onClick={() => {
            playAgainFn()
        }}>Matchmake</IonButton>

        <IonButton onClick={() => {
            rematchFn();
        }}>Rematch</IonButton>
    </h4>
}

function DailyScore({gameOver, playNextDaily, currentScore, currentMoves, completedScore}) {
    let cScore = Math.max(currentScore, currentMoves)
    return <>
        {<h4 style={{textAlign: "center"}}>{`${cScore} moves, ${currentScore + completedScore} total.`}</h4>}
        {gameOver && !!playNextDaily && <h4 style={{textAlign: "center"}}>
            <IonButton onClick={() => {
                playNextDaily()
            }}>Play next</IonButton>
        </h4>
        }
        {gameOver && !playNextDaily && <h4 style={{textAlign: "center"}}>
            Done for today. Thank you for playing! <br/>
            Check the <Link to="/leaderboard">leaderboard</Link> to see your rank.
        </h4>
        }
    </>
}

function Timer({timeout}) {
    const [elapsed, setElapsed] = useState(0);
    useEffect(() => {
        let t = setInterval(() => {
            setElapsed((v) => v + 1)
        }, 500)

        return () => {
            clearInterval(t)
        }
    }, [timeout])

    if (!timeout) {
        return <span></span>
    }

    return <h4 style={{textAlign: "center"}}>{Ui.toTimeRemainingString(timeout)}</h4>

}

function PlayReady(
    {
        makeMoveFn,
        playAgainFn,
        rematchFn,
        roomId,
        publicGameState,
        myPlaneParts,
        myPlaneHeads,
        myPlaneDirections,
        myMoves,
        myStatus,
        otherMoves,
        otherStatus,
        myTurn,
        gameOver,
        iWin,
        lastMoveType,
        lastMove,
        movesCount,
        currentScore,
        completedScore,
        playNextDaily,
        isDailyGame,
        opponentPlayer,
        toggleVibration,
        toggleSound,
        soundOn,
        vibrationOn,
        switchBar,
        queuedMoves,
    }) {
    const [presentToast] = useIonToast()
    const [presentAlert] = useIonAlert();

    Ui.initToastPresenter(presentToast);

    const showToast = (text) => {
        presentToast({message: text, duration: 1000, position: 'bottom'});
    }

    const [drawing, setDrawing] = useState(false);
    const [drawingColor, setDrawingColor] = useState<string | null>(null);

    useEffect(() => {
        if (drawing) {
            listenForEvents();
        } else {
            unlistenForEvents();
        }
    }, [drawing])

    redrawColors();

    const startDrawing = (color) => {
        setDrawing(true);
        setDrawingColor(color)
    }

    const stopDrawing = () => {
        setDrawing(false);
        setDrawingColor(null)
    }

    const clearCurrent = () => {
        drawn = Object.fromEntries(Object.entries(drawn).filter(([id, color]) => color != drawingColor))
        redrawColors()

    }

    const clearAll = () => {
        drawn = {}
        redrawColors()
    }

    const Feedback = FeedbackFactory(showToast, soundOn, vibrationOn);

    drawFn = (id) => {
        if (!drawing || !drawingColor) {
            console.log("not drawing")
            return;
        }

        if (drawn[id] && drawn[id] == drawingColor) {
            drawn[id] = "";
        } else {
            drawn[id] = drawingColor;
        }
        redrawColors()
    }


    let makeMove = (x, y) => {
        if (drawing) {
            return false;
        }
        if (gameOver) {
            return false;
        }
        Feedback.makeMove({x, y, myMove: true, type: ""})
        makeMoveFn(x, y)
    }

    let items: any[] = [];

    useEffect(() => {
        if (movesCount === 0) {
            Feedback.gameStart();
            stopDrawing()
            clearAll()
            clearAllColors()
        }
    }, [movesCount])

    useEffect(() => {
        if (!publicGameState) {
            console.log('Not started');
            return;
        }
        if (lastMoveType) {
            if (lastMoveType === "Hit") {
                Feedback.hit(lastMove)
            }
            if (lastMoveType === "Air") {
                Feedback.air(lastMove);
            }
            if (lastMoveType === "Dead") {
                Feedback.dead(lastMove);
            }
        }

        if (gameOver) {
            Feedback.gameEnd(lastMove, iWin);

            if (iWin) {
                presentAlert({
                    header: 'You win!',
                    message: lastMove?.type === "Abandon" ? "Opponent abandoned the game" : "",
                    buttons: ['OK'],
                })
            } else {
                presentAlert({
                    header: 'Opponent wins!',
                    message: lastMove?.type === "Abandon" ? "You abandoned the game" : "",
                    buttons: ['OK'],
                })
            }
        }

    }, [gameOver, lastMoveType, movesCount]);

    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((row, y) => {
        ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'].forEach((col, x) => {
            items.push({
                row,
                col,
                y,
                x
            })
        })
    })

    let isLatestPlayerMove = (x, y) => {
        return lastMove?.x == x && lastMove?.y == y && lastMove.myMove;
    }
    let isLatestOpponentMove = (x, y) => {
        return lastMove?.x == x && lastMove?.y == y && !lastMove.myMove;
    }

    let planeDirection = (x, y, planeDirections) => {
        return Ui.tryGetDirection(x, y, planeDirections) ?? ""
    }

    let isHit = (hits, x, y) => {
        return Ui.contains(x, y, hits)
    }

    let isAir = (airs, x, y) => {
        return Ui.contains(x, y, airs);
    }

    let isDead = (kills, x, y) => {
        return Ui.contains(x, y, kills);
    }
    let queuedMoveClassName = (queuedMoves, x, y) => {
        for (let i = 0; i < queuedMoves.length; i++) {
            let qm = queuedMoves[i];
            
            if (qm.x == x && qm.y == y) {
                return " queued queued-" + (i+1) + " ";
            }
            
        }
        return " ";
    }

    let isPlane = (x, y, planeParts) => {
        return Ui.contains(x, y, planeParts)
    }

    let isHead = (x, y, planeHeads) => {
        return Ui.contains(x, y, planeHeads)
    }

    let drawnColor = (x, y) => {
        let res = drawn[`${x}-${y}`] || "";
    }

    let isOdd = (x, y) => {
        if (y % 2 == 0) {
            return (x % 2 == 0)
        }
        return (x % 2 == 1)
    }

    let currentMoves = (Ui.toArray(myMoves.kills).length + Ui.toArray(myMoves.airs).length + Ui.toArray(myMoves.hits).length)

    return <IonPage>
        <IonContent className={"play-page " + (gameOver ? "game-over" : (myTurn ? "my-turn " : "other-turn "))}>
            {switchBar}

            {!isDailyGame && <VersusScore
                rematchFn={() => {
                    rematchFn(roomId)
                }}
                gameOver={gameOver}
                iWin={iWin}
                myStatus={myStatus}
                otherStatus={otherStatus}
                playAgainFn={playAgainFn}
            />}

            {isDailyGame && <DailyScore
                gameOver={gameOver}
                currentScore={currentScore}
                currentMoves={currentMoves}
                playNextDaily={playNextDaily}
                completedScore={completedScore}
            />}


            {!isDailyGame && opponentPlayer && <PlayerRow player={opponentPlayer}/>}
            {!isDailyGame && <Timer
                timeout={publicGameState.MoveTimeout}
            />}

            <div className={"grid avioane main play toucharea"}>
                {items.map((item) => {
                    return <div
                        data-id={`${item.x}-${item.y}`}
                        onClick={() => {
                            makeMove(item.x, item.y)
                        }}
                        className={
                            "item " +
                            // (drawnColor(item.x, item.y) + " ") +
                            (isOdd(item.x, item.y) ? "odd " : "even ") +
                            (isLatestPlayerMove(item.x, item.y) ? "latest " : "") +
                            (isHit(myMoves.hits, item.x, item.y) ? "hit " : "") +
                            (isAir(myMoves.airs, item.x, item.y) ? "air " : "") +
                            (queuedMoveClassName(queuedMoves, item.x, item.y)) +
                            (isDead(myMoves.kills, item.x, item.y) ? "dead " : "")}
                        key={item.x + "-" + item.y
                        }
                    >
                        {item.y == 0 && <span className="x-helper">{item.col}</span>}
                        {item.x == 0 && <span className="y-helper">{item.row}</span>}
                    </div>
                })}
            </div>
            {!isDailyGame && <div className="bottom">
                <div className="move-counter">
                    <div className="dead">&times; {Ui.toArray(otherMoves.kills).length}</div>
                    <div className="hit">&times; {Ui.toArray(otherMoves.hits).length}</div>
                    <div className="air">&times; {Ui.toArray(otherMoves.airs).length}</div>
                </div>
                <div className={"secondary-container " + (!lastMove?.myMove ? lastMoveType : '')}>
                    <div className="grid avioane secondary foreground play">
                        {items.map((item) => {
                            return <div className={
                                "item " +
                                (isHit(otherMoves.hits, item.x, item.y) ? "hit " : "") +
                                (isAir(otherMoves.airs, item.x, item.y) ? "air " : "") +
                                (isLatestOpponentMove(item.x, item.y) ? "latest " : "") +
                                (isDead(otherMoves.kills, item.x, item.y) ? "dead " : "")
                            } key={item.x + "-" + item.y}>
                            </div>
                        })}
                    </div>
                    <div className="grid avioane secondary background play">
                        {items.map((item) => {
                            return <div className={
                                "item " +
                                (planeDirection(item.x, item.y, myPlaneDirections) + " ") +
                                (isHead(item.x, item.y, myPlaneHeads) ? "head " : "")
                            } key={item.x + "-" + item.y}>
                            </div>
                        })}
                    </div>
                    <div className="grid avioane secondary background-background play">
                        {items.map((item) => {
                            return <div className={
                                "item "
                            } key={item.x + "-" + item.y}>
                            </div>
                        })}
                    </div>
                </div>
                <div className="move-counter">
                    <div className="dead">&times; {Ui.toArray(myMoves.kills).length}</div>
                    <div className="hit">&times; {Ui.toArray(myMoves.hits).length}</div>
                    <div className="air">&times; {Ui.toArray(myMoves.airs).length}</div>
                </div>
            </div>}
            <div style={{paddingBottom: '100px'}}></div>
        </IonContent>

        {!drawing &&
            <div className="draw-container">
                <IonText>Draw:</IonText>
                <div className="draw-bar">
                    <button className="red" onClick={() => startDrawing("red")}></button>
                    <button className="yellow" onClick={() => startDrawing("orange")}></button>
                    <button className="green" onClick={() => startDrawing("green")}></button>
                </div>
                <div className="settings">
                    <IonIcon icon={barcode} size="large" color={vibrationOn ? 'none' : 'light'}
                             onClick={() => toggleVibration()}/>
                    <IonIcon icon={musicalNotes} color={soundOn ? 'none' : 'light'} size="large"
                             onClick={() => toggleSound()}/>
                </div>
            </div>}
        {drawing &&
            <div className="draw-container">
                <IonButton size="small" onClick={() => stopDrawing()}>
                    Stop drawing
                </IonButton>
            </div>}
    </IonPage>;
}

export default Play

let listener = function (event) {
    let item = document.elementsFromPoint(event.clientX, event.clientY)[0] || null
    if (!item || !item.classList?.contains("item")) {
        return
    }
    drawFn(
        item.getAttribute('data-id')
    )

}

let listenForEvents = () => {
    if (!document.querySelector(".toucharea.play")) {
        console.log("no toucharea");
        return;
    }

    console.log("Listening for draw events");

    [document.querySelector(".toucharea.play")!].forEach((el) => {
        el.addEventListener('pointerdown', listener);
    })

}

let unlistenForEvents = () => {
    if (!document.querySelector(".toucharea.play")) {
        console.log("no toucharea");
        return;
    }

    console.log("UNListening for draw events");

    [document.querySelector(".toucharea.play")!].forEach((el) => {
        el.removeEventListener('pointerdown', listener)
    })

}