import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Link, useParams } from "react-router-dom";
import { useRedirectOnCondition } from "../hooks/useRedirectOnCondition";
import './Login.scss';
import * as Ui from '../fjs/fs/Ui';



function Inner({ accepteLinkChallenge }) {

    return <IonPage>
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonButton size="large" expand="full" onClick={() => {
                            accepteLinkChallenge();
                        }}>Play</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>;
}

function LinkChallenge({ state, dispatch }) {
    const { linkChallengeId } = useParams<any>();

    // let registerManager = Ui.getRegisterManager(state, dispatch)
    let accepteLinkChallenge = () => { Ui.acceptLinkChallenge(dispatch, linkChallengeId)}

    const redirected = useRedirectOnCondition([
        // [(() => state.Player), "/dashboard"], 
    ]);
    if(redirected) {
        return <div>redirecting</div>;
    }

    let waitingProps = {
        accepteLinkChallenge
    }
    return <Inner {...waitingProps} />
}


export default LinkChallenge