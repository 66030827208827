import { IonContent, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as Ui from '../fjs/fs/Ui';
import './History.scss';
import _ from "lodash";


function Inner({
    players,
    getScoresByFilter,
    getWinsAndLossesByFilter,
}) {

    let [playerFilter, setPlayerFilter] = useState("allPlayers")
    let [timeFilter, setTimeFilter] = useState("allTime")

    let playerFilters = _.uniqBy(
        players.map((s) => {
            return {
                Id: s,
                Name: s,
            }
        }), ((p: any) => p.Id))

    let scores = getScoresByFilter(playerFilter, timeFilter)
    let { wins, losses } = getWinsAndLossesByFilter(playerFilter, timeFilter)

    return <IonPage>
        <IonContent>
            <IonList>
                <IonListHeader mode="ios">
                    History
                </IonListHeader>
                <IonItem lines="full">
                    <IonLabel>
                        Score: {wins} - {losses}
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <div className="score-selectors">
                    <IonSelect placeholder="Players" value={playerFilter} onIonChange={(e) => {
                        setPlayerFilter(e.detail.value)
                    }}>
                        <IonSelectOption value="allPlayers">All players</IonSelectOption>
                        {playerFilters.map(p => <IonSelectOption key={p.Id} value={p.Id}>{p.Name}</IonSelectOption>)}
                    </IonSelect>
                    <IonSelect placeholder="Time" value={timeFilter} onIonChange={(e) => {
                        setTimeFilter(e.detail.value)
                    }}>
                        <IonSelectOption value="allTime">All time</IonSelectOption>
                        {playerFilter !== "allPlayers" && <IonSelectOption value="latestDay">Latest Day Played</IonSelectOption>}
                        <IonSelectOption value="today">Today</IonSelectOption>
                        <IonSelectOption value="yesterday">Yesterday</IonSelectOption>
                        <IonSelectOption value="lastWeek">Last 7 days</IonSelectOption>
                        <IonSelectOption value="lastMonth">Last 30 days</IonSelectOption>
                        <IonSelectOption value="lastYear">Last 365 days</IonSelectOption>
                    </IonSelect>
                </div>
                </IonItem>
                    
                {scores.map((s) => <IonItem lines="full" key={s.date}>
                    {s.type} vs {s.vs} on {s.date}
                </IonItem>
                )}
            </IonList>
        </IonContent>
    </IonPage>;
}

function History({ state, dispatch }) {
    const params: any = useParams();

    useEffect(() => {
        if (!state.Leaderboard) {
            Ui.fetchLeaderboard(dispatch);
        }
    }, [state.Leaderboard]);

    if (!state.Leaderboard) {
        return <div>loading...</div>
    }

    let props = {
        players: Ui.getPlayers(state),
        getScoresByFilter: (playerFilter, timeFilter) => Ui.getScoresByFilter(state, playerFilter, timeFilter),
        getWinsAndLossesByFilter: (playerFilter, timeFilter) => Ui.getWinsAndLossesByFilter(state, playerFilter, timeFilter),
    }

    return <Inner {...props} />
}

export default History