import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonToast, useIonViewDidEnter } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import * as Ui from '../fjs/fs/Ui';
import { useRedirectOnCondition } from "../hooks/useRedirectOnCondition";
import './Draw.scss';
import _ from "lodash";


let isHead = (x, y, planeHeads) => {
    return Ui.contains(x, y, planeHeads)
}

function Preview({ allPlanesPlaced, startGame }) {

    let miniItems: any[] = [];

    [1, 2, 3, 4, 5].forEach((row, y) => {
        ['a', 'b', 'c', 'd', 'e'].forEach((col, x) => {
            miniItems.push({
                row,
                col,
                y,
                x
            })
        })
    })

    let previewPlanes = [
        { direction: "North", plane: Ui.drawPlane(2, 0, Ui.mapStringToDirection("North")) },
        { direction: "South", plane: Ui.drawPlane(2, 4, Ui.mapStringToDirection("South")) },
        { direction: "West", plane: Ui.drawPlane(0, 2, Ui.mapStringToDirection("West")) },
        { direction: "East", plane: Ui.drawPlane(4, 2, Ui.mapStringToDirection("East")) },
    ]

    return <div >
        <div
            className="preview"
            style={{ display: allPlanesPlaced ? "none" : "grid" }}
        >

            {previewPlanes.map(({ direction, plane }: any) => {
                return <div
                    key={direction}
                    data-direction={JSON.stringify(direction)}
                    className={"draggable grid mini avioane " + (JSON.stringify(direction) == JSON.stringify(previewDirection) ? "selected " : "")}
                >
                    {miniItems.map((item) => {
                        return <div className={"item " + plane.Direction + " " + (isHead(item.x, item.y, plane.Heads) ? "head " : "")} key={item.x + "-" + item.y}>

                        </div>
                    })}
                </div>
            })}
        </div>
    </div>
}

function PlaneArea({ }) {

    let items: any[] = [];

    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((row, y) => {
        ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'].forEach((col, x) => {
            items.push({
                row,
                col,
                y,
                x
            })
        })
    })

    return <div className="main-container">
        <div className="grid avioane overlap-layer">
            {items.map((item) => {
                return <div
                    className={
                        "item item-main "
                    }
                    key={item.x + "-" + item.y}
                    data-xy={`${item.x}-${item.y}`}
                >
                </div>
            })}
        </div>
        <div className="grid avioane main-background">
            {items.map((item) => {
                return <div
                    className={
                        "item item-main "
                    }
                    key={item.x + "-" + item.y}
                >
                </div>
            })}
        </div>
        <div className="grid avioane main">
            {items.map((item) => {
                return <div
                    className={
                        "item item-main "
                    }
                    data-x={item.x}
                    data-y={item.y}
                    data-xy={`${item.x}-${item.y}`}
                    key={item.x + "-" + item.y}
                >
                    {item.y == 0 && <span className="x-helper">{item.col}</span>}
                    {item.x == 0 && <span className="y-helper">{item.row}</span>}
                </div>
            })}
        </div>
    </div>

}

function TouchDetectionOverlay({
    children,
    onHover,
    onDrop,
    onTap,
    onDragStart,
}) {

    let touchOverlay = useRef<any>();

    useEffect(() => {

        let remove = () => { console.log("cant remove events, nothing registered") }
        if (touchOverlay.current) {
            remove = listenForEvents(
                touchOverlay.current,
                (x, y) => onHover(x, y),
                (x, y) => onTap(x, y),
                (x, y) => onDrop(x, y),
                (dir) => {
                    onDragStart(dir)
                },
            );
        }

        return () => {
            remove()
        }
    }, [touchOverlay.current])

    return <div ref={touchOverlay} className="touch-overlay">
        {children}
    </div>
}


let listenForEvents = (touchareaElement, onHover, onTap, onDrop, onDragStart) => {
    console.log("listening for events")

    let listenersToRemove: any[] = [];

    let registerEventListener = (el, eventName, listener) => {
        el.addEventListener(eventName, listener);

        listenersToRemove.push(() => { el.removeEventListener(eventName, listener) })
    }


    touchareaElement.querySelectorAll(".draggable").forEach((el) => {

        registerEventListener(el, "pointerdown", function (event) {
            let dir = Ui.mapStringToDirection(JSON.parse(el.getAttribute('data-direction')!));


            (async () => {
                Haptics.impact({ style: ImpactStyle.Light })
            })()

            onDragStart(dir)
        })

    });

    [touchareaElement].forEach((el) => {

        registerEventListener(el, "pointerdown", function (event) {
            let item = document.elementsFromPoint(event.clientX, event.clientY)[0] || null

            onTap(
                item.getAttribute('data-x'),
                item.getAttribute('data-y'),
            )
        })


        registerEventListener(el, 'pointermove', function (event) {
            let item = document.elementsFromPoint(event.clientX, event.clientY)[0] || null

            onHover(
                item.getAttribute('data-x'),
                item.getAttribute('data-y'),
            )
        })

        registerEventListener(el, 'pointerdown', function (event) {
            let item = document.elementsFromPoint(event.clientX, event.clientY)[0] || null
            onTap(
                item.getAttribute('data-x'),
                item.getAttribute('data-y'),
            )
        })

        registerEventListener(el, 'pointerup', function (event) {
            let item = document.elementsFromPoint(event.clientX, event.clientY)[0] || null
            onDrop(
                item.getAttribute('data-x'),
                item.getAttribute('data-y'),
            )
        })
    })

    return () => {
        console.log("removing listeners");

        listenersToRemove.forEach((removeFn) => removeFn())
    }
}

/**
 * TouchDetectionOverlay 
 * - e elementul pe care se asculta pentru evenimente de touch si le face forward prin handles
 * - se ocupa in useEffect de listen / unlisten de evenimente de touch
 * PlaneArea doar deseneaza tabla de joc (de interactiuni se ocupa TouchDetectionOverlay)
 * Preview doar deseneaza cele 4 orientari de avioane (de interactiuni se ocupa TouchDetectionOverlay)
 * listenForEvents ataseaza listeners la touch detection overlay
 * In Inner e parte din logica
 * - e suprascris drawClasses global, care adauga / scoate clase de css din PlaneArea
 * In Draw e alta parte din logica
 * - e suprascris addPlaneFn global (pentru ca aici avem acces la State)
 * - e suprascris removePlaneFn global (pentru ca aici avem acces la State)
 * 
 * Lucrurile nu se fac prin React ci se fac prin a suprascrie variable globale
 * 
 */
function Draw({ state, dispatch }) {
    const redirected = useRedirectOnCondition([
        [(() => !state.ApiKey), "/"],
        [(() => !Ui.canBeOnDrawPage(state)), "/"],
    ]);

    const [presentToast] = useIonToast()

    if (redirected) {
        return <div>redirecting</div>;
    }

    addPlaneFn = (x, y, direction) => {

        if (!direction) {
            return false;
        }

        let plane = Ui.makePlane(x, y, direction);

        let issues = Ui.validatePlanePlacement(Ui.getPlanesWith(Ui.getPlanes(state), plane))

        if (issues.length) {
            issues.forEach((m) => {
                presentToast({ message: m, duration: 1000, position: 'top' });
            })

            return false;
        }


        Ui.addPlane(dispatch, plane)

        return true;
    };

    removePlaneFn = (x, y) => {

        let plane = Ui.getPlaneAt(Ui.getPlanes(state), parseInt(x), parseInt(y))

        if (plane) {
            Ui.removePlane(dispatch, plane)

            return true;
        } else {
            Ui.toArray(Ui.getPlanes(state))
        }

        return false;
    }

    let drawProps = {
        startGameFn: () => Ui.startGame(dispatch),
        classesFn: (previewPlane) => {
            return Ui.getDrawClasses(state, previewPlane)
        },
        allPlanesPlaced: Ui.toArray(Ui.getPlanes(state)).length === 3,
        isMatchmaking: Ui.drawingForMatchmaking(state),
    }


    return <Inner {...drawProps} />;
}

let previewDirection = null
let previewX = null
let previewY = null


let drawClasses = () => { console.log("drawClasses not init") }
let addPlaneFn = (x, y, direction) => { console.log("addPlaneFn not init"); return false; }
let removePlaneFn = (x, y) => { console.log("removePlaneFn not init"); return false; }

function Inner({
    classesFn,
    startGameFn,
    allPlanesPlaced,
    isMatchmaking,
}) {

    let classes = []

    drawClasses = () => {
        if (previewDirection && previewX && previewY) {
            classes = classesFn(Ui.makePlane(previewX, previewY, previewDirection))
        } else {
            classes = classesFn(null)
        }

        classes.forEach(([[x, y], classList]) => {
            let el = document.querySelector(`.grid.avioane.main [data-xy="${x}-${y}"]`)

            if (el) {
                el.classList.remove("head")
                el.classList.remove("part")
                el.classList.remove("North")
                el.classList.remove("South")
                el.classList.remove("East")
                el.classList.remove("West")

                el.classList.remove("preview-head")
                el.classList.remove("preview-part")
                el.classList.remove("preview-North")
                el.classList.remove("preview-South")
                el.classList.remove("preview-East")
                el.classList.remove("preview-West")
            }

            if ((classList as any).length) {
                (classList as any).forEach((c) => {
                    el?.classList.add(c)
                })
            }

            let overlapEl = document.querySelector(`.grid.avioane.overlap-layer [data-xy="${x}-${y}"]`)
            
            if (overlapEl) {
                overlapEl.classList.remove("overlap")
            }
            if ((classList as any).length) {
                (classList as any).forEach((c) => {
                    if (c === 'overlap') {
                        overlapEl?.classList.add(c)
                    }
                })
            }
        })

    }

    drawClasses();

    let startGame = () => {
        (async () => {
            Haptics.notification({ type: NotificationType.Success })
        })()
        startGameFn()
    }

    return <IonPage>
        <IonContent className="draw-page">
            <TouchDetectionOverlay
                onDragStart={(dir) => {
                    previewDirection = dir
                    previewX = null
                    previewY = null
                }}
                onHover={(x, y) => {
                    previewX = x
                    previewY = y
                    drawClasses()
                }}
                onDrop={(x, y) => {
                    addPlaneFn(x, y, previewDirection);

                    previewDirection = null
                    previewX = null
                    previewY = null

                    drawClasses()
                }}
                onTap={(x, y) => {
                    let removed = removePlaneFn(x, y)
                    if (removed) {
                        previewDirection = null
                        previewX = null
                        previewY = null
                    }
                    drawClasses()
                }}
            >
                <div style={{ padding: "10px" }}>

                    {allPlanesPlaced
                        ? <div
                            style={{ display: "block", width: "100%" }}
                        >
                            <div>
                                All planes placed. <br />
                                <IonButton onClick={() => startGame()}>
                                    { isMatchmaking ? "Join matchmaking queue" : "Ready!"}
                                </IonButton>
                            </div>
                        </div>
                        : <div>
                            Drag Planes from the bottom to the top to place them. <br />
                            Tap / click on a placed plane to remove it. <br />
                            Place 3 planes.
                        </div>
                    }
                </div>

                <div className="main-parent">
                    <PlaneArea />
                </div>
                <Preview
                    startGame={startGame}
                    allPlanesPlaced={allPlanesPlaced}
                />
            </TouchDetectionOverlay>

        </IonContent>
    </IonPage>;
}

export default Draw