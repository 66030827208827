import { IonButton, IonContent, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonSelect, IonSelectOption } from "@ionic/react";
import { useState } from "react";
import { useParams } from "react-router";
import * as Ui from '../fjs/fs/Ui';
import './Login.scss';


function Inner({ 
    getFriend, 
    challengeFriend,
}) {

    const { friendshipId } = useParams<any>();

    const [addFriend, setAddFriend] = useState(false);
    const [username, setUsername] = useState("");

    let friend: any = getFriend(friendshipId)

    if (!friend) {
        return <div>Loading...</div>
    }

    return <IonPage>
        <IonContent>
            <IonList>
                <IonListHeader mode="ios">
                    Challenge {friend.Name}
                </IonListHeader>
                <IonItem lines="full" >
                    <IonSelect placeholder="Select match length" style={{width: "100%", "maxWidth": "100%"}}>
                        <IonLabel>Match length</IonLabel>
                        <IonSelectOption value="3">2 out of 3</IonSelectOption>
                        <IonSelectOption value="5">3 out of 5</IonSelectOption>
                        <IonSelectOption value="7">4 out of 7</IonSelectOption>
                        <IonSelectOption value="9">5 out of 9</IonSelectOption>
                        <IonSelectOption value="11">6 out of 11</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem lines="none" >
                    <IonButton color="primary" onClick={() => {
                        challengeFriend(friend.Id)
                    }}>Challenge</IonButton>
                </IonItem>

            </IonList>
        </IonContent>
    </IonPage>;
}

function Challenge({ state, dispatch}) {
    let challengeProps = {
        getFriend: (friendshipIdString) => Ui.getFriend(dispatch, friendshipIdString),
        challengeFriend: (friendshipId) => Ui.challengeFriend(dispatch, friendshipId),
    }

    return <Inner {...challengeProps} />
}

export default Challenge