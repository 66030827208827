import { IonContent, IonPage } from "@ionic/react";
import { useRedirectOnCondition } from "../hooks/useRedirectOnCondition";
import './Login.scss';
import { canBeOnWaitingPage } from "../fjs/fs/Ui";



function Inner({}) {
    
    return <IonPage>
        <IonContent>
            <h1 style={{"textAlign": "center"}}>Waiting for other players</h1>
        </IonContent>
    </IonPage>;
}

function Waiting({state, dispatch}) {
    
    const redirected = useRedirectOnCondition([
        [(() => !canBeOnWaitingPage(state)), "/"], 
    ]);
    if(redirected) {
        return <div>redirecting</div>;
    }

    let waitingProps = {
    }
    return <Inner {...waitingProps}  />
}


export default Waiting