const GameHomeSwitchItem = (
    {
        queueState,
        actionFn,
    }) =>  {
    
    if (queueState === 'accept') {
        return <div className="switch-item gameend queue-accept" onClick={() => actionFn()}>
            Accept
        </div>
    }

    if (queueState === 'wait') {
        return <div className="switch-item gameend" onClick={() => actionFn()}>
            Wait
        </div>
    }

    if (queueState === 'inQueue') {
        return <div className="switch-item gameend" onClick={() => actionFn()}>
            in Q
        </div>
    }

    return <div className="switch-item gameend home" onClick={() => actionFn()}>
        Home
    </div>
}

export default GameHomeSwitchItem;

