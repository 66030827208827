import { formatDistance, formatDistanceToNowStrict } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export function toLocalDateTime2(serverDateTime) {
    let utc = zonedTimeToUtc(serverDateTime, 'UTC');
    let zoned = utcToZonedTime(utc, Intl.DateTimeFormat().resolvedOptions().timeZone);

    return zoned;
}

export function timeAgo(serverDateTime) {
    return formatDistance(serverDateTime, Date.now(), { includeSeconds: true, addSuffix: true})
}

export function timeElapsed(date) {
    return formatDistanceToNowStrict(date)
}

