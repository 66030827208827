import { Preferences } from "@capacitor/preferences";

export async function getItem(key) {
  let {value} = await Preferences.get({
    key: key,
  });

  return value;
}

export async function setItem(key, stringValue) {
  await Preferences.set({
    key: key,
    value: stringValue,
  });  
}

export async function removeItem(key) {
  let {value} = await Preferences.remove({
    key: key,
  });

  return value;
}

export async function removeItems(keys) {
  for(var i = 0; i < keys.length; i++) {
    let key = keys[i];
    await Preferences.remove({
      key: key,
    });
  }
}

export async function removeAll() {
  return await Preferences.clear();
}

