import * as signalR from '@microsoft/signalr'

let conn : signalR.HubConnection | null = null;
let promise : any = null;

let previousConnections : signalR.HubConnection[] = [];
export const init = (host, token, serverResponseHandlerFn) => {
    console.warn("Trying to init signalR");
    
    if (conn) {
        console.warn("Trying to close previous socket");
        conn.stop();
    }
    
    let connection = new signalR.HubConnectionBuilder()
        // .withAutomaticReconnect([])
        .withUrl(host + "/chatHub?access-token=" + token, { accessTokenFactory: () => token })
        .build(); 
    
    connection.on("ServerResponse", data => {
        serverResponseHandlerFn(data)
    });

    conn = connection
    promise = connection.start();
}

export const SocketMsg = async (json) => {
    if (!conn) {
        console.log("SignalR not initialized")
        return;
    }

    await promise;
    await conn.invoke("SocketMsg", json)
}
