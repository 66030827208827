import {useEffect, useState } from "react";
import * as Ui from "./fjs/fs/Ui";

function range(start, endInclusive) {
    let out: any[] = [];
    for (let i = start; i <= endInclusive; i++) {
        out.push(i);
    }

    return out;
}


function Timer({timeout}) {
    const [elapsed, setElapsed] = useState(0);
    useEffect(() => {
        let t = setInterval(() => {
            setElapsed((v) => v + 1)
        }, 500)

        return () => {
            clearInterval(t)
        }
    }, [timeout])

    if (!timeout) {
        return <span></span>
    }
    
    let percentage = Ui.toPercentageElapsedFloat(timeout, 60);
    
    percentage = Math.max(0, percentage)
    percentage = Math.min(100, percentage)
    

    return <div className="timeout" style={{"width" : `${percentage}%`}}></div>
}
const GameSwitchItem = (
    {
        isCurrent,
        isPlayerTurn,
        timeout,
        playerDeadCount,
        otherDeadCount,
        isGameOver,
        playerWon,
        lastPlayerMoveResult,
        lastOtherMoveResult,
        actionFn,
        altActionFn,
    }) => {

    if (isGameOver) {
        if (isCurrent) {
            return <div className={"switch-item gameend current"}
                        onClick={() => { altActionFn() }}
            >
                Hide
            </div>
        }
        if (playerWon) {
            return <div className={"switch-item gameend "}
                onClick={() => { actionFn() }}
            >
                Won
            </div>
        } else {
            return <div className={"switch-item gameend "}
                onClick={() => { actionFn() }}
            >
                Lost
            </div>

        }
    }

    let playerPips = range(1, playerDeadCount);
    let otherPips = range(1, otherDeadCount);

    return <div className={"switch-item " + (isPlayerTurn ? "player-turn " : "other-turn ") + (isCurrent ? "current " : " ")}
        onClick={() => { actionFn() }}
    >
        <Timer timeout={timeout} />
        <div className="moves">
            <div className={"switch-icon " + lastPlayerMoveResult }></div>
            <div className={"switch-icon " + lastOtherMoveResult }></div>
        </div>
        <div className="progress">
            <div className="player-progress player-side">
                {playerPips.map((p) => <div className="progress-pip" key={p}></div>)}
            </div>
            <div className="player-progress other-side">
                {otherPips.map((p) => <div className="progress-pip" key={p}></div>)}
            </div>
        </div>
    </div>

}

export default GameSwitchItem;

