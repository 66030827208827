import { Union } from "../fable_modules/fable-library.4.1.4/Types.js";
import { union_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { some } from "../fable_modules/fable-library.4.1.4/Option.js";
import { nonSeeded } from "../fable_modules/fable-library.4.1.4/Random.js";
import { length, item } from "../fable_modules/fable-library.4.1.4/List.js";
import { utcNow, op_Subtraction } from "../fable_modules/fable-library.4.1.4/Date.js";
import { max } from "../fable_modules/fable-library.4.1.4/Double.js";
import { seconds as seconds_1, totalMinutes } from "../fable_modules/fable-library.4.1.4/TimeSpan.js";
import { op_UnaryNegation_Int32 } from "../fable_modules/fable-library.4.1.4/Int32.js";
import { createAtom } from "../fable_modules/fable-library.4.1.4/Util.js";

export class Env extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Dev", "Prod"];
    }
}

export function Env_$reflection() {
    return union_type("Utils.Env", [], Env, () => [[], []]);
}

export function getEnv() {
    if (window.location.host === "localhost:8100") {
        return new Env(0, []);
    }
    else {
        return new Env(1, []);
    }
}

export function debug(a) {
}

export function alert(a) {
    window.alert(some(toText(printf("%A"))(a)));
}

export function log2(a) {
    let arg_1;
    const e = new Error(toText(printf("%A"))(a));
    console.log(some((arg_1 = e.stack, toText(printf("%A"))(arg_1))));
}

export function log(a) {
    console.log(some(a));
}

export function logPipe(a) {
    log(a);
    return a;
}

export function toRad(value) {
    return (value * 3.141592653589793) / 180;
}

export function distance(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const lat1_1 = toRad(lat1);
    const lat2_1 = toRad(lat2);
    const a = (Math.sin(dLat / 2) * Math.sin(dLat / 2)) + (((Math.sin(dLon / 2) * Math.sin(dLon / 2)) * Math.cos(lat1_1)) * Math.cos(lat2_1));
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
}

let routerPushFn = (s) => {
    log("Can\'t navigate yet because router wasn\'t activated");
};

export function setRouterPushFn(fn) {
    routerPushFn = fn;
}

export function pushRouter(s) {
    routerPushFn(s);
}

export function getRandomInList(lst) {
    const rnd = nonSeeded();
    return item(rnd.Next1(length(lst)), lst);
}

export function toTimeElapsedString(dateTime) {
    const d = op_Subtraction(utcNow(), dateTime);
    const minutes = max(0, totalMinutes(d));
    const seconds = max(0, seconds_1(d)) | 0;
    const arg = ~~minutes | 0;
    return toText(printf("%02i:%02i"))(arg)(seconds);
}

export function toTimeRemainingString(dateTime) {
    const d = op_Subtraction(utcNow(), dateTime);
    const minutes = max(0, -totalMinutes(d));
    const seconds = max(0, op_UnaryNegation_Int32(seconds_1(d))) | 0;
    const arg = ~~Math.floor(minutes) | 0;
    return toText(printf("%02i:%02i"))(arg)(seconds);
}

export let toastPresentFn = createAtom((obj) => {
    log("Toast presenter not registered");
});

export function presentErrorToast(message) {
    const arg = {
        duration: 1000,
        message: message,
        position: "top",
    };
    toastPresentFn()(arg);
}

export function presentInfoToast(message) {
    const arg = {
        duration: 1000,
        message: message,
        position: "bottom",
    };
    toastPresentFn()(arg);
}

