import { awaitPromise } from "../fable_modules/fable-library.4.1.4/Async.js";
import * as storage from "../../js/storage.js";
import { Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { Player_$reflection } from "../Shared/Types.js";
import { record_type, bool_type, string_type, option_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { Convert_fromJson, Convert_serialize } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { singleton } from "../fable_modules/fable-library.4.1.4/AsyncBuilder.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.1.4/Choice.js";
import { SimpleJson_tryParse } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";

export function getValueFromStorageAsync(key) {
    return awaitPromise(storage.getItem(key));
}

export class StorageContent extends Record {
    constructor(Player, ApiKey, VibrationOn, SoundOn) {
        super();
        this.Player = Player;
        this.ApiKey = ApiKey;
        this.VibrationOn = VibrationOn;
        this.SoundOn = SoundOn;
    }
}

export function StorageContent_$reflection() {
    return record_type("Local.StorageContent", [], StorageContent, () => [["Player", option_type(Player_$reflection())], ["ApiKey", option_type(string_type)], ["VibrationOn", bool_type], ["SoundOn", bool_type]]);
}

export function storeAllInStorage(state) {
    let arg_1, typeInfo;
    const all = new StorageContent(state.Player, state.ApiKey, state.VibrationOn, state.SoundOn);
    return awaitPromise((arg_1 = ((typeInfo = createTypeInfo(StorageContent_$reflection()), Convert_serialize(all, typeInfo))), storage.setItem("all", arg_1)));
}

export function tryGetAllFromStorageAsync() {
    return singleton.Delay(() => singleton.Bind(getValueFromStorageAsync("all"), (_arg) => {
        let matchValue, inputJson, typeInfo, m, res_1;
        const value = _arg;
        let res;
        try {
            res = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(value), (matchValue != null) ? ((inputJson = matchValue, (typeInfo = createTypeInfo(StorageContent_$reflection()), Convert_fromJson(inputJson, typeInfo)))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            res = (new FSharpResult$2(1, [ex.message]));
        }
        return singleton.Return((res.tag === 1) ? ((m = res.fields[0], (console.log(m), void 0))) : ((res_1 = res.fields[0], res_1)));
    }));
}

