import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonPage } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { useState } from "react";
import * as Ui from '../fjs/fs/Ui';
import './Login.scss';


function Inner({ 
    requests, 
    friends, 
    onlineFriends, 
    requestFriendship,
    isFriendOnline,
    challengeFriend,
    declineRequest,
    acceptRequest,
}) {
   
    const [addFriend, setAddFriend] = useState(false);
    const [username, setUsername] = useState("");

    // let requests: any = Ui.getFriendRequests(state)
    // let friends: any = Ui.getFriends(state)
    // let onlineFriends: any[] = Ui.getOnlineFriends(state)

    return <IonPage>
        <IonContent>
            <IonList>
                <IonListHeader mode="ios">
                    Friends
                </IonListHeader>
                <IonItem lines="full" >
                {addFriend
                    ? <>
                        <IonInput placeholder="Enter username" onIonChange={(e) => {
                            setUsername(e.detail.value!)
                        }}/>
                        <IonButton color="primary" onClick={() => {
                            requestFriendship(username);
                            setAddFriend(false);
                        }}>Send</IonButton>
                        <IonButton color="light" onClick={() => setAddFriend(false)}>Cancel</IonButton>
                    </>
                    : <>
                        <IonButton slot="end" color="primary" onClick={() => setAddFriend(true)}>Add</IonButton>
                    </>
                }
                    </IonItem>

                {friends.map((s) => <IonItem lines="full" key={s.Id}>
                    {isFriendOnline(s.Id) 
                        ? <IonIcon icon={ellipse} className="onlineIndicator online" />
                        : <IonIcon icon={ellipse} className="onlineIndicator" /> 
                    }
                    <IonLabel>
                        {s.Name}
                    </IonLabel>
                    {/* <IonButton color="primary" routerLink={"/challenge/" + s.IdAsString}>Challenge</IonButton> */}
                    <IonButton color="primary" onClick={() => {
                        challengeFriend(s.Id) 
                    }}>Challenge</IonButton>
                    <IonButton color="danger" onClick={() => {
                        declineRequest(s.Id) 
                    }}>Remove</IonButton>
                </IonItem>
                )}
            </IonList>
            <IonList>
                <IonListHeader mode="ios">
                    Requests
                </IonListHeader>
                {requests.map((s) => <IonItem lines="full" key={s.Id}>
                    <IonLabel>
                        {s.Name}
                    </IonLabel>
                    <IonButton color="primary" onClick={() => {
                        acceptRequest(s.Id)
                    }}>Accept</IonButton>
                    <IonButton color="light" onClick={() => {
                        declineRequest(s.Id) 
                    }}>Hide</IonButton>
                </IonItem>
                )}
            </IonList>
        </IonContent>
    </IonPage>;
}

function Friends({ state, dispatch}) {

    let friendsProps = {
        requests: Ui.getFriendRequests(state),
        friends: Ui.getFriends(state),
        onlineFriends: Ui.getOnlineFriends(state),
        requestFriendship: (username) => Ui.requestFriendship(dispatch, username),
        isFriendOnline: (friendshipId) => Ui.isFriendOnline(state, friendshipId),
        challengeFriend: (friendshipId) => Ui.challengeFriend(dispatch, friendshipId),
        declineRequest: (friendshipId) => Ui.declineRequest(dispatch, friendshipId),
        acceptRequest: (friendshipId) => Ui.acceptRequest(dispatch, friendshipId),
    }

    return <Inner {...friendsProps} />
}

export default Friends