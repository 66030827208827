import {
    IonBadge,
    IonButton,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as Ui from '../fjs/fs/Ui';
import {useRedirectOnCondition} from "../hooks/useRedirectOnCondition";
import './Dashboard.scss';
import SwitchBar from "../SwitchBar";


function DailyRow({dailyState, totalScore, finishedLayouts, playDailyFn}) {

    if (dailyState === "started") {
        return <div onClick={() => playDailyFn()} className="daily-btn">
            <PlayOptionRow title={"Daily 5"}
                           subtitle={`${finishedLayouts}/5 done. Score: ${totalScore}`}> </PlayOptionRow>
        </div>;
    }

    if (dailyState === "finished") {
        return <div onClick={() => playDailyFn()} className="daily-btn">
            <PlayOptionRow title={"Daily 5"} subtitle={<span>Done for today</span>}> </PlayOptionRow>
        </div>;
    }

    return <div onClick={() => playDailyFn()} className="daily-btn">
        <PlayOptionRow title={"Daily 5"}
                       subtitle={<span>Solve 5 games in as few moves as possible</span>}> </PlayOptionRow>
    </div>;
}

function LeaderboardRow({}) {
    return <Link onClick={(e) => e.stopPropagation()} to="/leaderboard" className="leaderboard-btn">
        <PlayOptionRow title={"Leaderboard"} subtitle={``}> </PlayOptionRow>
    </Link>;
}

function MatchmakingRow({onlineCount, activeWaitingRoomCount, playMatchmaking}) {
    let str = onlineCount === 0 ? null : (onlineCount === 1 ? `1 online (you)` : `${onlineCount} online`)
    let str2 = activeWaitingRoomCount > 0 ? `${onlineCount} in queue` : null

    return <div onClick={() => playMatchmaking()} className="matchmaking-btn">
        <PlayOptionRow title={<span>VS. <br/> other <br/> players</span>} subtitle={``}> </PlayOptionRow>
    </div>
}


function FriendsRow({onlineFriendsCount, requestsCount}) {
    let onlineString = onlineFriendsCount > 0 ? `${onlineFriendsCount} online` : null;
    let requestString = requestsCount === 1 ? `1 request` : (requestsCount > 1 ? `${requestsCount} requests` : null)
    let str = onlineString && requestString ? `${requestString} | ${onlineString}` : (requestString || onlineString)

    return <Link to="/friends">
        <PlayOptionRow title={"Play vs. friends"} subtitle={`Challenge someone from your friend list`}> </PlayOptionRow>
    </Link>

    return <>
        <Link to="/friends">
            <IonItem className="play-option title" button detail lines="none">
                {str && <IonBadge slot="end">{str}</IonBadge>}
                <IonLabel>Play vs. friend</IonLabel>
            </IonItem>
        </Link>
    </>


}

function LinkChallengeRow({playWithLink}) {
    return <div onClick={() => playWithLink()}>
        <PlayOptionRow title={"Play vs. anyone"} subtitle={`Send a link to play together.`}> </PlayOptionRow>
    </div>
}

function LinkChallengeRow2({room, cancelChallenge}) {
    let [copiedText, setCopiedText] = useState("");

    const copyContent = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    return <PlayOptionRow title={`Waiting for link to accept`} subtitle={<IonInput value={room.Link} readonly/>}>
        <IonButton size="small" onClick={() => copyContent(room.Link)} color="success">
            {copiedText === "" ? "Copy to clipboard" : "Copied, ready to paste"}
        </IonButton>
        <IonButton size="small" color="danger" onClick={() => {
            cancelChallenge(room.Id)
        }}>Cancel</IonButton>
    </PlayOptionRow>
}


function PlayOptionRow({children, title, subtitle}) {
    return <div className="play-option-row">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <div className="actions">
            {children}
        </div>
    </div>
}

function RoomRow({children, player, subtitle}) {
    return <div className="room-row">
        <div className="main">
            <div className={"player " + player.Icon}>
            </div>
            <div className="content">
                <div className="content-main">
                    <div className="player-name">{player.Name}</div>
                    <div className="subtitle">{subtitle}</div>
                </div>
                <div className="actions">
                    {children}
                </div>
            </div>
        </div>
    </div>
}

function ReadyCheckRow({
                           readyCheck,
                           readyCheckConfirm,
                           readyCheckDecline,
                       }) {
    if (!readyCheck) {
        return <></>
    }
    return <div className="accept-match-row">
        <div className="status">
            {readyCheck.Accepted
                ? <span>Waiting for other player!</span>
                : <span>Match ready!</span>}

        </div>
        <div className="time">
            <TimeElapsed date={readyCheck.Date}/>
        </div>
        <div className="actions">
            {!readyCheck.Accepted && <IonButton className="cancel" color="success" size="small"
                                                onClick={() => readyCheckConfirm(readyCheck.Id)}>Accept</IonButton>}
            <IonButton className="cancel" color="danger" size="small" onClick={() => readyCheckDecline(readyCheck.Id)}>
                Decline
            </IonButton>
        </div>
    </div>
}

function InMatchmakingRow({
                              inMatchmaking,
                              abandonMatchmaking,
                          }) {
    if (!inMatchmaking) {
        return <></>
    }
    return <div className="matchmaking-btn">
        <div className="matchmaking-row">
            <div className="content-main">
                <div className="status">
                    in matchmaking
                </div>
                <div className="time">
                    <TimeElapsed date={inMatchmaking}/>
                </div>
            </div>
            <div className="actions">
                <IonButton className="cancel" color="danger" size="small"
                           onClick={() => abandonMatchmaking(inMatchmaking)}>Cancel</IonButton>
            </div>
        </div>
    </div>
}


function TimeElapsed({date}) {
    const [elapsed, setElapsed] = useState(0);
    useEffect(() => {
        let t = setInterval(() => {
            setElapsed((v) => v + 1)
        }, 500)

        return () => {
            clearInterval(t)
        }
    }, [date])

    if (!date) {
        return <span></span>
    }

    return <span>{Ui.toTimeElapsedString(date)}</span>
}


function TimeAgo({date}) {
    const [elapsed, setElapsed] = useState(0);
    useEffect(() => {
        let t = setInterval(() => {
            setElapsed((v) => v + 1)
        }, 500)

        return () => {
            clearInterval(t)
        }
    }, [date])

    if (!date) {
        return <span></span>
    }

    return <span>{Ui.toTimeAgoString(date)}</span>
}

function Inner(
    {
        players,
        getScoresByFilter,
        activeRooms,
        challengeRooms,
        linkChallengeRooms,
        getWinsAndLossesByFilter,
        requests,
        onlineFriends,
        player,
        onlineCount,
        activeWaitingRoomCount,
        playDaily,
        playMatchmaking,
        readyCheckConfirm,
        readyCheckDecline,
        playWithLink,
        cancelChallenge,
        acceptChallenge,
        declineChallenge,
        rejoinChallenge,
        rejoinActiveRoom,
        abandonGame,
        abandonMatchmaking,
        logout,
        isAnonPlayer,
        rejoinableRooms,
        inMatchmaking,
        readyChecks,
        acceptedRematches,
        incomingRematches,
        outgoingRematches,
        incomingChallenges,
        outgoingChallenges,
        acceptedChallenges,
        acceptedLinkChallenges,
        outgoingLinkChallenges,
        dailyState,
        totalScore,
        finishedLayouts,
        switchBar
    }) {
    let {wins, losses} = getWinsAndLossesByFilter("allPlayers", "allTime")

    return <IonPage>
        <IonContent className="dashboard-page">
            {switchBar}
            
            {readyChecks.map((readyCheck) => <ReadyCheckRow
                key={readyCheck.Id}
                readyCheck={readyCheck}
                readyCheckConfirm={readyCheckConfirm}
                readyCheckDecline={readyCheckDecline}
            />)}
            
            <IonList>
                {rejoinableRooms.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Rejoin vs {room.OtherName} <br/> <TimeAgo date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <Link to={"/play/" + room.IdString}><IonButton size="small"
                                                                       color="success">Rejoin</IonButton></Link>
                        <IonButton size="small" color="danger" onClick={() => abandonGame(room.Id)}>Abandon</IonButton>
                    </RoomRow>
                })}

                {outgoingRematches.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Waiting for {room.OtherName} to accept the rematch <br/> <TimeAgo
                                        date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="danger"
                                   onClick={() => cancelChallenge(room.Id)}>Cancel</IonButton>
                    </RoomRow>
                })}
                {incomingRematches.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Wants a rematch <br/> <TimeAgo date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="success"
                                   onClick={() => acceptChallenge(room.Id)}>Accept</IonButton>
                        <IonButton size="small" color="danger"
                                   onClick={() => declineChallenge(room.Id)}>Decline</IonButton>
                    </RoomRow>
                })}

                {acceptedRematches.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Waiting for {room.OtherName}</span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="success"
                                   onClick={() => rejoinChallenge(room.Id)}>Edit</IonButton>
                        <IonButton size="small" color="danger"
                                   onClick={() => cancelChallenge(room.Id)}>Abandon</IonButton>
                    </RoomRow>
                })}


                {incomingChallenges.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Challenged by {room.OtherName} <br/> <TimeAgo
                                        date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="success"
                                   onClick={() => acceptChallenge(room.Id)}>Accept</IonButton>
                        <IonButton size="small" color="danger"
                                   onClick={() => declineChallenge(room.Id)}>Decline</IonButton>
                    </RoomRow>
                })}

                {outgoingChallenges.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span> Waiting for {room.OtherName} to accept <br/> <TimeAgo
                                        date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="danger"
                                   onClick={() => cancelChallenge(room.Id)}>Cancel</IonButton>
                    </RoomRow>
                })}


                {acceptedChallenges.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Challenge accepted <br/> <TimeAgo date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="success"
                                   onClick={() => rejoinChallenge(room.Id)}>Rejoin</IonButton>
                        <IonButton size="small" color="danger"
                                   onClick={() => cancelChallenge(room.Id)}>Abandon</IonButton>
                    </RoomRow>
                })}

                {acceptedLinkChallenges.map((room) => {
                    return <RoomRow key={room.Id}
                                    subtitle={<span>Waiting for other to accept <br/> <TimeAgo
                                        date={room.Date}/></span>}
                                    player={{Name: room.OtherName, Icon: room.OtherIcon}}>
                        <IonButton size="small" color="success"
                                   onClick={() => rejoinChallenge(room.Id)}>Edit</IonButton>
                        <IonButton size="small" color="danger"
                                   onClick={() => cancelChallenge(room.Id)}>Abandon</IonButton>
                    </RoomRow>
                })}

                <div className="button-grid-main">
                    <div className="button-grid-top">
                        {inMatchmaking ?
                            <InMatchmakingRow
                                inMatchmaking={inMatchmaking}
                                abandonMatchmaking={abandonMatchmaking}
                            />
                            :
                            <MatchmakingRow
                                onlineCount={onlineCount}
                                activeWaitingRoomCount={activeWaitingRoomCount}
                                playMatchmaking={playMatchmaking}
                            />}
                        <div className="button-grid-top-right">
                            <LeaderboardRow/>
                            <DailyRow dailyState={dailyState} playDailyFn={playDaily} totalScore={totalScore}
                                      finishedLayouts={finishedLayouts}/>

                        </div>

                    </div>
                    <FriendsRow
                        requestsCount={requests.length}
                        onlineFriendsCount={onlineFriends.length}
                    />
                    {outgoingLinkChallenges.length === 0 && <LinkChallengeRow
                        playWithLink={playWithLink}
                    />}
                    {outgoingLinkChallenges.map((room) => <LinkChallengeRow2 key={room.Id} room={room}
                                                                             cancelChallenge={cancelChallenge}/>)}
                </div>
            </IonList>

            <IonList>
                <Link to="/history">
                    <IonItem button detail lines="full">
                        <IonLabel>
                            Lifetime score {wins}W - {losses}L
                        </IonLabel>
                    </IonItem>
                </Link>

                <Link to="/icon">
                    <IonItem button detail lines="full">
                        <IonLabel>
                            Change icon
                        </IonLabel>
                    </IonItem>
                </Link>

                <IonItem button detail lines="full"
                         onClick={() => {
                             logout()
                         }}
                >
                    <IonLabel>
                        Logout
                        {isAnonPlayer ? " (lose all data)" : ""}
                    </IonLabel>
                </IonItem>
                {isAnonPlayer && <Link to="/register">
                    <IonItem button detail lines="full">
                        <IonLabel>
                            Register (and save data)
                        </IonLabel>
                    </IonItem></Link>}
            </IonList>
        </IonContent>
    </IonPage>;
}

function Dashboard({state, dispatch}) {
    const redirected = useRedirectOnCondition([
        [(() => !state.Player), "/"],
    ]);
    if (redirected) {
        return <div>redirecting</div>;
    }

    let switchBar = <SwitchBar state={state} dispatch={dispatch} currentRoomId={null}/>

    let dashboardProps = {
        players: Ui.getPlayers(state),
        getScoresByFilter: (playerFilter, timeFilter) => Ui.getScoresByFilter(state, playerFilter, timeFilter),
        activeRooms: Ui.getActiveRooms(state),
        challengeRooms: Ui.getRecentChallenges(state.Player, state),
        linkChallengeRooms: Ui.getLinkChallenges(state.Player, state),
        getWinsAndLossesByFilter: (playerFilter, timeFilter) => Ui.getWinsAndLossesByFilter(state, playerFilter, timeFilter),
        requests: Ui.getFriendRequests(state),
        onlineFriends: Ui.getOnlineFriends(state),
        onlineCount: state.OnlineCount,
        activeWaitingRoomCount: state.ActiveWaitingRoomCount,
        player: state.Player,
        playAgain: () => Ui.playAgain(dispatch),
        playDaily: () => Ui.playDaily(dispatch),
        playMatchmaking: () => Ui.playMatchmaking(dispatch),
        playWithLink: () => Ui.playWithLink(dispatch),
        cancelChallenge: (challengeId) => Ui.cancelChallenge(dispatch, challengeId),
        acceptChallenge: (challengeId) => Ui.acceptChallenge(dispatch, challengeId),
        declineChallenge: (challengeId) => Ui.declineChallenge(dispatch, challengeId),
        rejoinChallenge: (challengeId) => Ui.rejoinChallenge(dispatch, challengeId),
        rejoinActiveRoom: (roomId) => Ui.rejoinActiveRoom(dispatch, roomId),
        abandonGame: (roomId) => Ui.abandonGame(dispatch, roomId),
        abandonMatchmaking: () => Ui.abandonMatchmaking(dispatch),
        logout: () => Ui.logout(dispatch),
        isAnonPlayer: Ui.isAnonPlayer(state),
        rejoinableRooms: Ui.getRejoinableRooms(state.Player, state),
        inMatchmaking: Ui.inMatchmaking(state),
        readyChecks: Ui.getReadyChecks(state),
        acceptMatch: (roomId) => Ui.acceptMatch(dispatch, roomId),

        readyCheckConfirm: (roomId) => Ui.readyCheckConfirm(dispatch, roomId),
        readyCheckDecline: (roomId) => Ui.readyCheckDecline(dispatch, roomId),

        acceptedRematches: Ui.getAcceptedRematches(state),
        incomingRematches: Ui.getIncomingRematches(state),
        outgoingRematches: Ui.getOutgoingRematches(state),

        incomingChallenges: Ui.getIncomingChallenges(state),
        outgoingChallenges: Ui.getOutgoingChallenges(state),
        acceptedChallenges: Ui.getAcceptedChallenges(state),
        acceptedLinkChallenges: Ui.getAcceptedLinkChallenges(state),
        outgoingLinkChallenges: Ui.getOutgoingLinkChallenges(state),
        dailyState: Ui.getDailyState(state),
        totalScore: Ui.getDailyTotalScore(state),
        finishedLayouts: Ui.getDailyFinishedLayoutsCount(state),
        switchBar,
    }


    return <Inner {...dashboardProps} />
}


export default Dashboard