import { toastPresentFn, setRouterPushFn as setRouterPushFn_1, toTimeRemainingString as toTimeRemainingString_1, toTimeElapsedString as toTimeElapsedString_1, getEnv } from "./Utils.js";
import { comparePrimitives, compare as compare_1, safeHash, arrayHash, equalArrays, compareArrays, equals, int32ToString, stringHash, createAtom } from "../fable_modules/fable-library.4.1.4/Util.js";
import { maxValue, addMinutes, now, op_Subtraction, toString, addYears, addMonths, addDays, date as date_2, today, addSeconds, compare, utcNow } from "../fable_modules/fable-library.4.1.4/Date.js";
import { ValidationError_get_empty, $007CIdleState$007C_$007C, Msg } from "./Types.js";
import { concat, sum, isEmpty, tryLast, initialize, choose, contains as contains_1, tryFind as tryFind_1, singleton, append, length, empty, map as map_1, sortByDescending, tryHead, ofArray, collect, filter, toArray as toArray_1 } from "../fable_modules/fable-library.4.1.4/List.js";
import { bind, unwrap, map, defaultArg } from "../fable_modules/fable-library.4.1.4/Option.js";
import { List_distinct } from "../fable_modules/fable-library.4.1.4/Seq2.js";
import { timeAgo, toLocalDateTime2 } from "../../js/datetime.js";
import { join, printf, toText } from "../fable_modules/fable-library.4.1.4/String.js";
import { empty as empty_2, singleton as singleton_1, append as append_1, toList as toList_3, collect as collect_1, delay, map as map_2 } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { Move, ChallengeId, Plane, PlaneDirection, RoomId } from "../Shared/Types.js";
import { FSharpMap__get_Item, containsKey, empty as empty_1, toArray as toArray_2, toList as toList_2, tryFind, ofList, keys } from "../fable_modules/fable-library.4.1.4/Map.js";
import { totalSeconds } from "../fable_modules/fable-library.4.1.4/TimeSpan.js";
import { toList as toList_1 } from "../fable_modules/fable-library.4.1.4/Set.js";
import { validatePlanePlacement as validatePlanePlacement_1, getOutside as getOutside_1, getOverlaps as getOverlaps_1, getPlaneHeads as getPlaneHeads_1, getPlaneParts as getPlaneParts_1, north, east, west, south } from "../Shared/Domain.js";
import { toString as toString_1 } from "../fable_modules/fable-library.4.1.4/Types.js";
import { tryHead as tryHead_1, tryItem, mapIndexed, sortBy, map as map_3, choose as choose_1, find } from "../fable_modules/fable-library.4.1.4/Array.js";
import { rangeDouble } from "../fable_modules/fable-library.4.1.4/Range.js";

export const linkBase = (() => {
    const matchValue = getEnv();
    return (matchValue.tag === 1) ? "https://avioane.addalgo.com" : "https://localhost:8100";
})();

export let lastRecheckAuth = createAtom(utcNow());

export function recheckAuth(dispatch) {
    if (compare(lastRecheckAuth(), addSeconds(utcNow(), -30)) < 0) {
        dispatch(new Msg(6, []));
        lastRecheckAuth(utcNow());
    }
}

export let lastPing = createAtom(utcNow());

export function ping(dispatch) {
    if (compare(lastPing(), addSeconds(utcNow(), -2.5)) < 0) {
        dispatch(new Msg(8, []));
        lastPing(utcNow());
    }
}

export function login(dispatch, username, pass) {
    return dispatch(new Msg(9, [[username, pass]]));
}

export function logout(dispatch) {
    return dispatch(new Msg(12, []));
}

export function getMessages(state) {
    return toArray_1(state.Messages);
}

export function isAnonPlayer(state) {
    const matchValue = state.Player;
    let matchResult;
    if (matchValue != null) {
        if (matchValue.Type.tag === 1) {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return true;
        default:
            return false;
    }
}

export function getPlayers(state) {
    return toArray_1(filter((username) => (username !== defaultArg(map((p) => p.Username, state.Player), "")), List_distinct(collect((s) => ofArray([s.WinnerDisplayName, s.LoserDisplayName]), state.Scores), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })));
}

function filterScores(playerFilter, timeFilter, scores) {
    const scoresForPlayer = filter((s) => {
        if (playerFilter === "allPlayers") {
            return true;
        }
        else {
            const username = playerFilter;
            if (s.WinnerDisplayName === username) {
                return true;
            }
            else {
                return s.LoserDisplayName === username;
            }
        }
    }, scores);
    let latestDayIntervalForPlayer;
    const latestScoreThatIsNotToday = tryHead(sortByDescending((s_2) => s_2.Date, filter((s_1) => (compare(toLocalDateTime2(s_1.Date), today()) < 0), scores), {
        Compare: compare,
    }));
    if (latestScoreThatIsNotToday == null) {
        latestDayIntervalForPlayer = void 0;
    }
    else {
        const latestScore = latestScoreThatIsNotToday;
        const start = date_2(latestScore.Date);
        const end$0027 = addDays(date_2(latestScore.Date), 1);
        latestDayIntervalForPlayer = [start, end$0027];
    }
    return filter((s_3) => {
        switch (timeFilter) {
            case "allTime":
                return true;
            case "latestDay":
                if (playerFilter === "allPlayers") {
                    return false;
                }
                else if (latestDayIntervalForPlayer != null) {
                    const start_1 = latestDayIntervalForPlayer[0];
                    const end$0027_1 = latestDayIntervalForPlayer[1];
                    if (compare(s_3.Date, start_1) >= 0) {
                        return compare(s_3.Date, end$0027_1) < 0;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            case "today":
                return compare(toLocalDateTime2(s_3.Date), today()) > 0;
            case "yesterday":
                if (compare(toLocalDateTime2(s_3.Date), addDays(today(), -1)) > 0) {
                    return compare(toLocalDateTime2(s_3.Date), today()) < 0;
                }
                else {
                    return false;
                }
            case "lastWeek":
                return compare(toLocalDateTime2(s_3.Date), addMonths(today(), -1)) > 0;
            case "lastMonth":
                return compare(toLocalDateTime2(s_3.Date), addMonths(today(), -1)) > 0;
            case "lastYear":
                return compare(toLocalDateTime2(s_3.Date), addYears(today(), -1)) > 0;
            default:
                throw new Error(toText(printf("Unknown time filter %s"))(timeFilter));
        }
    }, scoresForPlayer);
}

export function getScoresByFilter(state, playerFilter, timeFilter) {
    let matchValue, player;
    return toArray_1((matchValue = state.Player, (matchValue != null) ? ((player = matchValue, map_1((s) => {
        const type = (s.WinnerDisplayName === player.DisplayName) ? "Won" : "Lost";
        const vs = (s.WinnerDisplayName === player.DisplayName) ? s.LoserDisplayName : s.WinnerDisplayName;
        const myScore = ((s.WinnerDisplayName === player.DisplayName) ? 3 : s.LoserScore) | 0;
        const otherScore = ((s.WinnerDisplayName !== player.DisplayName) ? 3 : s.LoserScore) | 0;
        return {
            date: toString(toLocalDateTime2(s.Date), "yyyy-MM-dd HH:mm"),
            myScore: myScore,
            otherScore: otherScore,
            type: type,
            vs: vs,
        };
    }, filterScores(playerFilter, timeFilter, state.Scores)))) : empty()));
}

export function getActiveRooms(state) {
    return Array.from(map_2((_arg) => {
        const id = _arg.fields[0];
        return {
            Id: new RoomId(id),
            Name: id,
        };
    }, keys(state.PlayerStatus.Rooms)));
}

export function toTimeAgoString(date) {
    return timeAgo(date);
}

export function toTimeElapsedString(date) {
    return toTimeElapsedString_1(date);
}

export function toTimeRemainingString(date) {
    if ((compare(date, utcNow()) < 0) ? true : (compare(date, addDays(utcNow(), 1)) > 0)) {
        return "00:00";
    }
    else {
        return toTimeRemainingString_1(date);
    }
}

export function toPercentageElapsedFloat(date, maxSeconds) {
    const timeSpan = op_Subtraction(date, now());
    const percentage = (totalSeconds(timeSpan) / maxSeconds) * 100;
    return 100 - percentage;
}

export function getLinkChallenges(player, state) {
    const activePatternResult = $007CIdleState$007C_$007C(state);
    if (activePatternResult != null) {
        const state_1 = activePatternResult;
        return toArray_1(map_1((challengeRoom_2) => {
            let matchValue_1;
            const challengeId = challengeRoom_2.Id.fields[0] | 0;
            let Link;
            const arg_1 = int32ToString(challengeId);
            Link = toText(printf("%s/link/%s"))(linkBase)(arg_1);
            const IAmTheChallenger = equals(challengeRoom_2.ChallengerPlayer.Id, player.Id);
            const Name = equals(challengeRoom_2.ChallengerPlayer.Id, player.Id) ? join(", ", map_1((p) => p.DisplayName, toList_1(challengeRoom_2.ChallengedPlayers))) : challengeRoom_2.ChallengerPlayer.DisplayName;
            const TimeInSecondsUntilExpire = totalSeconds(op_Subtraction(toLocalDateTime2(challengeRoom_2.Date), addMinutes(utcNow(), -5))) - 10;
            return {
                Date: challengeRoom_2.Date,
                IAmTheChallenger: IAmTheChallenger,
                Id: challengeRoom_2.Id,
                Link: Link,
                Name: Name,
                Status: (matchValue_1 = challengeRoom_2.Status, (matchValue_1.tag === 3) ? "Accepted" : ((matchValue_1.tag === 2) ? "Started" : ((matchValue_1.tag === 4) ? "Declined" : ((matchValue_1.tag === 1) ? "GameOver" : ((matchValue_1.tag === 5) ? "Cancelled" : "New"))))),
                TimeInSecondsUntilExpire: TimeInSecondsUntilExpire,
            };
        }, filter((challengeRoom_1) => (compare(toLocalDateTime2(challengeRoom_1.Date), addMinutes(utcNow(), -5)) > 0), filter((challengeRoom) => {
            if (challengeRoom.Type.tag === 2) {
                return true;
            }
            else {
                return false;
            }
        }, toList_1(state_1.PlayerStatus.Challenges)))));
    }
    else {
        return [];
    }
}

export function getRecentChallenges(player, state) {
    const activePatternResult = $007CIdleState$007C_$007C(state);
    if (activePatternResult != null) {
        const state_1 = activePatternResult;
        return toArray_1(map_1((challengeRoom_2) => {
            let matchValue_1;
            const IAmTheChallenger = equals(challengeRoom_2.ChallengerPlayer.Id, player.Id);
            const Name = equals(challengeRoom_2.ChallengerPlayer.Id, player.Id) ? join(", ", map_1((p) => p.DisplayName, toList_1(challengeRoom_2.ChallengedPlayers))) : challengeRoom_2.ChallengerPlayer.DisplayName;
            const TimeInSecondsUntilExpire = totalSeconds(op_Subtraction(toLocalDateTime2(challengeRoom_2.Date), addMinutes(utcNow(), -5))) - 10;
            return {
                Date: challengeRoom_2.Date,
                IAmTheChallenger: IAmTheChallenger,
                Id: challengeRoom_2.Id,
                Name: Name,
                Status: (matchValue_1 = challengeRoom_2.Status, (matchValue_1.tag === 3) ? "Accepted" : ((matchValue_1.tag === 2) ? "Started" : ((matchValue_1.tag === 4) ? "Declined" : ((matchValue_1.tag === 1) ? "GameOver" : ((matchValue_1.tag === 5) ? "Cancelled" : "New"))))),
                TimeInSecondsUntilExpire: TimeInSecondsUntilExpire,
            };
        }, filter((challengeRoom_1) => (compare(toLocalDateTime2(challengeRoom_1.Date), addMinutes(utcNow(), -5)) > 0), filter((challengeRoom) => {
            const matchValue = challengeRoom.Type;
            switch (matchValue.tag) {
                case 1:
                case 0:
                    return true;
                default:
                    return false;
            }
        }, toList_1(state_1.PlayerStatus.Challenges)))));
    }
    else {
        return [];
    }
}

export function acceptChallenge(dispatch, challengeId) {
    return dispatch(new Msg(36, [challengeId]));
}

export function declineChallenge(dispatch, challengeId) {
    return dispatch(new Msg(37, [challengeId]));
}

export function cancelChallenge(dispatch, challengeId) {
    return dispatch(new Msg(38, [challengeId]));
}

export function rejoinChallenge(dispatch, challengeId) {
    return dispatch(new Msg(39, [challengeId]));
}

export function rejoinActiveRoom(dispatch, roomId) {
    return dispatch(new Msg(14, [roomId]));
}

export function abandonGame(dispatch, roomId) {
    return dispatch(new Msg(28, [roomId]));
}

export function abandonMatchmaking(dispatch) {
    return dispatch(new Msg(29, []));
}

export function getWinsAndLossesByFilter(state, playerFilter, timeFilter) {
    const matchValue = state.Player;
    if (matchValue != null) {
        const player = matchValue;
        const scores_1 = filterScores(playerFilter, timeFilter, state.Scores);
        const wins = length(filter((s) => (s.LoserDisplayName !== player.Username), scores_1)) | 0;
        const losses = (length(scores_1) - wins) | 0;
        return {
            losses: losses,
            wins: wins,
        };
    }
    else {
        return {
            losses: 0,
            wins: 0,
        };
    }
}

export function getPlanes(state) {
    const matchValue = state.UiState;
    switch (matchValue.tag) {
        case 1: {
            const planes_1 = matchValue.fields[0];
            return planes_1;
        }
        case 3: {
            const planes_2 = matchValue.fields[1];
            return planes_2;
        }
        case 0:
            return empty();
        case 4:
            return empty();
        default: {
            const planes = matchValue.fields[1];
            return planes;
        }
    }
}

export function addPlane(dispatch, plane) {
    return dispatch(new Msg(15, [plane]));
}

export function removePlane(dispatch, plane) {
    return dispatch(new Msg(16, [plane]));
}

export function getPlanesWith(planes, plane) {
    return append(planes, singleton(plane));
}

export function mapStringToDirection(str) {
    switch (str) {
        case "North":
            return new PlaneDirection(0, []);
        case "South":
            return new PlaneDirection(1, []);
        case "West":
            return new PlaneDirection(2, []);
        case "East":
            return new PlaneDirection(3, []);
        default:
            throw new Error(toText(printf("Couldnt match string [%s] with any direction"))(str));
    }
}

export function getPartsForPlane(plane) {
    const y = plane.Y | 0;
    const x = plane.X | 0;
    const matchValue = plane.Direction;
    switch (matchValue.tag) {
        case 1:
            return south(x, y);
        case 2:
            return west(x, y);
        case 3:
            return east(x, y);
        default:
            return north(x, y);
    }
}

export function getPlaneParts(planes) {
    return getPlaneParts_1(planes);
}

export function getPlaneHeads(planes) {
    return getPlaneHeads_1(planes);
}

export function getPlaneDirections(planes) {
    return ofList(map_1((plane) => [[plane.X, plane.Y], plane.Direction], planes), {
        Compare: compareArrays,
    });
}

export function tryGetDirection(x, y, planeDirections) {
    return tryFind([x, y], planeDirections);
}

export function getOverlaps(planeParts, planeHeads) {
    return getOverlaps_1(planeParts, planeHeads);
}

export function getOutside(planeParts, planeHeads) {
    return getOutside_1(planeParts, planeHeads);
}

export function makePlane(x, y, direction) {
    return new Plane(x, y, direction);
}

export function drawPlane(x, y, direction) {
    const plane = makePlane(x, y, direction);
    const Parts = getPartsForPlane(plane);
    const Heads = getPlaneHeads(singleton(plane));
    return {
        Direction: toString_1(direction),
        Heads: Heads,
        Parts: Parts,
    };
}

export function getDirection(x, y, planes) {
    return map((p_1) => toString_1(p_1.Direction), tryFind_1((p) => {
        if (p.X === x) {
            return p.Y === y;
        }
        else {
            return false;
        }
    }, planes));
}

export function contains(x, y, points) {
    return contains_1([x, y], points, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    });
}

export function toList(arr) {
    return ofArray(arr);
}

export function toArray(lst) {
    return toArray_1(lst);
}

export function getPlaneAt(planes, x, y) {
    return tryHead(filter((plane) => {
        const parts = getPlaneParts(singleton(plane));
        const heads = getPlaneHeads(singleton(plane));
        return contains_1([x, y], append(parts, heads), {
            Equals: equalArrays,
            GetHashCode: arrayHash,
        });
    }, planes));
}

export function startGame(dispatch) {
    return dispatch(new Msg(24, []));
}

export function playAgain(dispatch) {
    return dispatch(new Msg(17, []));
}

export function playMatchmaking(dispatch) {
    return dispatch(new Msg(17, []));
}

export function playDaily(dispatch) {
    return dispatch(new Msg(18, []));
}

export function fetchLeaderboard(dispatch) {
    return dispatch(new Msg(7, []));
}

export function playWithLink(dispatch) {
    return dispatch(new Msg(19, []));
}

export function acceptLinkChallenge(dispatch, challengeIdString) {
    return dispatch(new Msg(23, [new ChallengeId(challengeIdString)]));
}

export function rematch(dispatch, roomId) {
    return dispatch(new Msg(25, [roomId]));
}

export function forceLogin(dispatch) {
    return dispatch(new Msg(26, []));
}

export function validatePlanePlacement(planes) {
    const matchValue = validatePlanePlacement_1(planes);
    if (matchValue.tag === 1) {
        const m = matchValue.fields[0];
        return toArray_1(m);
    }
    else {
        return [];
    }
}

export function setRouterPushFn(fn) {
    setRouterPushFn_1(fn);
}

export function getMovesForPlayerIdCheck(gameState, checkFn) {
    const moves = map_1((tupledArg_1) => {
        let y_1, x_1, y_2, x_2, y, x, y_4, x_4, y_5, x_5, y_3, x_3;
        const result = tupledArg_1[1];
        return {
            move: (result.tag === 1) ? ((y_1 = (result.fields[0][1] | 0), (x_1 = (result.fields[0][0] | 0), [x_1, y_1]))) : ((result.tag === 2) ? ((y_2 = (result.fields[0][1] | 0), (x_2 = (result.fields[0][0] | 0), [x_2, y_2]))) : ((result.tag === 3) ? [-1, -1] : ((result.tag === 4) ? [-1, -1] : ((y = (result.fields[0][1] | 0), (x = (result.fields[0][0] | 0), [x, y])))))),
            result: (result.tag === 1) ? ((y_4 = (result.fields[0][1] | 0), (x_4 = (result.fields[0][0] | 0), "Hit"))) : ((result.tag === 2) ? ((y_5 = (result.fields[0][1] | 0), (x_5 = (result.fields[0][0] | 0), "Dead"))) : ((result.tag === 3) ? "Abandon" : ((result.tag === 4) ? "Timeout" : ((y_3 = (result.fields[0][1] | 0), (x_3 = (result.fields[0][0] | 0), "Air")))))),
        };
    }, filter((tupledArg) => {
        const p = tupledArg[0];
        const res = tupledArg[1];
        return checkFn(p);
    }, gameState.MoveResults));
    const hits = map_1((m_1) => m_1.move, filter((m) => (m.result === "Hit"), moves));
    const kills = map_1((m_3) => m_3.move, filter((m_2) => (m_2.result === "Dead"), moves));
    return {
        airs: map_1((m_5) => m_5.move, filter((m_4) => (m_4.result === "Air"), moves)),
        hits: hits,
        kills: kills,
    };
}

export function getMovesForMyself(player, gameState) {
    const playerId = player.Id;
    return getMovesForPlayerIdCheck(gameState, (p) => equals(p, playerId));
}

export function getQueuedMoves(state, roomId) {
    return toArray_1(choose((move) => {
        if (move.tag === 0) {
            const y = move.fields[1] | 0;
            const x = move.fields[0] | 0;
            return {
                x: x,
                y: y,
            };
        }
        else {
            return void 0;
        }
    }, defaultArg(tryFind(roomId, state.QueuedMoves), empty())));
}

export function getMovesForOther(player, gameState) {
    const playerId = player.Id;
    return getMovesForPlayerIdCheck(gameState, (p) => !equals(p, playerId));
}

export function getStats(moves, deadC, hitC, airC) {
    const arg = length(moves.kills) | 0;
    const arg_2 = length(moves.hits) | 0;
    const arg_4 = length(moves.airs) | 0;
    return toText(printf("%i%s %i%s %i%s"))(arg)(deadC)(arg_2)(hitC)(arg_4)(airC);
}

export function getStatus(moves, deadC, notDeadC) {
    return join("", append(initialize(length(moves.kills), (_arg) => deadC), initialize(3 - length(moves.kills), (_arg_1) => notDeadC)));
}

export function getMyStats(player, gameState, deadC, hitC, airC) {
    const moves = getMovesForMyself(player, gameState);
    return getStats(moves, deadC, hitC, airC);
}

export function getMyStatus(player, gameState, deadC, notDeadC) {
    const moves = getMovesForOther(player, gameState);
    return getStatus(moves, deadC, notDeadC);
}

export function getOtherStats(player, gameState, deadC, hitC, airC) {
    const moves = getMovesForOther(player, gameState);
    return getStats(moves, deadC, hitC, airC);
}

export function getOtherStatus(player, gameState, deadC, notDeadC) {
    const moves = getMovesForMyself(player, gameState);
    return getStatus(moves, deadC, notDeadC);
}

export function makeMove(dispatch, roomId, x, y) {
    return dispatch(new Msg(30, [roomId, new Move(0, [x, y])]));
}

export function queueMove(dispatch, roomId, x, y) {
    return dispatch(new Msg(31, [roomId, new Move(0, [x, y])]));
}

export function isItMyTurn(player, gameState) {
    const playerId = player.Id;
    const gameState_1 = gameState;
    return equals(gameState_1.NextPlayerId, playerId);
}

export function isThereAWinner(gameState) {
    const gameState_1 = gameState;
    const matchValue = gameState_1.Winner;
    if (matchValue != null) {
        const winner = matchValue;
        return true;
    }
    else {
        return false;
    }
}

export function amITheWinner(player, gameState) {
    let winner;
    const playerId = player.Id;
    const gameState_1 = gameState;
    const matchValue = gameState_1.Winner;
    let matchResult, winner_1;
    if (matchValue != null) {
        if ((winner = matchValue, equals(winner, playerId))) {
            matchResult = 0;
            winner_1 = matchValue;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return true;
        default:
            return false;
    }
}

export function getMovesCount(gameState) {
    return length(gameState.MoveResults);
}

export function getLastMoveType(gameState) {
    let y_1, x_1, y_2, x_2, y, x;
    const matchValue = tryLast(gameState.MoveResults);
    if (matchValue == null) {
        return void 0;
    }
    else {
        const res = matchValue[1];
        const p = matchValue[0];
        return (res.tag === 1) ? ((y_1 = (res.fields[0][1] | 0), (x_1 = (res.fields[0][0] | 0), "Hit"))) : ((res.tag === 2) ? ((y_2 = (res.fields[0][1] | 0), (x_2 = (res.fields[0][0] | 0), "Dead"))) : ((res.tag === 3) ? "Abandon" : ((res.tag === 4) ? "Timeout" : ((y = (res.fields[0][1] | 0), (x = (res.fields[0][0] | 0), "Air"))))));
    }
}

export function getLastMoveForUi(player, gameState) {
    const playerId = player.Id;
    const gameState_1 = gameState;
    const matchValue = tryLast(gameState_1.MoveResults);
    if (matchValue == null) {
        return void 0;
    }
    else {
        const res = matchValue[1];
        const p = matchValue[0];
        let patternInput;
        switch (res.tag) {
            case 1: {
                const y_1 = res.fields[0][1] | 0;
                const x_1 = res.fields[0][0] | 0;
                patternInput = ["Hit", [x_1, y_1]];
                break;
            }
            case 2: {
                const y_2 = res.fields[0][1] | 0;
                const x_2 = res.fields[0][0] | 0;
                patternInput = ["Dead", [x_2, y_2]];
                break;
            }
            case 3: {
                patternInput = ["Abandon", [-1, -1]];
                break;
            }
            case 4: {
                patternInput = ["Timeout", [-1, -1]];
                break;
            }
            default: {
                const y = res.fields[0][1] | 0;
                const x = res.fields[0][0] | 0;
                patternInput = ["Air", [x, y]];
            }
        }
        const t = patternInput[0];
        const m = patternInput[1];
        return {
            myMove: equals(playerId, p),
            type: t,
            x: m[0],
            y: m[1],
        };
    }
}

export function getFriends(state) {
    return toArray_1(map_1((f) => {
        const id = f.Id.fields[0] | 0;
        return {
            Id: f.Id,
            IdAsString: id,
            Name: f.FriendDisplayName,
        };
    }, state.Friends));
}

export function getOnlineFriends(state) {
    return toArray_1(state.OnlineFriends);
}

export function isFriendOnline(state, friendshipId) {
    return contains_1(friendshipId, state.OnlineFriends, {
        Equals: equals,
        GetHashCode: safeHash,
    });
}

export function getFriendRequests(state) {
    return toArray_1(map_1((f) => {
        const id = f.Id.fields[0] | 0;
        return {
            Id: f.Id,
            IdAsString: id,
            Name: f.FriendDisplayName,
        };
    }, state.Requests));
}

export function getFriend(state, friendshipIdString) {
    return find((f) => (int32ToString(f.IdAsString) === friendshipIdString), getFriends(state));
}

export function requestFriendship(dispatch, username) {
    return dispatch(new Msg(32, [username]));
}

export function acceptRequest(dispatch, friendshipId) {
    return dispatch(new Msg(33, [friendshipId]));
}

export function declineRequest(dispatch, friendshipId) {
    return dispatch(new Msg(34, [friendshipId]));
}

export function challengeFriend(dispatch, friendshipId) {
    return dispatch(new Msg(35, [friendshipId]));
}

export function getViewModule(state) {
    if (state.Player == null) {
        return "loginRegister";
    }
    else {
        return "browser";
    }
}

export function getLoginManager(state, dispatch) {
    const tryLogin = (username, password) => dispatch(new Msg(9, [[username, password]]));
    const getValidationErrorForField = (fieldName) => toArray_1(defaultArg(tryFind(fieldName, defaultArg(tryFind("login", state.ValidationErrors), ValidationError_get_empty()).Errors), empty()));
    return {
        getValidationErrorForField: getValidationErrorForField,
        tryLogin: tryLogin,
    };
}

export function getRegisterManager(state, dispatch) {
    const tryRegister = (username, password) => dispatch(new Msg(10, [[username, password]]));
    const registerAnon = () => dispatch(new Msg(11, []));
    const getValidationErrorForField = (fieldName) => toArray_1(defaultArg(tryFind(fieldName, defaultArg(tryFind("register", state.ValidationErrors), ValidationError_get_empty()).Errors), empty()));
    return {
        getValidationErrorForField: getValidationErrorForField,
        registerAnon: registerAnon,
        tryRegister: tryRegister,
    };
}

export function getRejoinableRooms(player, state) {
    const playerId = player.Id;
    return choose_1((tupledArg) => {
        const roomId = tupledArg[0];
        const roomItem = tupledArg[1];
        let matchResult, date, pgs, planes, playersMap;
        switch (roomItem.tag) {
            case 1: {
                if (roomItem.fields[0].Winner == null) {
                    matchResult = 0;
                    date = roomItem.fields[3];
                    pgs = roomItem.fields[0];
                    planes = roomItem.fields[1];
                    playersMap = roomItem.fields[2];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            case 0: {
                if (roomItem.fields[0].Winner == null) {
                    matchResult = 0;
                    date = roomItem.fields[3];
                    pgs = roomItem.fields[0];
                    planes = roomItem.fields[1];
                    playersMap = roomItem.fields[2];
                }
                else {
                    matchResult = 1;
                }
                break;
            }
            default:
                matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                const otherPlayers = map_1((tupledArg_2) => {
                    const pId_1 = tupledArg_2[0];
                    const publicPlayer_1 = tupledArg_2[1];
                    return publicPlayer_1.Name;
                }, filter((tupledArg_1) => {
                    const pId = tupledArg_1[0];
                    const publicPlayer = tupledArg_1[1];
                    return !equals(pId, playerId);
                }, toList_2(playersMap)));
                const otherImgSrc = defaultArg(tryHead(map_1((tupledArg_4) => {
                    const pId_3 = tupledArg_4[0];
                    const publicPlayer_3 = tupledArg_4[1];
                    return publicPlayer_3.Icon;
                }, filter((tupledArg_3) => {
                    const pId_2 = tupledArg_3[0];
                    const publicPlayer_2 = tupledArg_3[1];
                    return !equals(pId_2, playerId);
                }, toList_2(playersMap)))), "plane-r");
                const myStatus = getMyStatus(player, pgs, "💀", "✈️");
                const otherStatus = getOtherStatus(player, pgs, "💀", "✈️");
                const idString = roomId.fields[0];
                return {
                    Date: date,
                    Id: roomId,
                    IdString: idString,
                    OtherIcon: otherImgSrc,
                    OtherName: join(", ", otherPlayers),
                    Score: toText(printf("%s - %s"))(myStatus)(otherStatus),
                };
            }
            default:
                return void 0;
        }
    }, toArray_2(state.PlayerStatus.Rooms));
}

export function inMatchmaking(state) {
    return state.PlayerStatus.InMatchmaking;
}

export function getReadyChecks(state) {
    return map_3((tupledArg) => {
        const roomId = tupledArg[0];
        const _arg = tupledArg[1];
        const date = _arg[1];
        const accepted = _arg[0];
        return {
            Accepted: accepted,
            Date: date,
            Id: roomId,
        };
    }, toArray_2(state.PlayerStatus.ReadyChecks));
}

export function readyCheckConfirm(dispatch, roomId) {
    return dispatch(new Msg(20, [roomId]));
}

export function readyCheckDecline(dispatch, roomId) {
    return dispatch(new Msg(21, [roomId]));
}

export function acceptMatch(dispatch, roomId) {
    return dispatch(new Msg(22, [roomId]));
}

export function getIncomingChallenges(state) {
    let array;
    return choose_1((challengeRoom_1) => {
        let matchResult;
        if (challengeRoom_1.Type.tag === 1) {
            if (challengeRoom_1.Status.tag === 0) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return {
                    Id: challengeRoom_1.Id,
                    OtherIcon: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom_1.ChallengedPlayers))), "plane-b") : challengeRoom_1.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_2) => player_2.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom_1.ChallengedPlayers))) : challengeRoom_1.ChallengerPlayer.DisplayName,
                };
            default:
                return void 0;
        }
    }, (array = toArray_1(toList_1(state.PlayerStatus.Challenges)), array.filter((challengeRoom) => !equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)))));
}

export function getOutgoingChallenges(state) {
    let array;
    return choose_1((challengeRoom_1) => {
        let matchResult;
        if (challengeRoom_1.Type.tag === 1) {
            if (challengeRoom_1.Status.tag === 0) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return {
                    Id: challengeRoom_1.Id,
                    OtherIcon: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom_1.ChallengedPlayers))), "plane-b") : challengeRoom_1.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_2) => player_2.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom_1.ChallengedPlayers))) : challengeRoom_1.ChallengerPlayer.DisplayName,
                };
            default:
                return void 0;
        }
    }, (array = toArray_1(toList_1(state.PlayerStatus.Challenges)), array.filter((challengeRoom) => equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)))));
}

export function getIncomingRematches(state) {
    let array;
    return choose_1((challengeRoom_1) => {
        let matchResult;
        if (challengeRoom_1.Type.tag === 0) {
            if (challengeRoom_1.Status.tag === 0) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return {
                    Id: challengeRoom_1.Id,
                    OtherIcon: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom_1.ChallengedPlayers))), "plane-b") : challengeRoom_1.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_2) => player_2.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom_1.ChallengedPlayers))) : challengeRoom_1.ChallengerPlayer.DisplayName,
                };
            default:
                return void 0;
        }
    }, (array = toArray_1(toList_1(state.PlayerStatus.Challenges)), array.filter((challengeRoom) => !equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)))));
}

export function getOutgoingRematches(state) {
    let array;
    return choose_1((challengeRoom_1) => {
        let matchResult;
        if (challengeRoom_1.Type.tag === 0) {
            if (challengeRoom_1.Status.tag === 0) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return {
                    Id: challengeRoom_1.Id,
                    OtherIcon: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom_1.ChallengedPlayers))), "plane-b") : challengeRoom_1.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom_1.ChallengerPlayer.Id, map((player_2) => player_2.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom_1.ChallengedPlayers))) : challengeRoom_1.ChallengerPlayer.DisplayName,
                };
            default:
                return void 0;
        }
    }, (array = toArray_1(toList_1(state.PlayerStatus.Challenges)), array.filter((challengeRoom) => equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)))));
}

export function getAcceptedChallenges(state) {
    return choose_1((challengeRoom) => {
        let matchResult;
        if (challengeRoom.Type.tag === 1) {
            if (challengeRoom.Status.tag === 3) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return {
                    Id: challengeRoom.Id,
                    OtherIcon: equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom.ChallengedPlayers))), "plane-b") : challengeRoom.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom.ChallengedPlayers))) : challengeRoom.ChallengerPlayer.DisplayName,
                };
            default:
                return void 0;
        }
    }, toArray_1(toList_1(state.PlayerStatus.Challenges)));
}

export function getAcceptedRematches(state) {
    return choose_1((challengeRoom) => {
        let matchResult;
        if (challengeRoom.Type.tag === 0) {
            if (challengeRoom.Status.tag === 3) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return {
                    Id: challengeRoom.Id,
                    OtherIcon: equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom.ChallengedPlayers))), "plane-b") : challengeRoom.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom.ChallengedPlayers))) : challengeRoom.ChallengerPlayer.DisplayName,
                };
            default:
                return void 0;
        }
    }, toArray_1(toList_1(state.PlayerStatus.Challenges)));
}

export function getAcceptedLinkChallenges(state) {
    return choose_1((challengeRoom) => {
        let arg_1;
        let matchResult;
        if (challengeRoom.Type.tag === 2) {
            if (challengeRoom.Status.tag === 3) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                const challengeId = challengeRoom.Id.fields[0] | 0;
                return {
                    Date: challengeRoom.Date,
                    Id: challengeRoom.Id,
                    Link: (arg_1 = int32ToString(challengeId), toText(printf("%s/link/%s"))(linkBase)(arg_1)),
                    OtherIcon: equals(challengeRoom.ChallengerPlayer.Id, map((player) => player.Id, state.Player)) ? defaultArg(tryHead(map_1((p) => p.Icon, toList_1(challengeRoom.ChallengedPlayers))), "plane-b") : challengeRoom.ChallengerPlayer.Icon,
                    OtherName: equals(challengeRoom.ChallengerPlayer.Id, map((player_1) => player_1.Id, state.Player)) ? join(", ", map_1((p_1) => p_1.DisplayName, toList_1(challengeRoom.ChallengedPlayers))) : challengeRoom.ChallengerPlayer.DisplayName,
                };
            }
            default:
                return void 0;
        }
    }, toArray_1(toList_1(state.PlayerStatus.Challenges)));
}

export function getOutgoingLinkChallenges(state) {
    return choose_1((challengeRoom) => {
        let arg_1;
        let matchResult;
        if (challengeRoom.Type.tag === 2) {
            if (challengeRoom.Status.tag === 0) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                const challengeId = challengeRoom.Id.fields[0] | 0;
                return {
                    Id: challengeRoom.Id,
                    Link: (arg_1 = int32ToString(challengeId), toText(printf("%s/link/%s"))(linkBase)(arg_1)),
                };
            }
            default:
                return void 0;
        }
    }, toArray_1(toList_1(state.PlayerStatus.Challenges)));
}

export function canBeOnDrawPage(state) {
    const matchValue = state.UiState;
    switch (matchValue.tag) {
        case 1:
            return true;
        case 3:
            return true;
        case 0:
            return false;
        case 4:
            return false;
        default:
            return true;
    }
}

export function canBeOnWaitingPage(state) {
    const matchValue = state.UiState;
    switch (matchValue.tag) {
        case 1:
            return false;
        case 3:
            return false;
        case 0:
            return false;
        case 4:
            return true;
        default:
            return false;
    }
}

export function canBeOnPlayPage(state, roomId) {
    if (!state.PlayerStatusLoaded) {
        return true;
    }
    else {
        const matchValue = tryFind(roomId, state.PlayerStatus.Rooms);
        if (matchValue == null) {
            return false;
        }
        else {
            const roomItem = matchValue;
            return true;
        }
    }
}

export function getPlanesForPlayerInRoom(state, roomId) {
    const matchValue = tryFind(roomId, state.PlayerStatus.Rooms);
    if (matchValue == null) {
        return empty();
    }
    else {
        const roomItem = matchValue;
        switch (roomItem.tag) {
            case 1: {
                const planes_1 = roomItem.fields[1];
                const pgs_1 = roomItem.fields[0];
                const date_1 = roomItem.fields[3];
                return planes_1;
            }
            case 2: {
                const pgs_2 = roomItem.fields[0];
                return empty();
            }
            default: {
                const planes = roomItem.fields[1];
                const pgs = roomItem.fields[0];
                const date = roomItem.fields[3];
                return planes;
            }
        }
    }
}

export function tryGetOpponentPlayerForRoom(state, roomId) {
    let matchValue, roomItem, playersMap_1, planes_1, pgs_1, date_1, pgs_2, playersMap, planes, pgs, date;
    const playerId = map((p) => p.Id, state.Player);
    return map((tupledArg_1) => {
        const pId_1 = tupledArg_1[0];
        const publicPlayer = tupledArg_1[1];
        return {
            Icon: publicPlayer.Icon,
            Name: publicPlayer.Name,
        };
    }, tryHead(filter((tupledArg) => {
        const pId = tupledArg[0];
        return !equals(pId, playerId);
    }, toList_2((matchValue = tryFind(roomId, state.PlayerStatus.Rooms), (matchValue == null) ? empty_1({
        Compare: compare_1,
    }) : ((roomItem = matchValue, (roomItem.tag === 1) ? ((playersMap_1 = roomItem.fields[2], (planes_1 = roomItem.fields[1], (pgs_1 = roomItem.fields[0], (date_1 = roomItem.fields[3], playersMap_1))))) : ((roomItem.tag === 2) ? ((pgs_2 = roomItem.fields[0], empty_1({
        Compare: compare_1,
    }))) : ((playersMap = roomItem.fields[2], (planes = roomItem.fields[1], (pgs = roomItem.fields[0], (date = roomItem.fields[3], playersMap)))))))))))));
}

export function getPublicGameStateForPlayerInRoom(state, roomId) {
    const matchValue = tryFind(roomId, state.PlayerStatus.Rooms);
    if (matchValue == null) {
        return void 0;
    }
    else {
        const roomItem = matchValue;
        switch (roomItem.tag) {
            case 1: {
                const planes_1 = roomItem.fields[1];
                const pgs_1 = roomItem.fields[0];
                const date_1 = roomItem.fields[3];
                return pgs_1;
            }
            case 2: {
                const pgs_2 = roomItem.fields[0];
                return pgs_2;
            }
            default: {
                const planes = roomItem.fields[1];
                const pgs = roomItem.fields[0];
                const date = roomItem.fields[3];
                return pgs;
            }
        }
    }
}

export function drawingForMatchmaking(state) {
    if (state.UiState.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

export function getDailyState(state) {
    let finishedLayouts, finishedLayouts_1;
    const matchValue = state.PlayerStatus.DailyStatus.CompletedLayouts;
    const matchValue_1 = state.PlayerStatus.DailyStatus.HasStartedLayouts;
    let matchResult, finishedLayouts_2;
    if (isEmpty(matchValue)) {
        if (matchValue_1) {
            if ((finishedLayouts = matchValue, length(finishedLayouts) === 5)) {
                matchResult = 1;
                finishedLayouts_2 = matchValue;
            }
            else {
                matchResult = 3;
            }
        }
        else {
            matchResult = 0;
        }
    }
    else if ((finishedLayouts_1 = matchValue, length(finishedLayouts_1) === 5)) {
        matchResult = 1;
        finishedLayouts_2 = matchValue;
    }
    else if (matchValue_1) {
        matchResult = 3;
    }
    else {
        matchResult = 2;
    }
    switch (matchResult) {
        case 0:
            return "not_started";
        case 1:
            return "finished";
        case 2:
            return "started";
        default:
            return "started";
    }
}

export function getDailyTotalScore(state) {
    const completed = sum(state.PlayerStatus.DailyStatus.CompletedLayouts, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) | 0;
    return (state.PlayerStatus.DailyStatus.CurrentScore + completed) | 0;
}

export function getDailyCompletedScore(state) {
    return sum(state.PlayerStatus.DailyStatus.CompletedLayouts, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    });
}

export function getDailyCurrentScore(state, roomId) {
    const matchValue = tryFind(roomId, state.PlayerStatus.Rooms);
    let matchResult, pgs;
    if (matchValue != null) {
        if (matchValue.tag === 2) {
            if (matchValue.fields[0].Winner != null) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
                pgs = matchValue.fields[0];
            }
        }
        else {
            matchResult = 2;
        }
    }
    else {
        matchResult = 2;
    }
    switch (matchResult) {
        case 0:
            return 0;
        case 1:
            return length(pgs.MoveResults) | 0;
        default:
            return 0;
    }
}

export function getDailyFinishedLayoutsCount(state) {
    return length(state.PlayerStatus.DailyStatus.CompletedLayouts);
}

export function isDailyGameRoom(state, roomId) {
    const matchValue = tryFind(roomId, state.PlayerStatus.Rooms);
    let matchResult;
    if (matchValue != null) {
        if (matchValue.tag === 2) {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return true;
        default:
            return false;
    }
}

export function getRoomId(state) {
    const matchValue = state.UiState;
    if (matchValue.tag === 4) {
        const roomId = matchValue.fields[0];
        if (tryFind(roomId, state.PlayerStatus.Rooms) != null) {
            return roomId;
        }
        else {
            return void 0;
        }
    }
    else {
        return void 0;
    }
}

function undecidedUiLeaderboard(displayName) {
    return {
        DisplayName: displayName,
        Rank: 99999,
        RankLabel: "Undecided",
        Score: "?",
    };
}

function leaderboardItemToUiLeaderboard(li) {
    if (li.tag === 1) {
        const l_1 = li.fields[0];
        return undecidedUiLeaderboard(l_1.DisplayName);
    }
    else {
        const l = li.fields[0];
        return {
            DisplayName: l.DisplayName,
            Rank: l.Rank,
            RankLabel: int32ToString(l.Rank),
            Score: int32ToString(l.Score),
        };
    }
}

export function getTop100Leaderboard(state) {
    let matchValue, leaderboard;
    return sortBy((li_1) => li_1.Rank, toArray_1((matchValue = state.Leaderboard, (matchValue != null) ? ((leaderboard = matchValue, map_1(leaderboardItemToUiLeaderboard, leaderboard.Top100))) : empty())), {
        Compare: comparePrimitives,
    });
}

export function getFriendsLeaderboard(state) {
    let matchValue, leaderboard;
    return sortBy((li_1) => li_1.Rank, toArray_1((matchValue = state.Leaderboard, (matchValue != null) ? ((leaderboard = matchValue, map_1(leaderboardItemToUiLeaderboard, leaderboard.Friends))) : empty())), {
        Compare: comparePrimitives,
    });
}

export function getTopPlayersPosition(state) {
    const matchValue = state.Leaderboard;
    if (matchValue != null) {
        const leaderboard = matchValue;
        return leaderboardItemToUiLeaderboard(leaderboard.TopPlayerPosition);
    }
    else {
        return undecidedUiLeaderboard(defaultArg(map((p) => p.DisplayName, state.Player), "?"));
    }
}

export function getFriendsPosition(state) {
    const matchValue = state.Leaderboard;
    if (matchValue != null) {
        const leaderboard = matchValue;
        return defaultArg(map(leaderboardItemToUiLeaderboard, tryFind_1((l) => {
            if (l.tag === 1) {
                const l_2 = l.fields[0];
                return equals(l_2.PlayerId, map((p_2) => p_2.Id, state.Player));
            }
            else {
                const l_1 = l.fields[0];
                return equals(l_1.PlayerId, map((p_1) => p_1.Id, state.Player));
            }
        }, leaderboard.Friends)), undecidedUiLeaderboard(defaultArg(map((p_3) => p_3.DisplayName, state.Player), "?")));
    }
    else {
        return undecidedUiLeaderboard(defaultArg(map((p) => p.DisplayName, state.Player), "?"));
    }
}

export function getDrawClasses(state, previewPlane) {
    const planes = getPlanes(state);
    const planeParts = getPlaneParts(planes);
    const planeHeads = getPlaneHeads(planes);
    const directions = ofList(map_1((p) => [[p.X, p.Y], p.Direction], planes), {
        Compare: compareArrays,
    });
    const previewPlanes = defaultArg(map(singleton, previewPlane), empty());
    const previewPlaneParts = getPlaneParts(previewPlanes);
    const previewPlaneHeads = getPlaneHeads(previewPlanes);
    const overlaps = getOverlaps(append(previewPlaneParts, planeParts), append(previewPlaneHeads, planeHeads));
    const previewDirections = ofList(map_1((p_2) => [[p_2.X, p_2.Y], p_2.Direction], previewPlanes), {
        Compare: compareArrays,
    });
    const xys = Array.from(delay(() => collect_1((y_2) => map_2((x_2) => [[x_2, y_2], toArray_1(concat(toList_3(delay(() => append_1(contains_1([x_2, y_2], planeParts, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    }) ? singleton_1(singleton("part")) : empty_2(), delay(() => append_1(containsKey([x_2, y_2], directions) ? singleton_1(ofArray(["head", toString_1(FSharpMap__get_Item(directions, [x_2, y_2]))])) : empty_2(), delay(() => append_1(contains_1([x_2, y_2], overlaps, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    }) ? singleton_1(singleton("overlap")) : empty_2(), delay(() => append_1(contains_1([x_2, y_2], previewPlaneParts, {
        Equals: equalArrays,
        GetHashCode: arrayHash,
    }) ? singleton_1(singleton("preview-part")) : empty_2(), delay(() => {
        let arg;
        return containsKey([x_2, y_2], previewDirections) ? singleton_1(ofArray(["preview-head", (arg = toString_1(FSharpMap__get_Item(previewDirections, [x_2, y_2])), toText(printf("preview-%s"))(arg))])) : empty_2();
    }))))))))))))], rangeDouble(0, 1, 9)), rangeDouble(0, 1, 9))));
    return xys;
}

export function getAvailableIcons(state) {
    return ["plane-b", "plane-r", "plane-y"];
}

export function selectIcon(dispatch, icon) {
    return dispatch(new Msg(13, [icon]));
}

export function getSelectedIcon(state) {
    return map((p) => p.Icon, state.Player);
}

export function initToastPresenter(f) {
    toastPresentFn(f);
}

export function toggleVibration(dispatch) {
    return dispatch(new Msg(41, []));
}

export function toggleSound(dispatch) {
    return dispatch(new Msg(40, []));
}

export function mapRoomsToGameSwitchItems(state, dispatch, currentRoomId) {
    let array_2, array;
    const switchItems = map_3((tupledArg_4) => {
        const roomId_4 = tupledArg_4[0];
        const roomItem_3 = tupledArg_4[1];
        const publicGameState_3 = tupledArg_4[2];
        const player_2 = tupledArg_4[3];
        const roomIdString = roomId_4.fields[0];
        const isPlayerTurn = isItMyTurn(player_2, publicGameState_3);
        const timeout = publicGameState_3.MoveTimeout;
        const myMoves = getMovesForMyself(player_2, publicGameState_3);
        const otherMoves = getMovesForOther(player_2, publicGameState_3);
        const myLastMove = map((r) => {
            switch (r.tag) {
                case 1:
                    return "hit";
                case 2:
                    return "dead";
                case 3:
                    return "abandon";
                case 4:
                    return "timeout";
                default:
                    return "air";
            }
        }, map((tuple) => tuple[1], tryLast(filter((tupledArg_5) => {
            const pId = tupledArg_5[0];
            return equals(pId, player_2.Id);
        }, publicGameState_3.MoveResults))));
        const otherLastMove = map((r_2) => {
            switch (r_2.tag) {
                case 1:
                    return "hit";
                case 2:
                    return "dead";
                case 3:
                    return "abandon";
                case 4:
                    return "timeout";
                default:
                    return "air";
            }
        }, map((tuple_1) => tuple_1[1], tryLast(filter((tupledArg_6) => {
            const pId_1 = tupledArg_6[0];
            const r_1 = tupledArg_6[1];
            return !equals(pId_1, player_2.Id);
        }, publicGameState_3.MoveResults))));
        const isCurrent = equals(roomId_4, currentRoomId);
        const playerDeadCount = length(myMoves.kills) | 0;
        const otherDeadCount = length(otherMoves.kills) | 0;
        return {
            actionFn: () => {
                dispatch(new Msg(27, [toText(printf("/play/%s"))(roomIdString)]));
            },
            altActionFn: () => {
            },
            isCurrent: isCurrent,
            isGameOver: isThereAWinner(publicGameState_3),
            isPlayerTurn: isPlayerTurn,
            lastOtherMoveResult: unwrap(otherLastMove),
            lastPlayerMoveResult: unwrap(myLastMove),
            otherDeadCount: otherDeadCount,
            playerDeadCount: playerDeadCount,
            playerWon: amITheWinner(player_2, publicGameState_3),
            roomId: roomId_4,
            timeout: timeout,
        };
    }, (array_2 = choose_1((tupledArg_1) => {
        const roomId_1 = tupledArg_1[0];
        const roomItem = tupledArg_1[1];
        const publicGameState = getPublicGameStateForPlayerInRoom(state, roomId_1);
        return bind((tupledArg_2) => {
            const roomId_2 = tupledArg_2[0];
            const roomItem_1 = tupledArg_2[1];
            const pgs_1 = tupledArg_2[2];
            return map((player) => [roomId_2, roomItem_1, pgs_1, player], state.Player);
        }, map((pgs) => [roomId_1, roomItem, pgs], publicGameState));
    }, (array = toArray_2(state.PlayerStatus.Rooms), array.filter((tupledArg) => {
        const roomId = tupledArg[0];
        return !contains_1(roomId, state.HiddenRooms, {
            Equals: equals,
            GetHashCode: safeHash,
        });
    }))), array_2.filter((tupledArg_3) => {
        let dateTime, planes, playerIdMap, publicGameState_2;
        const roomId_3 = tupledArg_3[0];
        const roomItem_2 = tupledArg_3[1];
        const publicGameState_1 = tupledArg_3[2];
        const player_1 = tupledArg_3[3];
        let roomExpired;
        if (!isThereAWinner(publicGameState_1)) {
            roomExpired = false;
        }
        else if (isDailyGameRoom(state, roomId_3)) {
            roomExpired = false;
        }
        else {
            const roomDate = (roomItem_2.tag === 0) ? ((dateTime = roomItem_2.fields[3], (planes = roomItem_2.fields[1], (playerIdMap = roomItem_2.fields[2], (publicGameState_2 = roomItem_2.fields[0], dateTime))))) : ((roomItem_2.tag === 1) ? ((dateTime = roomItem_2.fields[3], (planes = roomItem_2.fields[1], (playerIdMap = roomItem_2.fields[2], (publicGameState_2 = roomItem_2.fields[0], dateTime))))) : maxValue());
            roomExpired = (compare(roomDate, addMinutes(now(), -2)) < 0);
        }
        const dailyFinished = (roomItem_2.tag === 2) && isThereAWinner(publicGameState_1);
        if (!dailyFinished) {
            return !roomExpired;
        }
        else {
            return false;
        }
    })));
    return mapIndexed((index, item) => {
        if (item.isGameOver) {
            let redirect;
            const matchValue = tryItem(index + 1, switchItems);
            if (matchValue == null) {
                const matchValue_1 = tryItem(index - 1, switchItems);
                if (matchValue_1 == null) {
                    redirect = (new Msg(27, [toText(printf("/dashboard"))]));
                }
                else {
                    const item_2 = matchValue_1;
                    const roomIdString_2 = item_2.roomId.fields[0];
                    redirect = (new Msg(27, [toText(printf("/play/%s"))(roomIdString_2)]));
                }
            }
            else {
                const item_1 = matchValue;
                const roomIdString_1 = item_1.roomId.fields[0];
                redirect = (new Msg(27, [toText(printf("/play/%s"))(roomIdString_1)]));
            }
            return {
                actionFn: item.actionFn,
                altActionFn: () => {
                    dispatch(new Msg(2, [ofArray([new Msg(42, [item.roomId]), redirect])]));
                },
                isCurrent: item.isCurrent,
                isGameOver: item.isGameOver,
                isPlayerTurn: item.isPlayerTurn,
                lastOtherMoveResult: unwrap(item.lastOtherMoveResult),
                lastPlayerMoveResult: unwrap(item.lastPlayerMoveResult),
                otherDeadCount: item.otherDeadCount,
                playerDeadCount: item.playerDeadCount,
                playerWon: item.playerWon,
                roomId: item.roomId,
                timeout: item.timeout,
            };
        }
        else {
            return item;
        }
    }, switchItems);
}

export function getQueueState(state, dispatch) {
    let patternInput;
    if (state.PlayerStatus.InMatchmaking != null) {
        patternInput = ["inQueue", () => dispatch(new Msg(27, ["dashboard"]))];
    }
    else {
        const matchValue = tryHead_1(getReadyChecks(state));
        if (matchValue != null) {
            const readyCheck = matchValue;
            patternInput = (readyCheck.Accepted ? ["Wait", () => dispatch(new Msg(27, ["dashboard"]))] : ["Accept", () => dispatch(new Msg(22, [readyCheck.Id]))]);
        }
        else {
            patternInput = ["idle", () => dispatch(new Msg(17, []))];
        }
    }
    const queueState = patternInput[0];
    const actionFn = patternInput[1];
    return {
        actionFn: actionFn,
        queueState: queueState,
    };
}

export function getHomeState(state, dispatch) {
    const queueState = getQueueState(state, dispatch);
    return {
        actionFn: () => dispatch(new Msg(27, ["/dashboard"])),
        queueState: queueState.queueState,
    };
}

